import React from "react";
import styles from "./Founders.module.scss"; 

const Founders = () => {
    return (
        <div className={styles.foundersSection}>
            <div className={styles.textContent}>
                <h1>Fundadores</h1>
                <h3>
                    <b>Hernán y Octavio</b>, dos emprendedores apasionados por la tecnología y la creación de soluciones prácticas, fundaron la empresa con un enfoque claro: <b>proporcionar productos que aporten un valor genuino a sus usuarios.</b>
                </h3>
                <h5>
                    Nuestra misión principal es <b>trabajar en estrecha colaboración con reconocidos productores</b> de eventos y organizadores de fiestas, siempre con la mirada puesta en la innovación constante, con el fin de <b>impulsar de manera significativa y efectiva la evolución de la industria.</b>
                </h5>
            </div>
            <div className={styles.imageContent}>
                <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/imagen.png" alt="Fundadores" />
            </div>
        </div>
    );
}

export default Founders;
