import React, { useState } from 'react';
import useLazyLoadImage from '../../Hooks/useLazyLoadImage';
import './LazyImage.css';

const LazyImage = ({ src, alt, placeholder, className }) => {
  const { imgRef, isIntersecting } = useLazyLoadImage();
  const [isLoaded, setIsLoaded] = useState();

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div className="image-container">
      {!isLoaded && <div className="skeleton-loader"></div>}
      <img
        ref={imgRef}
        src={isIntersecting ? src : placeholder}
        alt={alt}
        className={`lazy-image ${isLoaded ? 'visible' : 'hidden'} ${className}`}
        onLoad={handleImageLoad}
      />
    </div>
  );
};

export default LazyImage;