import React from 'react';
import { Icon } from '@iconify/react';
import styles from './ButtonEmail.module.scss';

const ButtonEmail = ({ label = 'Enviar correo', email = 'contacto@empresa.com', subject = 'Consulta', body = '', className = '', variant = 'default' }) => {
    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);

    return (
        <a 
            href={`mailto:${email}?subject=${encodedSubject}&body=${encodedBody}`} 
            className={`${styles.button} ${styles[variant]} ${className}`} 
            target="_blank" 
            rel="noopener noreferrer"
        >
            <div className={styles.buttonText}>{label}</div>
            <div className={styles.icon}>
                <Icon icon="material-symbols:arrow-forward-rounded" className={styles.arrowIcon} />
            </div>
        </a>
    );
};

export default ButtonEmail;