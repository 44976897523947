import React, { useState, useEffect } from "react";
import EventCard from "../EventCard/EventCard";
import axios from "axios";
import styles from "./EventosContenedor.module.scss";
import variables from "../../Context/Variables";
import EventoCardSkeleton from "../EventCard/Skeleton/EventoCardSkeleton";

function EventosFinalizadosCont() {
  const [eventos, setEventos] = useState([]);
  const [error, setError] = useState(null);
  const [estaCargando, setEstaCargando] = useState(true);
  const [mostrarCantidad, setMostrarCantidad] = useState(3);

  useEffect(() => {
    axios
      .get(variables.API_BASE_URL + "/api/eventos/eventosExpirados")
      .then((response) => {
        setEventos(response.data);
        setEstaCargando(false);
      })
      .catch((error) => {
        console.error("Error al obtener los eventos:", error);
        setError(error.toString());
        setEstaCargando(false);
      });
  }, []);

  if (error) {
    return <div>Error al cargar los eventos: {error}</div>;
  }

  if (estaCargando) {
    return (
      <div className={styles.eventos}>
        <EventoCardSkeleton />
        <EventoCardSkeleton />
        <EventoCardSkeleton />
      </div>
    );
  }

  const handleVerMas = () => {
    setMostrarCantidad(eventos.length);
  };

  return (
    <div className={styles.eventos}>
      {Array.isArray(eventos) &&
        eventos.slice(0, mostrarCantidad).map((evento) => (
          <EventCard key={evento.id} expirado={true} evento={evento} />
        ))}
      {mostrarCantidad < eventos.length && (
        <div className={styles.contBoton}>
          <button onClick={handleVerMas} className={styles.verMas}>
            Ver más
          </button>
        </div>
      )}
    </div>
  );
}

export default EventosFinalizadosCont;