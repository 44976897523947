import React from 'react';
import styles from './Mission.module.scss';
import { Icon } from '@iconify/react/dist/iconify.js';

const helpItems = [
    {
        title: 'Automatización de ventas',
        description: 'Automatizamos todo tu proceso de ventas para que tengas más tiempo para gestionar y disfrutar de tu evento. El público de tu evento compra sus entradas directamente en el sitio web, de forma ágil y segura, pagando con cualquier tarjeta.'
    },
    {
        title: 'Digitalización',
        description: '¡Olvidate de los procesos manuales! Todo tu evento, en una sola plataforma, para que los asistentes compren sus entradas y consumiciones de forma 100% online, las 24 horas del día, los 365 días del año.'
    },
    {
        title: 'Servicio integral',
        description: 'Vas a tener un asesor personal a disposición para ayudarte en todos los procesos y resolver todas las dudas. Nuestros clientes son la prioridad, siempre.'
    }
];

const Mission = () => {
    return (
        <div className={styles['mission-container']}> 
            <div className={styles['mission-section']}>
                <div className={styles['mission-title']}>Nuestra misión</div>
                <div className={styles['mission-description']}>
                    <div className={styles['mission-quote']}>
                        “Hacer que la experiencia de compra de tickets para eventos sea simple, accesible y emocionante para todos.”
                    </div>
                    <div className={styles['mission-text']}>
                        Nos esforzamos por brindar una plataforma fácil de usar y segura para que nuestros clientes puedan descubrir y comprar entradas para una amplia variedad de eventos de manera rápida y sencilla.
                    </div>
                </div>
            </div>
            <div className={styles['help-section']}>
                <div className={styles['help-title']}>¿En qué te ayudamos?</div>
                <div className={styles['help-list']}>
                    {helpItems.map((item, index) => (
                        <div className={styles['help-item']} key={index}>
                            <Icon className={styles.icon} width={30} icon="solar:check-circle-bold-duotone" />
                            <div className={styles['help-item-title']}>{item.title}</div>
                            <div className={styles['help-item-description']}>
                                {item.description}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Mission;
