import React, { useState, useEffect } from "react";
import styles from "./PanelProductor.module.scss";
import MisEventos from "../mis-eventos/MisEventos";
import VentasUltimos3Meses from "../../Components/Graficos/Ventas ult. 3 meses/VentasUltimos3Meses";
import { useNavigate } from "react-router-dom";
import GraficoComparativoBarras from "../../Components/Graficos/Grafico de barras/GraficoDeBarras";

const PanelProductor = () => {
  const [jwt, setJwt] = useState("");
  const [nombreProductor, setNombreProductor] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const details = ensureAuthenticated();
    if (details) {
      setJwt(details.jwt);
      setNombreProductor(details.username.split(" ")[0]);
    }
  }, []);

  const ensureAuthenticated = () => {
    const userDetails =
      sessionStorage.getItem("userDetails") ||
      localStorage.getItem("userDetails");
    if (!userDetails) {
      navigate("/login");
    } else {
      const details = JSON.parse(userDetails);
      return details;
    }
  };

  return (
    <div className={styles.bodyPanel}>

      <div className={styles.contImgYTitulos}>
        
        <div className={styles.contImg}>
          <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/PanelProductor/pexels-marc-schulte-29528324.webp" />
          <div className={styles.contTituloYSub}>
            <h1>Panel de productor</h1>
            <h3>Te damos la bienvenida {nombreProductor}</h3>
          </div>
        </div>

        <section className={styles.contGraficos}>
          <GraficoComparativoBarras jwt={jwt} />
          <VentasUltimos3Meses jwt={jwt} />
        </section>

        <section className={styles.sectionEventos}>
          <h3 className={styles.tusEventos}>Tus eventos</h3>
          <MisEventos />
        </section>
        
      </div>

    </div>
  );
};

export default PanelProductor;
