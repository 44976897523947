import React from 'react'
import styles from "./LoaderSimplepass.module.scss"

const LoaderSimplepass = () => {
  return (
    <>
        <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/INDEX/logo-spinning-recortado.svg" alt="logo-spinning" className={styles.logo} srcset="" />
    </>
  )
}

export default LoaderSimplepass