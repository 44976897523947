import React from 'react';
import styles from './SkeletonProducto.module.scss'; // Asegúrate de agregar el archivo de estilos

const SkeletonProducto = () => {
    return (
        <div className={styles.skeletonProducto}>
            <div className={`${styles.skeleton} ${styles.skeletonImage}`}></div>
            <div className={styles.skeletonDatos}>
                <div className={`${styles.skeleton} ${styles.skeletonTitle}`}></div>
                <div className={`${styles.skeleton} ${styles.skeletonPrice}`}></div>
            </div>
        </div>
    );
};

export default SkeletonProducto;