import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Icon } from "@iconify/react/dist/iconify.js";
import variables from '../../../Context/Variables';
import { useAuth } from '../../../Context/AuthProvider';
import styles from './ExcelButton.module.scss';
import Alert from '../../../Components/Alert/Alert';

const DownloadExcelButton = ({id}) => {
  const { jwt } = useAuth(); 
  const [downloading, setDownloading] = useState(false);

  const handleDownloadExcel = async () => {
    if (!jwt) {
      Alert({
        tipo: 'error',
        titulo: 'Error de autenticación',
        descripcion: 'No se pudo verificar la autenticación. Intente nuevamente.'
      });
      return;
    }

    setDownloading(true);
    
    try {
      // Realizar la solicitud al backend para obtener los datos de tickets enviados directamente por evento
      const response = await axios.get(`${variables.API_BASE_URL}/api/registro/registros/evento/${id}`, {
        headers: {
            Authorization: `Bearer ${jwt}`,
        }
      });

      // Verificar si la respuesta tiene datos
      if (response.status === 200 && response.data.length > 0) {
        const data = response.data.map(ticket => ({
          'Id': ticket.id,
          'Nombre completo': ticket.nombreCompleto,
          'Email': ticket.email,
          'Nombre tipo de ticket': ticket.nombreTipoDeTicket,
          'Fecha y hora hecho': ticket.fechaYHoraHecho, 
          'Cantidad': ticket.cantidad,
          'Precio': ticket.precio
        }));

        // Convertir los datos en una hoja de Excel
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Tickets Enviados');

        // Convertir el archivo a Blob y descargarlo
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, 'tickets_enviados_directamente.xlsx');

        Alert({
          tipo: 'success',
          titulo: 'Descarga exitosa',
          descripcion: 'El archivo Excel se ha descargado correctamente'
        });
      } else {
        Alert({
          tipo: 'warning',
          titulo: 'Sin datos',
          descripcion: 'No hay tickets enviados directamente para descargar.'
        });
      }
    } catch (error) {
      console.error('Error al descargar el archivo Excel en el front:', error);
      Alert({
        tipo: 'error',
        titulo: 'Error',
        descripcion: 'Hubo un error al descargar el archivo Excel.'
      });
    } finally {
      setDownloading(false); // Finalizar la descarga
    }
  };

  return (
    <div>
      <button className={styles.downloadButton} onClick={handleDownloadExcel} disabled={downloading}>
        {downloading ? 'Descargando...' : 'Descargar Informe'}
        <Icon width={25} icon="mdi:microsoft-excel" />
      </button>
    </div>
  );
};

export default DownloadExcelButton;