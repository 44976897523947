// ConectorImpresora.js

/**
 * Una clase para interactuar con el plugin v3
 *
 * @date 2022-09-28
 * @author parzibyte
 * @see https://parzibyte.me/blog
 */

class Operacion {
    constructor(nombre, argumentos) {
        this.nombre = nombre;
        this.argumentos = argumentos;
    }
}

class ConectorPlugin {
    static URL_PLUGIN_POR_DEFECTO = "http://localhost:8000";
    static Operacion = Operacion;
    static ALINEACION_IZQUIERDA = 0;
    static ALINEACION_CENTRO = 1;
    static ALINEACION_DERECHA = 2;
    static RECUPERACION_QR_BAJA = 0;
    static RECUPERACION_QR_MEDIA = 1;
    static RECUPERACION_QR_ALTA = 2;
    static RECUPERACION_QR_MEJOR = 3;
    static ALGORITMO_IMAGEN_RASTERIZACION = 0;
    static ALGORITMO_IMAGEN_COLUMNAS = 1;
    static ALGORITMO_IMAGEN_NV_GRAPHICS = 2;

    constructor(ruta = ConectorPlugin.URL_PLUGIN_POR_DEFECTO, serial = "ZGUyY2E0NzZfXzIwMjQtMTEtMTJfXzIwMjUtMDEtMTEjIyNmQnBRdmdENHN2MFVBMFRtYytMS2F1UEJPR1BmOWFrRlgxNE5Edzdpdk05Qm9ZWDh6UFZvWWgxbURSMlRkdmZxc2NEV3ErUSsyM3Y2cWt1Sm03L3gyRExmMlpyMmpYYVlEeG9pSDVkRG10ZEZqSlFVY09ReE4yckU4ZGxESzFaM04wWVgxZ0hNWVJsUmZsRVV3cHB4TUNLUHVvSWZIZmN2Zytmb1ZPWjNXaWk0c3pKeGVndFlOUEZWeGwwUitNdStabllXTFlTYWd5dEJjeU1OZE1VNW9rODNVZ2o1d21xSDRJL2s3ZEZTb1VDeDhpazhPU3V1d3N5YWVhNWV2OGxyT3k4TUJTM2dUUmd5c0t4WE1EWFlpOTE4ZEpVdW5mKzBQZkE4dUY0TGdPWGdkNEIzMWZMWnpQNTVnVzc4Qm5zdG9oZmJaM0hmT1EydVpqd29xTm83N0ZVZXpFSWRQMklOeG5QcjhFMlY5MlRzdEpubmZORjludXdKTmFsNXpMZzlZM0FKV2tSRUxSc2ZWZzEvcnJteURXUHdaN3pUQ3FPVzBTamNYZnVieWx2RHo0UEpqOVR0TCs2cC8zQ3JZdXdjZWdsRDNCSGFIdTUzQVZBSGcxeWpmWUV5czk3cW9TRXZ6bXNxYjR2RHNVWW5yYzh1QlhuSEVwbHA0YVFQYUhteWtOUVlqdHF4VHlIdUVwWllESXRteW5uWDhvZjUybTBDdDlmQ1J3dDNRSXErTE9uOEhIYkM2Um5iUFFEeW9pMndCMStHdHlBelpWWFFyNis2a1R6TlovVldIZDhCemhkT0haYk1KNmJ2UFJraXdYR2plR0pNRy92Rk9rM3ZEZ3BSRmVseXErQ0g0WjNhdXUzVmxJcGJ2TVUrbWlnVVh3NFR6VVJTVGVxVWRDbz0=") {
        this.ruta = ruta;
        this.serial = serial;
        this.operaciones = [];
    }

    CargarImagenLocalEImprimir(ruta, maximoAncho, algoritmo) {
        this.operaciones.push(new Operacion("CargarImagenLocalEImprimir", Array.from(arguments)));
        return this;
    }
    Corte(lineas) {
        this.operaciones.push(new ConectorPlugin.Operacion("Corte", Array.from(arguments)));
        return this;
    }
    CorteParcial() {
        this.operaciones.push(new ConectorPlugin.Operacion("CorteParcial", Array.from(arguments)));
        return this;
    }
    DefinirCaracterPersonalizado(caracterRemplazo, matriz) {
        this.operaciones.push(new ConectorPlugin.Operacion("DefinirCaracterPersonalizado", Array.from(arguments)));
        return this;
    }
    DescargarImagenDeInternetEImprimir(urlImagen, maximoAncho, algoritmo) {
        this.operaciones.push(new ConectorPlugin.Operacion("DescargarImagenDeInternetEImprimir", Array.from(arguments)));
        return this;
    }
    DeshabilitarCaracteresPersonalizados() {
        this.operaciones.push(new ConectorPlugin.Operacion("DeshabilitarCaracteresPersonalizados", Array.from(arguments)));
        return this;
    }
    DeshabilitarElModoDeCaracteresChinos() {

        this.operaciones.push(new ConectorPlugin.Operacion("DeshabilitarElModoDeCaracteresChinos", Array.from(arguments)));
        return this;
    }
    EscribirTexto(texto) {
        this.operaciones.push(new ConectorPlugin.Operacion("EscribirTexto", Array.from(arguments)));
        return this;
    }
    EstablecerAlineacion(alineacion) {
        this.operaciones.push(new ConectorPlugin.Operacion("EstablecerAlineacion", Array.from(arguments)));
        return this;
    }
    EstablecerEnfatizado(enfatizado) {
        this.operaciones.push(new ConectorPlugin.Operacion("EstablecerEnfatizado", Array.from(arguments)));
        return this;
    }
    EstablecerFuente(fuente) {
        this.operaciones.push(new ConectorPlugin.Operacion("EstablecerFuente", Array.from(arguments)));
        return this;
    }
    EstablecerImpresionAlReves(alReves) {
        this.operaciones.push(new ConectorPlugin.Operacion("EstablecerImpresionAlReves", Array.from(arguments)));
        return this;
    }
    EstablecerImpresionBlancoYNegroInversa(invertir) {
        this.operaciones.push(new ConectorPlugin.Operacion("EstablecerImpresionBlancoYNegroInversa", Array.from(arguments)));
        return this;
    }
    EstablecerRotacionDe90Grados(rotar) {
        this.operaciones.push(new ConectorPlugin.Operacion("EstablecerRotacionDe90Grados", Array.from(arguments)));
        return this;
    }
    EstablecerSubrayado(subrayado) {
        this.operaciones.push(new ConectorPlugin.Operacion("EstablecerSubrayado", Array.from(arguments)));
        return this;
    }
    EstablecerTamañoFuente(multiplicadorAncho, multiplicadorAlto) {
        this.operaciones.push(new ConectorPlugin.Operacion("EstablecerTamañoFuente", Array.from(arguments)));
        return this;
    }
    Feed(lineas) {
        this.operaciones.push(new ConectorPlugin.Operacion("Feed", Array.from(arguments)));
        return this;
    }
    HabilitarCaracteresPersonalizados() {
        this.operaciones.push(new ConectorPlugin.Operacion("HabilitarCaracteresPersonalizados", Array.from(arguments)));
        return this;
    }
    HabilitarElModoDeCaracteresChinos() {
        this.operaciones.push(new ConectorPlugin.Operacion("HabilitarElModoDeCaracteresChinos", Array.from(arguments)));
        return this;
    }
    ImprimirCodigoDeBarrasCodabar(contenido, alto, ancho, algoritmo) {

        this.operaciones.push(new ConectorPlugin.Operacion("ImprimirCodigoDeBarrasCodabar", Array.from(arguments)));
        return this;
    }

    ImprimirCodigoDeBarrasCode128(contenido, alto, ancho, algoritmo) {
        this.operaciones.push(new ConectorPlugin.Operacion("ImprimirCodigoDeBarrasCode128", Array.from(arguments)));
        return this;
    }
    ImprimirCodigoDeBarrasCode39(contenido, incluirSumaDeVerificacion, modoAsciiCompleto, alto, ancho, algoritmo) {
        this.operaciones.push(new ConectorPlugin.Operacion("ImprimirCodigoDeBarrasCode39", Array.from(arguments)));
        return this;
    }

    ImprimirCodigoDeBarrasCode93(contenido, alto, ancho, algoritmo) {
        this.operaciones.push(new ConectorPlugin.Operacion("ImprimirCodigoDeBarrasCode93", Array.from(arguments)));
        return this;
    }

    ImprimirCodigoDeBarrasEan(contenido, alto, ancho, algoritmo) {
        this.operaciones.push(new ConectorPlugin.Operacion("ImprimirCodigoDeBarrasEan", Array.from(arguments)));
        return this;
    }
    ImprimirCodigoDeBarrasEan8(contenido, alto, ancho, tamañoImagen) {
        this.operaciones.push(new ConectorPlugin.Operacion("ImprimirCodigoDeBarrasEan8", Array.from(arguments)));
        return this;
    }
    ImprimirCodigoDeBarrasPdf417(contenido, nivelSeguridad, alto, ancho, tamañoImagen) {
        this.operaciones.push(new ConectorPlugin.Operacion("ImprimirCodigoDeBarrasPdf417", Array.from(arguments)));
        return this;
    }
    ImprimirCodigoDeBarrasTwoOfFiveITF(contenido, intercalado, alto, ancho, algoritmo) {
        this.operaciones.push(new ConectorPlugin.Operacion("ImprimirCodigoDeBarrasTwoOfFiveITF", Array.from(arguments)));
        return this;
    }
    ImprimirCodigoDeBarrasUpcA(contenido, alto, ancho, algoritmo) {
        this.operaciones.push(new ConectorPlugin.Operacion("ImprimirCodigoDeBarrasUpcA", Array.from(arguments)));
        return this;
    }
    ImprimirCodigoDeBarrasUpcE(contenido, alto, ancho, algoritmo) {
        this.operaciones.push(new ConectorPlugin.Operacion("ImprimirCodigoDeBarrasUpcE", Array.from(arguments)));
        return this;
    }
    ImprimirCodigoQr(contenido, anchoMaximo, nivelRecuperacion, algoritmo) {
        this.operaciones.push(new ConectorPlugin.Operacion("ImprimirCodigoQr", Array.from(arguments)));
        return this;
    }
    ImprimirImagenEnBase64(imagenCodificadaEnBase64, maximoAncho, algoritmo) {
        this.operaciones.push(new ConectorPlugin.Operacion("ImprimirImagenEnBase64", Array.from(arguments)));
        return this;
    }

    Iniciar() {
        this.operaciones.push(new ConectorPlugin.Operacion("Iniciar", Array.from(arguments)));
        return this;
    }

    Pulso(pin, tiempoEncendido, tiempoApagado) {
        this.operaciones.push(new ConectorPlugin.Operacion("Pulso", Array.from(arguments)));
        return this;
    }

    TextoSegunPaginaDeCodigos(numeroPagina, pagina, texto) {
        this.operaciones.push(new ConectorPlugin.Operacion("TextoSegunPaginaDeCodigos", Array.from(arguments)));
        return this;
    }

    GenerarImagenAPartirDeHtmlEImprimir(html, anchoPagina, maximoAncho, algoritmo) {
        this.operaciones.push(new ConectorPlugin.Operacion("GenerarImagenAPartirDeHtmlEImprimir", Array.from(arguments)));
        return this;
    }

    GenerarImagenAPartirDePaginaWebEImprimir(urlPagina, anchoPagina, maximoAncho, algoritmo) {
        this.operaciones.push(new ConectorPlugin.Operacion("GenerarImagenAPartirDePaginaWebEImprimir", Array.from(arguments)));
        return this;
    }

    static async obtenerImpresoras(ruta) {
        if (ruta) ConectorPlugin.URL_PLUGIN_POR_DEFECTO = ruta;
        const response = await fetch(ConectorPlugin.URL_PLUGIN_POR_DEFECTO + "/impresoras");
        return await response.json();
    }

    static async obtenerImpresorasRemotas(ruta, rutaRemota) {
        if (ruta) ConectorPlugin.URL_PLUGIN_POR_DEFECTO = ruta;
        const response = await fetch(ConectorPlugin.URL_PLUGIN_POR_DEFECTO + "/reenviar?host=" + rutaRemota);
        return await response.json();
    }


    async imprimirEnImpresoraRemota(nombreImpresora, rutaRemota) {
        const payload = {
            operaciones: this.operaciones,
            nombreImpresora,
            serial: this.serial,
        };
        const response = await fetch(this.ruta + "/reenviar?host=" + rutaRemota, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        return await response.json();
    }

    async imprimirEn(nombreImpresora) {
        const payload = {
            operaciones: this.operaciones,
            nombreImpresora,
            serial: this.serial,
        };
        const response = await fetch(`${this.ruta}/imprimir`, {
            method: "POST",
            body: JSON.stringify(payload),
        });
        return await response.json();
    }

    // Métodos estáticos ahora son métodos normales para poder ser llamados sin `static`
    static async obtenerImpresoras(ruta = ConectorPlugin.URL_PLUGIN_POR_DEFECTO) {
        const response = await fetch(`${ruta}/impresoras`);
        return await response.json();
    }

    static async obtenerImpresorasRemotas(ruta, rutaRemota) {
        const response = await fetch(`${ruta}/reenviar?host=${rutaRemota}`);
        return await response.json();
    }
}

// Exporta la clase para que puedas importarla en tus componentes de React
export default ConectorPlugin;