import React, { useState, useEffect } from 'react';
import styles from './MisDatos.module.scss';
import Alert from '../../Components/Alert/Alert';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import variables from '../../Context/Variables';
import skeleton from "./Skeleton/MisDatosSkeleton.module.scss"

const MisDatos = () => {
  const [usuario, setUsuario] = useState({ username: '', celular: '', email: '', dni: '', esCuentaGoogle: false });
  const navigate = useNavigate();
  const [cargando, setCargando] = useState(true);
  const [jwt, setJwt] = useState("");

  useEffect(() => {
    document.title = "Mis datos";
    const userDetailsString = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');

    if (!userDetailsString) {
      navigate("/login");
    }

    const userDetails = JSON.parse(userDetailsString);
    setJwt(userDetails.jwt);

    axios.get(variables.API_BASE_URL + "/api/usuarios/data", {
      headers: {
        Authorization: `Bearer ${userDetails.jwt}`,
      },
    })
      .then((response) => {
        setUsuario({
          ...usuario,
          username: response.data.username || "",
          celular: response.data.celular || "",
          email: response.data.email || "",
          dni: response.data.dni || "",
          esCuentaGoogle: response.data.esCuentaGoogle || false,
        });
        setCargando(false);
      })
      .catch((error) => {
        console.error("Error obteniendo los datos del usuario:", error);
        Alert({
          tipo: 'error',
          titulo: 'Error al obtener los datos del usuario',
          descripcion: 'No se pudieron cargar tus datos. Por favor, intenta más tarde.',
          duracion: 4000
        });
      });
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const result = await Alert({
      tipo: 'warning',
      titulo: '¿Estás seguro?',
      descripcion: 'Confirmar si deseas actualizar tus datos.',
      duracion: 0
    });

    if (result) {
      try {
        await axios.post(variables.API_BASE_URL + "/api/usuarios/update", usuario, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        });
        
        Alert({
          tipo: 'success',
          titulo: 'Datos actualizados con éxito!',
          descripcion: 'Tus datos han sido actualizados correctamente.',
          duracion: 4000
        });
      } catch (error) {
        console.error("Error:", error);
        Alert({
          tipo: 'error',
          titulo: 'Error al actualizar los datos',
          descripcion: 'Hubo un problema al intentar actualizar tus datos, por favor intenta de nuevo más tarde.',
          duracion: 4000
        });
      }
    }
  };

  if (cargando) {
    return (
      <div className={skeleton.skeletonWrapper}>
        <div className={skeleton.skeletonHeading}></div>
        <div className={skeleton.skeletonText}></div>
        <div className={skeleton.skeletonInput}></div>
        <div className={skeleton.skeletonInput}></div>
        <div className={skeleton.skeletonInput}></div>
        <div className={skeleton.skeletonInput}></div>
        <div className={skeleton.skeletonButton}></div>
      </div>

    );
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUsuario((prevUsuario) => ({ ...prevUsuario, [name]: value }));
  };

  return (
    <div className={styles.registroForm}>
      <div className={`${styles.columnCenter} ${styles.introMisDatos}`}>
        <h1>Mis datos</h1>
        {usuario.esCuentaGoogle && <p style={{color:"red"}}>No puedes modificar tus datos ya que tu cuenta es gestionada a través de Google.</p>}
        <p>Acá están los datos de tu cuenta; si lo deseas, podés modificarlos, pero <b>recordá poner siempre <u>información válida</u>, esta será utilizada para tus próximas operaciones en la plataforma.</b></p>
      </div>
      <form id="perfilForm" onSubmit={handleSubmit}>
        <div className={styles.inputGroup}>
          <label htmlFor="username">Nombre de Usuario:</label>
          <input type="text" id="username" name="username" value={usuario.username} readOnly disabled={usuario.esCuentaGoogle} />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="celular">Celular:</label>
          <input type="text" id="celular" disabled={usuario.esCuentaGoogle} name="celular" value={usuario.celular} onChange={handleChange} />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" disabled={usuario.esCuentaGoogle} name="email" value={usuario.email} onChange={handleChange} />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="dni">DNI:</label>
          <input type="text" id="dni" disabled={usuario.esCuentaGoogle} name="dni" value={usuario.dni} onChange={handleChange} />
        </div>
        <button type="submit" disabled={usuario.esCuentaGoogle}>Actualizar Datos</button>
      </form>
    </div>
  );
};

export default MisDatos;
