import React from 'react';
import styles from './TipoDeTicketSkeleton.module.scss'; 

const TipoDeTicketSkeleton = () => {
  return (
    <div className={styles.tipoDeTicketSkeleton}>
      <p className={`${styles.numeroTipoTicketSkeleton} ${styles.skeleton}`} style={{ height: '35px', width: '70%', margin: '0' }}>
      </p>

      <div className={styles.skeleton} style={{ height: '20px', width: '60%', margin: '10px 0 4px 0' }}></div>
      <div className={`${styles.inputSkeleton} ${styles.skeleton}`} style={{ height: '42px', width: '100%', margin: '0' }}></div>

      <div className={styles.skeleton} style={{ height: '20px', width: '60%', margin: '10px 0 4px 0' }}></div>
      <div className={`${styles.inputSkeleton} ${styles.skeleton}`} style={{ height: '42px', width: '100%', margin: '0' }}></div>

      <div className={styles.skeleton} style={{ height: '20px', width: '60%', margin: '10px 0 4px 0' }}></div>
      <div className={`${styles.inputSkeleton} ${styles.skeleton}`} style={{ height: '42px', width: '100%', margin: '0' }}></div>

      <div className={`${styles.eliminarBtnSkeleton} ${styles.skeleton}`} style={{ height: '40px', width: '60%', margin: '10px auto' }}></div>
    </div>
  );
};

export default TipoDeTicketSkeleton;
