import React from 'react'
import styles from "./TicketSkeleton.module.scss"

const EventoCardSkeleton = () => {
    return (
        <article className={styles.cardEvento}>

            <div style={{width:"100%", }} className={styles.contEncabezados}>
                <div style={{ height: '10px', width: '80%', margin:"0 auto"}} className={styles.skeleton} />
                <div style={{ height: '20px', width: '25%', margin:"4px auto"}} className={styles.skeleton} />
            </div>
            <div style={{width:"100%", borderBottom: 'solid 1px #ccc' }} className={styles.contEncabezados}>
                simplepass
            </div>


            <div className={styles.contenidoEvento}>
                <div className={styles.eventDetails}>
                    <div className={styles.eventTitle + ' ' + styles.skeleton} style={{ height: '50px', width: '90%', margin: '10px auto 10px 0' }}></div>
                    <div className={styles.atributoCard + ' ' + styles.skeleton} style={{ height: '15px', width: '80%', margin: '8px auto 0 0' }}></div>
                    <div className={styles.atributoCard + ' ' + styles.skeleton} style={{ height: '15px', width: '80%', margin: '8px auto 0 0' }}></div>
                    <div className={styles.atributoCard + ' ' + styles.skeleton} style={{ height: '15px', width: '80%', margin: '8px auto 0 0' }}></div>
                </div>
                <div className={styles.cardEventoImgLink + ' ' + styles.skeleton} style={{ height: '100%', borderRadius: '15px' }} />
                <div className={styles.botonComprar + ' ' + styles.skeleton} style={{ height: '40px' }}></div>
            </div>
        </article>
    )
}

export default EventoCardSkeleton