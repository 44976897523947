import React from "react";
import styles from "./NotFoundPage.module.scss"; 

const NotFoundPage = () => {
  return (
    <section className={styles.bodyEventoDetalles}>
      <div className={styles.eventoNoDisponible}>
        <img
          src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Imagenes+para+errores/error-404.png"
          alt="Página no encontrada"
          className={styles.imagenErrorEvento}
        />
        <h1>La página que buscas no existe o fue movida.</h1>
      </div>
    </section>
  );
};

export default NotFoundPage;
