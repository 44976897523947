import React from 'react';
import styles from './EventoCardSkeleton.module.scss';

const EventoCardSkeleton = () => {
  return (
    <article className={styles.cardEvento}>
      {/* Imagen del evento (puede ser un link o solo una imagen) */}
      <div className={`${styles.cardEventoImgLink} ${styles.skeleton}`}>
        <div className={`${styles.cardEventoImg} ${styles.skeleton}`} />
      </div>

      <div className={styles.contenidoEvento}>
        <div className={styles.eventDetails}>
          <div className={`${styles.eventTitle} ${styles.skeleton}`} style={{ height: '20px', width: '70%', marginBottom: '10px' }}></div>
          <div className={`${styles.atributoCard} ${styles.skeleton}`} style={{ height: '15px', width: '80%', marginBottom: '4px' }}></div>
        </div>

        <div className={styles.footer}>
          <div className={`${styles.atributoUbicacion} ${styles.skeleton}`} style={{ height: '15px', width: '60%', marginBottom: '4px' }}></div>
          <div className={`${styles.botonComprar} ${styles.skeleton}`} style={{ height: '40px', width: '40%' }}></div>
        </div>
      </div>
    </article>
  );
}

export default EventoCardSkeleton;