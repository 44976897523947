import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import styles from "./FiestaNacionalAcc.module.scss";
import LazyImage from '../../Components/LazyImage/LazyImage';
import { Icon } from '@iconify/react/dist/iconify.js';
import axios from 'axios';
import variables from '../../Context/Variables';
import EventCard from '../../Components/EventCard/EventCard';
import EventosNoEncontrados from '../../Components/EventosNoEncontrados/EventosNoEncontrados';
import LoaderSimplepass from '../../Components/Buttons-loaders-inputs/LoaderSimplepass/LoaderSimplepass';

const FiestaNacionalAcc = () => {

    const [eventos, setEventos] = useState([]);
    const [error, setError] = useState(null);
    const [estaBuscando, setEstaBuscando] = useState(true);
    const eventosBuscados = useRef(false);

    useEffect(() => {
        document.title = "Fiesta Nacional del Asado con Cuero";
    }, []);

    useEffect(() => {
        const busqueda = "Fiesta Nacional del Asado con Cuero";

        if (!eventosBuscados.current) {
            eventosBuscados.current = true;
            const buscarEventos = () => {
                axios.get(`${variables.API_BASE_URL}/api/eventos/search?q=${busqueda}`)
                    .then(response => {
                        setEventos(response.data);
                        setEstaBuscando(false);
                        console.log(response.data);
                        
                    })
                    .catch(err => {
                        setError(err.message);
                        setEstaBuscando(false);
                    });
            }
            buscarEventos();
        }

    }, []);

    return (
        <>
            <Helmet>
                <title>Fiesta Nacional del Asado con Cuero - Entradas Oficiales</title>
                <meta name="description" content="Compra tus entradas oficiales para la Fiesta Nacional del Asado con Cuero. Eventos, fechas y tickets disponibles." />
                <meta name="keywords" content="Fiesta Nacional, Asado con Cuero, entradas, tickets, eventos, Argentina" />
                <meta property="og:title" content="Fiesta Nacional del Asado con Cuero - Entradas Oficiales" />
                <meta property="og:description" content="Compra tus entradas oficiales para la Fiesta Nacional del Asado con Cuero. Eventos, fechas y tickets disponibles." />
                <meta property="og:image" content="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Asado+con+cuero/portada-nueva.webp" />
                <meta property="og:url" content={window.location.href} />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className={styles.body}>
                <div className={styles.header}>

                    <LazyImage className={styles.imagenHeader} src={"https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Asado+con+cuero/portada-nueva.webp"} alt={"Fiesta Nacional del Asado con Cuero - Imagen de portada"} />

                    <h1>Entradas oficiales para la <b>Fiesta Nacional del Asado con Cuero</b></h1>
                    <h2>Compra tus tickets acá <Icon width={20} icon="lucide:tickets" /></h2>
                    <div className={styles.contenedorMetodosDePago}>
                        <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/metodosPago/logo-Mastercard.webp" className={styles.mc} alt="Logo Mastercard" />
                        <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/metodosPago/Mercado-Pago-Logo.webp" className={styles.mp} alt="Logo Mercado Pago" />
                        <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/metodosPago/new-visa-logo-high-quality-png-latest.webp" className={styles.visa} alt="Logo Visa" />
                        <div className={styles.tarjeta}><span>Crédito</span></div>
                        <div className={styles.tarjeta}><span>Débito</span></div>
                    </div>
                </div>

                <section className={styles.eventos}>
                    {estaBuscando ? (
                        <div style={{ minHeight: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <LoaderSimplepass />
                        </div>
                    ) : (
                        eventos.length === 0 ? (
                            <EventosNoEncontrados query={"Fiesta Nacional del Asado con Cuero."} />
                        ) : (
                            eventos
                                .sort((a, b) => {
                                    const [dayA, monthA, yearA] = a.fechaRealizacion.split(' ')[0].split('/');
                                    const [dayB, monthB, yearB] = b.fechaRealizacion.split(' ')[0].split('/');
                                    const dateA = new Date(yearA, monthA - 1, dayA); 
                                    const dateB = new Date(yearB, monthB - 1, dayB);
                                    return dateA - dateB;
                                })
                                .map(evento => <EventCard key={evento.id} evento={evento} />)
                        )
                    )}
                </section>

            </div>
        </>
    )
}

export default FiestaNacionalAcc