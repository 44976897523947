import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import SeccionesForm from './SeccionesForm/SeccionesForm';
import styles from "./GeneradorMapaUbicaciones.module.scss";
import variables from "../../Context/Variables";
import { useAuth } from '../../Context/AuthProvider';

const GeneradorMapaUbicaciones = () => {
  const { eventoId } = useParams();
  const [butacasPorSeccion, setButacasPorSeccion] = useState(null);
  const { jwt } = useAuth();

  useEffect(() => {
    const fetchButacas = async () => {
      try {
        const response = await axios.get(`${variables.API_BASE_URL}/api/eventos/${eventoId}/butacasPorSeccion`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });

        const butacasData = response.data;
        setButacasPorSeccion(butacasData);
      } catch (error) {
        console.error("Error al obtener las butacas del evento:", error);
      }
    };

    fetchButacas();
  }, [eventoId, jwt]);

  return (
    <div>
      {butacasPorSeccion !== null ? (
        <div className={styles.contenidoparamapa}>
          {Object.entries(butacasPorSeccion).map(([seccion, butacas], index) => (
            <div key={index} className={styles.seccion}>
              <h3>Sección: {seccion}</h3>
              {butacas.map((butaca, butacaIndex) => (
                <p key={butacaIndex}>
                  Fila: {butaca.fila}, Asiento: {butaca.numeroAsiento} (Precio: ${butaca.precio})
                </p>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <SeccionesForm />
      )}
    </div>
  );
};

export default GeneradorMapaUbicaciones;