// PagoSkeleton.jsx
import React from 'react';
import styles from './PagoSkeleton.module.scss';

const PagoSkeleton = () => {
    return (
        <>
        <tr className={styles.skeletonRow}>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
        </tr>
        <tr className={styles.skeletonRow}>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
        </tr>
        <tr className={styles.skeletonRow}>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
        </tr>
        <tr className={styles.skeletonRow}>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
        </tr>
        <tr className={styles.skeletonRow}>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
        </tr>
        <tr className={styles.skeletonRow}>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
        </tr>
        <tr className={styles.skeletonRow}>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
        </tr>
        <tr className={styles.skeletonRow}>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
        </tr>
        <tr className={styles.skeletonRow}>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
        </tr>
        <tr className={styles.skeletonRow}>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
        </tr>
        <tr className={styles.skeletonRow}>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
        </tr>
        <tr className={styles.skeletonRow}>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
        </tr>
        <tr className={styles.skeletonRow}>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
        </tr>
        <tr className={styles.skeletonRow}>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
        </tr>
        <tr className={styles.skeletonRow}>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
            <td className={styles.skeletonCell}></td>
        </tr>
        </>
    );
};

export default PagoSkeleton;