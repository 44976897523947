import React, { useState, useEffect } from 'react';
import variables from '../../Context/Variables';
import styles from "./PaymentFormEmbedded.module.scss";
import Alert from '../Alert/Alert';
import "./FormMobbex.css"
import SubmitButton from '../Buttons-loaders-inputs/SubmitButton/SubmitButton';
import { Icon } from '@iconify/react/dist/iconify.js';

const PaymentFormEmbedded = ({ carritoCompraDto, jwt, handleModalAbierto }) => {
  const [mobbexInicializado, setMobbexInicializado] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let pagoCancelado = false;

  const loadMobbexSDK = () => {
    if (!window.MobbexEmbed) {
      if (!window.mobbexSDKLoading) {
        window.mobbexSDKLoading = true;
        const script = document.createElement('script');
        script.src = 'https://res.mobbex.com/js/embed/mobbex.embed@1.0.23.js';
        script.async = true;
        script.onload = () => {
          window.mobbexSDKLoading = false;
          setMobbexInicializado(true);
        };
        document.body.appendChild(script);
      }
    } else {
      setMobbexInicializado(true);
    }
  };

  useEffect(() => {
    loadMobbexSDK();
  }, []);

  const handlePayment = async () => {
    setIsLoading(true);
    handleModalAbierto(true);

    try {
      const response = await fetch(variables.API_BASE_URL + '/api/mobbex/intent-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify(carritoCompraDto)
      });

      const data = await response.json();
      console.log('Respuesta de la API de Mobbex:', data);

      const { url, id: checkoutId } = data;

      let options = {
        id: checkoutId,
        type: 'checkout',
        onPayment: (data) => {
          console.log('Pago recibido:', data);
          setIsLoading(false);
          pagoCancelado = false;

          if (data && data.data) {
            const paymentStatus = data.data.status.code;
            const paymentText = data.data.status.text;
            const paymentId = data.data.id;
            const paymentTotal = data.data.total;
            const paymentSourceName = data.data.data.find(item => item.key === 'sourceName').value;
            const transactionId = data.data.data.find(item => item.key === 'transactionId').value;

            if (paymentStatus === "200") {
              handleModalAbierto(false);
              window.MobbexEmbed.close();
              window.location.href = `/pago-exitoso?status=${paymentStatus}&text=${paymentText}&id=${paymentId}&total=${paymentTotal}&sourceName=${paymentSourceName}&transactionId=${transactionId}`;
            } else {
              Alert({
                tipo: 'error',
                titulo: 'Pago Fallido',
                descripcion: `Transacción denegada: ${paymentText}`,
                duracion: 5000
              });
              window.MobbexEmbed.close();
            }
          } else {
            Alert({
              tipo: 'error',
              titulo: 'Error',
              descripcion: 'No se pudo procesar el pago. Por favor, intente nuevamente.',
              duracion: 5000
            });
            window.MobbexEmbed.close();
          }
        },
        onResult: (data) => {
          console.log('Resultado del pago:', data);
          pagoCancelado = true;
          window.MobbexEmbed.close();
        },
        onClose: (cancelled) => {
          console.log('Pago cerrado:', cancelled ? 'Cancelado' : 'Completado');
          setIsLoading(false);

          // Solo mostrar el alert si el pago fue cancelado y no completado
          if (pagoCancelado && cancelled) {
            Alert({
              tipo: 'warning',
              titulo: 'Pago Cancelado',
              descripcion: 'El pago ha sido cancelado por el usuario.',
              duracion: 5000
            });
          }
          pagoCancelado = false;
          window.MobbexEmbed.close();
        },
        onError: (error) => {
          console.error('Error durante el pago:', error);
          Alert({
            tipo: 'error',
            titulo: 'Error en el Pago',
            descripcion: 'Ocurrió un error inesperado durante el procesamiento del pago. Por favor, intente nuevamente.',
            duracion: 5000
          });
          setIsLoading(false);
          handleModalAbierto(false);
        }
      };

      if (window.MobbexEmbed && checkoutId) {
        console.log('Iniciando Mobbex Embed con opciones:', options);
        if (!document.getElementById('mbbx-container')) {
          const container = document.createElement('div');
          container.id = 'mbbx-container';
          container.style.position = 'fixed';
          container.style.top = '0';
          container.style.left = '0';
          container.style.width = '100%';
          container.style.height = '100%';
          container.style.zIndex = '9999';
          document.body.appendChild(container);
        }
        window.MobbexEmbed.init(options).open();
      } else {
        console.log('Redirigiendo a URL:', url);
        window.location.href = url;
      }
    } catch (error) {
      console.error('Error al procesar el pago:', error);
      Alert({
        tipo: 'error',
        titulo: 'Error',
        descripcion: 'Error al procesar el pago. Por favor, intente nuevamente.',
        duracion: 5000
      });
      handleModalAbierto(false);
      setIsLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: "250px" }}>
      <SubmitButton
        isDisabled={!mobbexInicializado}
        color="#05356f"
        isLoading={isLoading}
        onClick={handlePayment}
        className={styles.button}
        filling={false}
      >
        <Icon width={28} icon="quill:creditcard" /> Tarjeta de débito/crédito
      </SubmitButton>
      <div id="mbbx-container"></div>
    </div>
  );
};

export default PaymentFormEmbedded;