import React, { useRef } from 'react'; 

const VideoComponent = () => {
  // Ref para acceder al elemento de video
  const videoRef = useRef(null);

  // Función para alternar la reproducción del video
  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused || videoRef.current.ended) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  return (
    <video
    ref={videoRef} 
    style={{width:"90%", maxWidth:"500px", borderRadius:"10px"}}
    autoPlay
    loop
    playsInline
    onClick={togglePlayPause} // Agregar el manejador de clics
    >
    <source src={'https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/INFO+PRODUCTORES+HORIZONTAL.mp4'} type="video/mp4" />
    Tu navegador no soporta videos HTML5.
    </video>
  );
};

export default VideoComponent;
