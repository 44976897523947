import React from "react";
import styles from "../../Pages/evento/EventoDetalles/EventoDetalles.module.scss";
import { Icon } from "@iconify/react";

const ListaTickets = ({ evento, carrito, ajustarCantidadTicket, formatCurrency }) => {
  if (!evento) return null;

  const sortedTickets = evento.tiposDeTickets
    .filter(
      (tipoTicket) =>
        tipoTicket.fila === null &&
        tipoTicket.numeroAsiento === null &&
        tipoTicket.seccion === null &&
        (tipoTicket.esMesa === null || tipoTicket.esMesa === false)
    )
    .sort((a, b) => a.nombre.localeCompare(b.nombre));

  const availableTickets = sortedTickets.filter(
    (ticket) => ticket.estaDisponible && ticket.cantidadVendida < ticket.cantidadLimite
  );
  const soldOutTickets = sortedTickets.filter(
    (ticket) => !ticket.estaDisponible || ticket.cantidadVendida >= ticket.cantidadLimite
  );

  const finalTicketList = [...availableTickets, ...soldOutTickets];

  return finalTicketList.map((ticket) => {
    const agotado = !ticket.estaDisponible || ticket.cantidadVendida >= ticket.cantidadLimite;
    return (
      <div key={ticket.id} className={styles.tipoTicketGeneral}>
        <div className={styles.detalleTipoTicket}>
          <h4>{ticket.nombre}</h4>
          <h5 style={{ color: agotado ? "red" : "" }}>
            {agotado ? "AGOTADOS" : formatCurrency(ticket.precio)}
          </h5>
        </div>
        {agotado ? (
          <div
            className={styles.resultadoCantidad}
            style={{ color: agotado ? "red" : "", minWidth: agotado ? "120px" : "" }}
          >
            AGOTADOS
          </div>
        ) : (
          <div className={styles.resultadoCantidad}>
            <button className={styles.btnCantidad} onClick={() => ajustarCantidadTicket(ticket.id, -1)} disabled={agotado}>
            <Icon icon="ri:subtract-fill" width={16} />
            </button>
            <input
              type="number"
              value={carrito.tickets[ticket.id]?.cantidad || 0}
              readOnly
              className={styles.cantidad}
              style={{ textAlign: "center" }}
            />
            <button className={styles.btnCantidad} onClick={() => ajustarCantidadTicket(ticket.id, 1)} disabled={agotado}>
            <Icon icon="mingcute:add-fill"width={16} />
            </button>
          </div>
        )}
      </div>
    );
  });
};

export default ListaTickets;