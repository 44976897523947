import React from 'react';
import styles from './GestionButacasSkeleton.module.scss';

const GestionButacasSkeleton = () => {
  return (
    <div className={styles.skeletonBody}>
      <div className={`${styles.skeleton} ${styles.tituloSkeleton}`}></div>
      <div className={`${styles.skeleton} ${styles.fondoSubSkeleton}`}></div>

      <div className={`${styles.skeleton} ${styles.mapaSkeleton}`}></div>

      <div className={`${styles.skeleton} ${styles.buttonSkeleton}`}></div>
    </div>
  );
};

export default GestionButacasSkeleton;