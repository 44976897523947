import React from 'react';
import styles from './PagoCard.module.scss';

// Función de ayuda para formatear fechas a dd/mm/aa
const formatearFecha = (fechaStr) => {
    if (!fechaStr) return '';
    const fecha = new Date(fechaStr);
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); 
    const ano = fecha.getFullYear().toString().substr(2);
    return `${dia}/${mes}/${ano}`;
};


const PagoCard = ({ pago }) => {
    return (
        <div className={styles.pagoCard}>
            <div className={styles.icono}>
                <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/mis+pagos/cartera.png" alt="Icono cartera" />
            </div>
            <div className={styles.contInfo}>
                <div className={styles.fondoSub}>
                    <h2> <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/mis+pagos/cartera.png" alt="Icono cartera" style={{ verticalAlign: "middle" }} /> Pago número: {pago.id}</h2>
                </div>
                <p><strong>Collection ID:</strong> {pago.collectionId}</p>
                <p><strong>Nombre del evento:</strong> {pago.nombreEvento}</p>
                <p><strong>Fecha del evento:</strong> {pago.fechaEvento ? formatearFecha(pago.fechaEvento) : 'N/A'}</p>
                <p><strong>Total del pago:</strong> ${pago.totalPago.toFixed(2)}</p>
                <p><strong>Medio de pago:</strong> {pago.medioDePago}</p>
                <p><strong>Estado:</strong> {pago.estado}</p>
                <p><strong>Entradas compradas:</strong> {pago.cantidadTicketsComprados}</p>
                <p><strong>Consumiciones compradas:</strong> {pago.cantidadTicketsConsumicionesCompradas}</p>
            </div>
        </div>
    );
};

export default PagoCard;
