import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import EventCard from '../../Components/EventCard/EventCard';
import SearchForm from '../../Components/SearchForm/SearchForm';
import styles from "./SearchForm.module.scss";
import variables from '../../Context/Variables';
import EventosNoEncontrados from '../../Components/EventosNoEncontrados/EventosNoEncontrados';
import LoaderSimplepass from '../../Components/Buttons-loaders-inputs/LoaderSimplepass/LoaderSimplepass';

const SearchResults = () => {
  const [eventos, setEventos] = useState([]);
  const [error, setError] = useState(null);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [estaBuscando, setEstaBuscando] = useState(true);
  const [ultimaBusqueda, setUltimaBusqueda] = useState("");

  // Modificación de head
  useEffect(() => {
    document.title = "Resultados de " + '"' + urlParams.get('q') + '"';
  }, [urlParams]);

  useEffect(() => {
    const query = urlParams.get('q');
    setUltimaBusqueda(query);
    setEstaBuscando(true);

    axios.get(`${variables.API_BASE_URL}/api/eventos/search?q=${encodeURIComponent(query)}`)
      .then(response => {
        setEventos(response.data);
        setEstaBuscando(false);
      })
      .catch(err => {
        setError(err.message);
        setEstaBuscando(false);
      });
  }, [location.search]);

  if (error) {
    return <div>Error al cargar los eventos: {error}</div>;
  }

  return (
    <div>
      <SearchForm style={{marginTop:"40px"}}/>
      <section className={styles.eventos}>
        {estaBuscando ? (
          <div style={{ minHeight: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <LoaderSimplepass />
          </div>
        ) : (
          eventos.length === 0 ? (
            <EventosNoEncontrados query={ultimaBusqueda} />
          ) : (
            eventos.map(evento => <EventCard key={evento.id} evento={evento} />)
          )
        )}
      </section>
    </div>
  );
};

export default SearchResults;