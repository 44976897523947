import React, {useState, useEffect} from 'react'
import styles from "./EditarEventoPage.module.scss"
import EditarEventoForm from './EditarEventoForm/EditarEventoForm'
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const EditarEventoPage = () => {
  const { eventoId } = useParams();
  const [jwt, setJwt] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (eventoId === null || eventoId === "") {
      navigate("/");
    }
  }, [eventoId]);

  // Verificar JWT al cargar el componente
  useEffect(() => {
    document.title = "Editar evento";
    ensureAuthenticated()
   }, []);
 
   const ensureAuthenticated = () => {
     const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
     if (!userDetails) {
       navigate("/login");
     } else {
       const details = JSON.parse(userDetails);
       setJwt(details.jwt);
       return details;
     }
   };

  return (
    <div className={styles.body}>
      <EditarEventoForm eventoId={eventoId} jwt={jwt}/>
    </div>
  )
}

export default EditarEventoPage
