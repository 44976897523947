import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import styles from "./GestionRrrpEventoPage.module.scss";
import CopyableLink from '../../Components/Buttons-loaders-inputs/CopyableLink button/CopyableLink';
import '../gestion-rrpps/Skeleton/rrppSkeleton.css';
import Swal from 'sweetalert2';
import variables from "../../Context/Variables";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/AuthProvider';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import Alert from '../../Components/Alert/Alert';
import { Icon } from "@iconify/react";

const GestionRrrpEventoPage = () => {
    const [rrppsAsignados, setRrppsAsignados] = useState([]);
    const [rrppsDisponibles, setRrppsDisponibles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasPermission, setHasPermission] = useState(true);
    const { eventoId, nombre } = useParams();
    const navigate = useNavigate();
    const [assigningRrppIds, setAssigningRrppIds] = useState(new Set());
    const [unassigningRrppIds, setUnassigningRrppIds] = useState(new Set());
    const { estaAutenticado, jwt } = useAuth();

    // Método para decodificar un string de URL
    const decodeStringFromURL = (str) => {
        return decodeURIComponent(str);
    };
    useEffect(() => {
        document.title = "RRPPs del evento: " + decodeStringFromURL(nombre);
        ensureAuthenticated();
        fetchRrpps(eventoId);
    }, [eventoId, nombre]);

    const ensureAuthenticated = () => {
        const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
        if (!userDetails) {
            navigate("/login");
        } else {
            const details = JSON.parse(userDetails);
            if (details.rol !== "PRODUCTOR") {
                setHasPermission(false);
            }
        }
    };

    const fetchRrpps = async (eventoId) => {
        try {
            const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
            if (!userDetails) {
                throw new Error("Detalles de usuario no disponibles. Asegúrate de estar autenticado.");
            }

            const details = JSON.parse(userDetails);
            const token = details.jwt;
            if (!token) {
                throw new Error("Token de autenticación no disponible.");
            }

            const response = await axios.get(`${variables.API_BASE_URL}/api/eventos/${eventoId}/rrpps`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 401) {
                setHasPermission(false);
                return;
            }

            if (!response.data || !response.data.asignados || !response.data.disponibles) {
                throw new Error("La estructura de los datos de respuesta no es la esperada.");
            }

            setRrppsAsignados(response.data.asignados);
            setRrppsDisponibles(response.data.disponibles);
            setLoading(false);
        } catch (error) {
            console.error('Error al cargar los RRPPs: ', error);
            Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: error.message || 'No se pudieron cargar los RRPPs del evento.'
            });
            setLoading(false);
        }
    };

    const handleAssign = async (rrppId) => {
        setAssigningRrppIds(prev => new Set(prev.add(rrppId)));
        try {
            await axios.post(`${variables.API_BASE_URL}/api/rrpps/${eventoId}/assignRrpp/${rrppId}`, null, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            });
            fetchRrpps(eventoId);
            Alert({
                tipo: 'success',
                titulo: 'Asignado',
                descripcion: 'RRPP asignado al evento exitosamente.'
            });
        } catch (error) {
            Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: 'No se pudo asignar el RRPP al evento.'
            });
        }
        setAssigningRrppIds(prev => {
            const newSet = new Set(prev);
            newSet.delete(rrppId);
            return newSet;
        });
    };

    const handleUnassign = async (rrppId) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, desasignar!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setUnassigningRrppIds(prev => new Set(prev.add(rrppId)));
                try {
                    await axios.post(`${variables.API_BASE_URL}/api/rrpps/${eventoId}/unassignRrpp/${rrppId}`, null, {
                        headers: {
                            Authorization: `Bearer ${jwt}`
                        }
                    });
                    fetchRrpps(eventoId);
                    Alert({
                        tipo: 'success',
                        titulo: 'Desasignado',
                        descripcion: 'El RRPP ha sido desasignado del evento.'
                    });
                } catch (error) {
                    Alert({
                        tipo: 'error',
                        titulo: 'Error',
                        descripcion: 'No se pudo desasignar el RRPP del evento.'
                    });
                }
                setUnassigningRrppIds(prev => {
                    const newSet = new Set(prev);
                    newSet.delete(rrppId);
                    return newSet;
                });
            }
        });
    };

    if (!hasPermission) {
        return <NoPermissionPage />;
    }

    if (loading) {
        return (
            <div className={styles.bodyGR}>
                <h1 className={styles.titulo}>Cargando RRPPs para: {decodeStringFromURL(nombre)}...</h1>
                <div className={styles.listaRrpps}>
                    <div className="rrppItem">

                    </div>
                    <div className="rrppItem">

                    </div>
                    <div className="rrppItem">

                    </div>
                </div>
            </div>
        );
    }



    return (
        <div className={styles.bodyGR}>
            <h1 className={styles.titulo}>RRPPs Asignados a: {decodeStringFromURL(nombre)}</h1>
            <p className={styles.descrip}>Desde aquí puedes <b>asignar</b> y <b>desasignar</b> los RRPPs de tu equipo a este evento en específico, <b>obtener los enlaces</b> mediante los cuales van a vender y <b>visualizar cuántas entradas vendieron</b>. <br />
                Recuerda que para poder asignar uno nuevo, primero debes agregar uno a tu equipo desde tu perfil.</p>

            {rrppsAsignados.length > 0 ? (
                <div className={styles.listaRrpps}>
                    {rrppsAsignados.map(rrpp => (
                        <div key={rrpp.id} className={styles.rrppItemAsignado}>
                            <div className={styles.contNombreAsignado}>
                                <Icon icon="solar:user-bold"width={16} />
                                <span>{rrpp.nombre}</span>
                            </div>

                            <div className={styles.contDatos}>
                                <p>Tickets vendidos: <span>{rrpp.totalTicketsVendidos}</span></p>
                                <p>Link de venta:</p>
                                <CopyableLink link={`https://simplepass.com.ar/evento/${eventoId}/${rrpp.id}`} />
                            </div>
                            <button className={styles.botonDesasignar} onClick={() => handleUnassign(rrpp.id)} disabled={unassigningRrppIds.has(rrpp.id)}>
                                {unassigningRrppIds.has(rrpp.id) ? <Icon icon="line-md:loading-loop" width={16}/> : <><Icon icon="solar:user-block-linear"width={16} /> Desasignar</>}
                            </button>
                        </div>
                    ))}
                </div>
            ) : (
                <p style={{ color: "#FF5C5D", padding: "30px 20%", width: "90%", textAlign: "center", margin: "16px auto", maxWidth: "900px", border: "solid 1px #ccc", borderRadius: "15px" }}>No hay RRPPs asignados a este evento.</p>
            )}

            {rrppsDisponibles.length > 0 ? (
                <>
                    <h2 className={styles.subtitulo}>Asignar Nuevo RRPP</h2>
                    <ul className={styles.listaRrpps}>
                        {rrppsDisponibles.map(rrpp => (
                            <li key={rrpp.id} className={styles.rrppItem}>
                                <div className={styles.contNombre}>
                                    <Icon icon="solar:user-bold"width={16} />
                                    <span>{rrpp.nombre}</span>
                                </div>

                                <button className={styles.botonAsignar} onClick={() => handleAssign(rrpp.id)} disabled={assigningRrppIds.has(rrpp.id)}>
                                    {assigningRrppIds.has(rrpp.id) ? <Icon icon="line-md:loading-loop"width={16} /> : <>Asignar <Icon icon="mingcute:add-fill"width={16} /></>}
                                </button>
                            </li>
                        ))}
                    </ul>
                </>
            ) : null}

        </div>
    );
};

export default GestionRrrpEventoPage;
