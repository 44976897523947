import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './PhoneInputComponent.scss';

const PhoneInputComponent = ({ value, onChange }) => {
  
  const handlePhoneChange = (phone) => {
    // Prevenir la eliminación del código de área del país
    if (phone && phone.length > 0) {
      onChange(phone);
    }
  };

  return (
    <PhoneInput
      defaultCountry="AR"
      value={value}
      onChange={handlePhoneChange}
      className="phone-input"
      international
      countryCallingCodeEditable={false}
    />
  );
};

export default PhoneInputComponent;
