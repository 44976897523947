import React from 'react';
import { Icon } from '@iconify/react';
import styles from './WhatsAppButton.module.scss';

const SendWhatsApp = ({ phoneNumber, message, label = "Hablar con asistente", className = '' }) => {
    const encodedMessage = encodeURIComponent(message);

    return (
        <a 
            href={`https://wa.me/${phoneNumber}?text=${encodedMessage}`} 
            target="_blank" 
            rel="noopener noreferrer" 
            className={`${styles.button} ${className}`}
        >
            <div className={styles.buttonText}>{label}</div>
            <div className={styles.icon}>
              <Icon icon="material-symbols:arrow-forward-rounded" className={styles.arrowIcon} />
            </div>
        </a>
    );
};

export default SendWhatsApp;
