  import React from "react";
  import styles from "./EventoDetallesSkeleton.module.scss"; 
  
  const EventoDetallesSkeleton = () => {
    return (
      <div className={styles.eventPortada}>
        <section className={`${styles.contImgEventoDetails} ${styles.skeleton}`}></section>

        <div className={styles.eventDetails}>
          <div className={`${styles.eventTitle} ${styles.skeleton}`} style={{ height: '50px', width: '70%', margin: '0' }}></div>
          <div className={styles.lineaDivisora}></div>
          {[...Array(4)].map((_, index) => (
            <div key={index} className={`${styles.atributoCard} ${styles.skeleton}`} style={{ height: '15px', width: '80%', margin: '4px 0' }}></div>
          ))}
          <div className={`${styles.descripcionEvento} ${styles.skeleton}`} style={{ height: '40px', width: '100%', marginTop: '10px' }}></div>
        </div>
      </div>
    );
  };

  export default EventoDetallesSkeleton;