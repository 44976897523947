import React, { useState, useEffect } from 'react';
import SubmitButton from '..//Buttons-loaders-inputs/SubmitButton/SubmitButton';
import { Icon } from '@iconify/react/dist/iconify.js';
import styles from './CierreLoteModal.module.scss';

const CierreLoteModal = ({ show, handleClose, onSubmit }) => {
    const [nombreEncargado, setNombreEncargado] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    useEffect(() => {
        // Habilitar el botón solo si el nombre tiene al menos 4 caracteres
        setIsSubmitDisabled(nombreEncargado.trim().length < 4);
    }, [nombreEncargado]);

    const handleSubmit = () => {
        if (nombreEncargado.trim().length < 4) {
            alert('El nombre del responsable debe tener al menos 4 caracteres.');
            return;
        }
        setIsLoading(true);
        onSubmit(nombreEncargado, setIsLoading);
    };

    if (!show) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <div className={styles.modalHeader}>
                    <h2 className={styles.modalTitle}>Cerrar Lote</h2>
                    <button className={styles.closeButton} onClick={handleClose}>
                        <Icon icon="carbon:close" />
                    </button>
                </div>
                <div className={styles.modalBody}>
                    <p>Antes de cerrar el lote por favor ingrese su numbre:</p>
                    <input
                        type="text"
                        placeholder="Nombre del responsable"
                        value={nombreEncargado}
                        onChange={(e) => setNombreEncargado(e.target.value)}
                        className={styles.input}
                    />
                </div>
                <div className={styles.modalFooter}>
                    <SubmitButton
                        onClick={handleSubmit}
                        isLoading={isLoading}
                        color="#4CAF50"
                        isDisabled={isSubmitDisabled}
                    >
                        Confirmar Cierre
                    </SubmitButton>
                    <button className={styles.cancelButton} onClick={handleClose}>Cancelar</button>
                </div>
            </div>
        </div>
    );
};

export default CierreLoteModal;