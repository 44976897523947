import React, { useEffect, useRef } from 'react';
import styles from "./LocationSearchInput.module.scss";

const LocationSearchInput = ({ onLocationSelect, ubicacionPrevia }) => {
  const autocompleteInput = useRef(null);
  const autocomplete = useRef(null);

  useEffect(() => {
    autocomplete.current = new window.google.maps.places.Autocomplete(
      autocompleteInput.current
    );
    autocomplete.current.addListener('place_changed', handlePlaceSelect);
  }, []);  

  function safeStringify(obj, indent = 2) {
    let cache = new Set();
    const retVal = JSON.stringify(obj, (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (cache.has(value)) {
          return;
        }
        cache.add(value);
      }
      return value;
    }, indent);
    cache = null;
    return retVal;
  }
  
  const handlePlaceSelect = () => {
    const place = autocomplete.current.getPlace();
    console.log("Place seleccionado:", safeStringify(place));
  
    if (!place) {
      console.error("No se recibió información del lugar.");
      return;
    }
  
    if (!place.geometry) {
      console.error("El lugar seleccionado no tiene una ubicación geográfica disponible.");
      return;
    }
  
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
  
    let name = place.name || '';
    let streetNumber = '';
    let route = '';
    let city = '';
    let province = '';
    let country = '';
  
    place.address_components.forEach(component => {
      if (component.types.includes('street_number')) {
        streetNumber = component.long_name;
      } else if (component.types.includes('route')) {
        route = component.long_name;
      } else if (component.types.includes('locality')) {
        city = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        province = component.short_name;
      } else if (component.types.includes('country')) {
        country = component.long_name;
      }
    });
  
    let fullAddress = '';
    if (name && route && streetNumber) {
      fullAddress = `${name}, ${route} ${streetNumber}, ${city}, ${province}, ${country}`;
    } else if (route && streetNumber) {
      fullAddress = `${route} ${streetNumber}, ${city}, ${province}, ${country}`;
    } else if (name && city && province) {
      fullAddress = `${name}, ${city}, ${province}, ${country}`;
    } else {
      fullAddress = `${city}, ${province}, ${country}`;
    }
  
    // Remover componentes vacíos o duplicados
    fullAddress = fullAddress.split(', ').filter((item, index, self) => item && self.indexOf(item) === index).join(', ');
  
    console.log("FULL ADDRESS: " + fullAddress);
  
    onLocationSelect({
      name: place.name,
      address: fullAddress.trim(),
      mapsUrl
    });
  };  

  return (
    <div className={styles.inputContainer}>
      <input
        ref={autocompleteInput}
        type="text"
        value={ubicacionPrevia}
        placeholder="Dirección, Ciudad, Provincia."
        className={styles.locationInput}
      />
      <img 
        src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/Google_Maps.svg.png" 
        alt="Google Maps" 
        className={styles.googleMapsIcon} 
      />
    </div>
  );
};

export default LocationSearchInput;
