import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import styles from "./CopyableLink.module.scss";

const CopyableLink = ({ link }) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(link).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 10000); 
        }, () => {
            alert('Error al copiar el enlace');
        });
    };

    return (
        <div className={styles.copyableLinkContainer}>
            <input type="text" value={link} readOnly className={styles.copyableLinkInput} />
            <button onClick={copyToClipboard} className={styles.copyButton}>
                {copied ? <Icon width={20} icon="ci:check" /> : <Icon width={20} icon="solar:share-bold" />} {copied ? 'Copiado' : 'Copiar'}
            </button>
        </div>
    );
};

export default CopyableLink;