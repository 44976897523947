import React from "react";
import styles from "./NoPermissionPage.module.scss";

const NoPermissionPage = () => {
  return (
    <section className={styles.bodyEventoDetalles}>
      <div className={styles.eventoNoDisponible}>
        <img
          src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Imagenes+para+errores/no-tocar.png"
          alt="Página no encontrada"
          className={styles.imagenErrorEvento}
        />
        <h1>Este usuario no tiene los permisos necesarios para acceder a esta página.</h1>
      </div>
    </section>
  );
};

export default NoPermissionPage;