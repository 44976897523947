import React from 'react';
import html2canvas from 'html2canvas';
import styles from './TicketConsumicion.module.scss';
import { Icon } from "@iconify/react";
const TicketConsumicion = ({ ticket }) => {
  const descargarComoImagen = () => {
    const divTicket = document.getElementById(`ticket-${ticket.id}`);

    html2canvas(divTicket, { useCORS: true })
      .then((canvas) => {
        const link = document.createElement('a');
        link.download = `ticket-${ticket.id}.png`;
        link.href = canvas.toDataURL('image/png');
        link.click();
      })
      .catch((error) => {
        console.error('Error al descargar el ticket:', error);
      });
  };

  return (
    <div className={styles.ticketConsumicionCont} id={`ticket-${ticket.id}`}>
      <div className={styles.ticketConsumicionHeader}>
        <span className={styles.ticketConsumicionTitulo}>
          Ticket de consumición <img className={styles.ticketConsumicionImg} src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Tickets/soda+(1).png" alt="" />
        </span>
      </div>
      <div className={styles.ticketConsumicionBrand}>
        <span className={styles.brandName}>simple<span className={styles.brandAccent}>pass</span></span>
      </div>
      <div className={styles.ticketConsumicionQR}>
        <img crossOrigin="anonymous" className={styles.qrCodeImg} src={`${ticket.qrImg}?nocache=${new Date().getTime()}`} alt="QR Code"/>
      </div>
      <div className={styles.ticketConsumicionId}>ID: {ticket.id}</div>
      <div className={styles.ticketConsumicionProducto}>{ticket.titulo}</div>
      <div className={styles.ticketConsumicionInfo}>
        Pasa a retirarlo con este ticket directamente, sin ir por caja.
      </div>
      <button className={styles.btnDescargarIndividual} onClick={descargarComoImagen}>
        Descargar <Icon icon="solar:download-minimalistic-bold"width={16} />
      </button>
    </div>
  );
};

export default TicketConsumicion;
