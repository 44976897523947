import React, { useState, useEffect } from "react";
import styles from "./ConfiguracionFinanzas.module.scss";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import FormularioCBUCVU from "../../Components/FormularioCBU/FormularioCBUCVU";
import { useNavigate } from "react-router-dom";

const ConfiguracionFinanzas = () => {
  const [eventoId, setEventoId] = useState("");
  const { id } = useParams();
  const [jwt, setJwt] = useState("");
  const navigate = useNavigate();

  //Modificacion de head
  useEffect(() => {
    document.title = "Configurar cbu o alias";
    const details = ensureAuthenticated();
    if (details) {
      setJwt(details.jwt);
    }
  }, []);

  const ensureAuthenticated = () => {
    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!userDetails) {
      navigate("/login");
    } else {
      const details = JSON.parse(userDetails);
      return details;
    }
  };

  useEffect(() => {
    if (id) setEventoId(id);
  }, [id]);



  return (
    <div className={styles.body2paso}>
      <div>
        <h2>¡Felicidades! Tu evento ha sido creado exitosamente.</h2>
        <h3>
          El siguiente paso es configurar la cuenta bancaria o virtual a la que
          desees recibir tus pagos.
        </h3>
        <FormularioCBUCVU eventoId={eventoId} jwt={jwt} />
        <p>
          Tranquilo. Todos tus datos sensibles son almacenados de manera{" "}
          <b>segura y encriptada.</b>
        </p>
        <div className={styles.botonContainer}>
          <Link to="/panel-productor" className={styles.botonAgregar}>
            Agregar luego <Icon icon="solar:arrow-right-linear" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ConfiguracionFinanzas;
