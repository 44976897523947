import React, { useEffect, useState } from "react";
import styles from "./ConectarMp.module.scss";
import { useParams } from "react-router-dom";

const ContectarCuentasMercadoPago = () => {
  const { eventoId } = useParams();

  //Modificacion de head
  useEffect(() => {
    document.title = "Conectar cuenta Mercado Pago";
  }, []);

  return (
    <div className={styles.body2paso}>
      <div>
        <h2>¡Felicidades! Tu evento ha sido creado exitosamente.</h2>
        <h3>
          El siguiente paso para poder recibir todos los pagos directamente en
          tu cuenta es <b>presionar este botón</b>, <b>abrir</b> tu cuenta de <b>Mercado Pago</b> si
          aún no la tienes o iniciar sesión si ya lo estás, y luego <b>hacer clic
          en "Permitir"</b>.
        </h3>
      </div>
      <div className={styles.eventos}>
        <a
          href={`https://auth.mercadopago.com.ar/authorization?client_id=3217027104832057&response_type=code&platform_id=mp&redirect_uri=https://simplepass.com.ar/api/mp/auth&state=${eventoId}`}
        >
          <button className={styles.botonConectarMp}>
            Conectar Mercado Pago
          </button>
        </a>
        <p>
          Todos tus datos sensibles son almacenados de manera{" "}
          <b>segura y encriptada.</b>
        </p>
      </div>
    </div>
  );
};

export default ContectarCuentasMercadoPago;
