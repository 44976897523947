import React, { useState, useEffect } from "react";
import styles from "./Faq.module.scss";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

const Faq = () => {
  //Modificacion de head
  useEffect(() => {
    document.title = "Preguntas frecuentes";
  }, []);

  // Estado para manejar la respuesta visible
  const [activeQuestion, setActiveQuestion] = useState(null);

  // Función para alternar la respuesta visible
  const toggleAnswer = (questionId) => {
    // Cierra la pregunta si ya está abierta, de lo contrario, muestra la pregunta clickeada
    setActiveQuestion(activeQuestion === questionId ? null : questionId);
  };

  return (
    <div className={styles.faqMain}>
      <div className={styles.contImgFaq}>
        <img
          src={`https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/login.signup.signupProductor/605F6BDF-E958-410E-868B-5214CE7C577B.png`}
          alt=""
          srcset=""
        />
      </div>
      <h2 className={styles.tituloTerminosYCondiciones}>
        Preguntas Frecuentes (FAQ)
      </h2>

      <section className={styles.faq}>
        <div
          onClick={() => toggleAnswer("answer1")}
          className={styles.preguntaFaq}
        >
          <h5>
            1. ¿Cómo puedo comprar entradas en línea?{" "}
            <Icon className={styles.iconoFaq} icon={activeQuestion === "answer1" ? "solar:alt-arrow-up-bold" : "solar:alt-arrow-down-bold"} />
            </h5>
          <div
            className={styles.contenidoPregunta}
            style={{ display: activeQuestion === "answer1" ? "block" : "none" }}
            id="answer1"
          >
            <p>
              Para comprar entradas en nuestra plataforma, seguí estos sencillos
              pasos:
            </p>
            <ol>
              <li>
                Ingresá al inicio de nuestro sitio web{" "}
                <Link to={"/index"} style={{ textDecoration: "none" }}>
                  simplepass.com.ar
                </Link>
              </li>
              <li>
                Busca el evento que te interesa y selecciona la cantidad de
                entradas y consumiciones que deseas comprar.
              </li>
              <li>
                Haz clic en "Comprar" y sigue las instrucciones para completar
                el proceso de compra.
              </li>
              <li>
                Podés pagar utilizando Mercado Pago u otros métodos de pago
                disponibles.
              </li>
              <li>
                Una vez completada la compra, recibirás tus entradas por correo
                electrónico o podrás acceder a ellas desde tu cuenta personal en
                nuestro sitio.
              </li>
            </ol>
          </div>
        </div>

        <div
          onClick={() => toggleAnswer("answer2")}
          className={styles.preguntaFaq}
        >
          <h5>
            2. ¿Cómo recibiré mis entradas después de la compra?{" "}
            <Icon className={styles.iconoFaq} icon={activeQuestion === "answer1" ? "solar:alt-arrow-up-bold" : "solar:alt-arrow-down-bold"} />
          </h5>
          <div
            className={styles.contenidoPregunta}
            style={{ display: activeQuestion === "answer2" ? "block" : "none" }}
            id="answer2"
          >
            <p>
              Después de realizar la compra en línea, recibirás tus entradas de
              una de las siguientes formas:
            </p>
            <ul>
              <li>
                Por correo electrónico: Directo a tu bandeja de entrada (o en la
                carpeta de spam, ¡no olvides revisar!).
              </li>
              <li>
                En tu cuenta Simplepass: iniciá sesión y tus tickets estarán
                disponibles en la sección "Mis Tickets”.
              </li>
            </ul>
          </div>
        </div>

        <div
          onClick={() => toggleAnswer("answer3")}
          className={styles.preguntaFaq}
        >
          <h5>
            3. ¿Puedo cancelar o cambiar mi compra?{" "}
            <Icon className={styles.iconoFaq} icon={activeQuestion === "answer1" ? "solar:alt-arrow-up-bold" : "solar:alt-arrow-down-bold"} />
          </h5>
          <div
            className={styles.contenidoPregunta}
            style={{ display: activeQuestion === "answer3" ? "block" : "none" }}
            id="answer3"
          >
            <p>
              Lamentablemente, no se permiten cambios ni devoluciones una vez
              que se ha completado el proceso de compra. Esto está de acuerdo
              con nuestras políticas y las leyes vigentes. Te recomendamos
              revisar cuidadosamente los detalles de tu compra antes de
              confirmarla.
            </p>
          </div>
        </div>

        <div
          onClick={() => toggleAnswer("answer4")}
          className={styles.preguntaFaq}
        >
          <h5>
            4. Si mis entradas no llegan por correo electrónico, ¿qué hago?{" "}
            <Icon className={styles.iconoFaq} icon={activeQuestion === "answer1" ? "solar:alt-arrow-up-bold" : "solar:alt-arrow-down-bold"} />
          </h5>
          <div
            className={styles.contenidoPregunta}
            style={{ display: activeQuestion === "answer4" ? "block" : "none" }}
            id="answer4"
          >
            <p>No hay problema, ¡acá estamos para ayudarte!</p>
            <p>
              Si no has recibido tus entradas por correo electrónico, primero
              verificá tu carpeta de correo no deseado o spam, el correo emisor
              es simplepass@simplesitemas.com. Si no las encontrás, podés
              iniciar sesión en tu cuenta en simplepass y buscar la sección "Mis
              Entradas" o "Historial de Compras". Desde allí, podrás descargar
              tus entradas en formato PNG o podes hacerle una captura de
              pantalla y va a funcionar igualmente.
            </p>
          </div>
        </div>

        <div
          onClick={() => toggleAnswer("answer5")}
          className={styles.preguntaFaq}
        >
          <h5>
            5. ¿Qué debo llevar al evento para ingresar?{" "}
            <Icon className={styles.iconoFaq} icon={activeQuestion === "answer1" ? "solar:alt-arrow-up-bold" : "solar:alt-arrow-down-bold"} />
          </h5>
          <div
            className={styles.contenidoPregunta}
            style={{ display: activeQuestion === "answer5" ? "block" : "none" }}
            id="answer5"
          >
            <p>
              Solo necesitarás tu ticket con el código QR en tu celular para que
              un miembro del staff del evento lo valide. También podés imprimir
              tus tickets, pero no es necesario. Recordá revisar las
              instrucciones específicas del evento.
            </p>
          </div>
        </div>

        <div
          onClick={() => toggleAnswer("answer6")}
          className={styles.preguntaFaq}
        >
          <h5>
            6. ¿Qué sucede si el evento se cancela o cambia de fecha?{" "}
            <Icon className={styles.iconoFaq} icon={activeQuestion === "answer1" ? "solar:alt-arrow-up-bold" : "solar:alt-arrow-down-bold"} />
          </h5>
          <div
            className={styles.contenidoPregunta}
            style={{ display: activeQuestion === "answer6" ? "block" : "none" }}
            id="answer6"
          >
            <p>
              En caso de cancelación o cambio de fecha del evento, te
              informaremos por correo electrónico o a través de tu cuenta
              personal en simplepass. Dependiendo de las políticas del evento,
              podrás optar por recibir un reembolso o mantener tus entradas para
              la nueva fecha. Simplepass está exento de los riesgos de
              cancelación y la responsabilidad de reprogramación o reembolso
              está a cargo del productor del evento.
            </p>
          </div>
        </div>

        <div
          onClick={() => toggleAnswer("answer7")}
          className={styles.preguntaFaq}
        >
          <h5>
            7. ¿Cómo puedo contactar al servicio al cliente?{" "}
            <Icon className={styles.iconoFaq} icon={activeQuestion === "answer1" ? "solar:alt-arrow-up-bold" : "solar:alt-arrow-down-bold"} />
          </h5>
          <div
            className={styles.contenidoPregunta}
            style={{ display: activeQuestion === "answer7" ? "block" : "none" }}
            id="answer7"
          >
            <p>
              Si tienes preguntas o necesitas ayuda, podés ponerte en contacto
              con nuestro servicio al cliente escribiendo un correo electrónico
              a simplesistemasweb@gmail.com o al botón de Whatsapp que aparece
              en la esquina inferior derecha de nuestro sitio web. Al utilizar
              simplepass, contás con nuestra ayuda en todo momento.
            </p>
          </div>
        </div>

        <div
          onClick={() => toggleAnswer("answer8")}
          className={styles.preguntaFaq}
        >
          <h5>
            8. ¿Cuál es la política de reembolso?{" "}
            <Icon className={styles.iconoFaq} icon={activeQuestion === "answer1" ? "solar:alt-arrow-up-bold" : "solar:alt-arrow-down-bold"} />
          </h5>
          <div
            className={styles.contenidoPregunta}
            style={{ display: activeQuestion === "answer8" ? "block" : "none" }}
            id="answer8"
          >
            <p>
              Nuestra política de reembolso varía según el evento y el
              organizador. Si un evento se cancela o cambia de fecha, te
              proporcionaremos información sobre las opciones de reembolso
              disponibles. Te recomendamos revisar los términos y condiciones
              específicos del evento al realizar la compra.
            </p>
          </div>
        </div>

        <div
          onClick={() => toggleAnswer("answer9")}
          className={styles.preguntaFaq}
        >
          <h5>
            9. ¿Cómo puedo imprimir mis entradas?{" "}
            <Icon className={styles.iconoFaq} icon={activeQuestion === "answer1" ? "solar:alt-arrow-up-bold" : "solar:alt-arrow-down-bold"} />
          </h5>
          <div
            className={styles.contenidoPregunta}
            style={{ display: activeQuestion === "answer9" ? "block" : "none" }}
            id="answer9"
          >
            <p>
              Para imprimir tus entradas, inicia sesión en tu cuenta en nuestro
              sitio web y busca la sección "Mis Entradas" o "Historial de
              Compras". Desde allí, podrás descargar e imprimir tus entradas en
              formato PDF. Asegúrate de tener una impresora disponible.
            </p>
          </div>
        </div>

        <div
          onClick={() => toggleAnswer("answer10")}
          className={styles.preguntaFaq}
        >
          <h5>
            10. ¿Qué debo hacer si no recibí mis entradas por correo
            electrónico?{" "}
            <Icon className={styles.iconoFaq} icon={activeQuestion === "answer1" ? "solar:alt-arrow-up-bold" : "solar:alt-arrow-down-bold"} />
          </h5>
          <div
            className={styles.contenidoPregunta}
            style={{
              display: activeQuestion === "answer10" ? "block" : "none",
            }}
            id="answer10"
          >
            <p>
              Si no has recibido tus entradas por correo electrónico, primero
              verifica tu carpeta de correo no deseado o spam. Si aún no las
              encuentras, inicia sesión en tu cuenta en nuestro sitio web y
              busca la sección "Mis Entradas" o "Historial de Compras". Desde
              allí, podrás descargar tus entradas en formato PDF.
            </p>
          </div>
        </div>

        <div
          onClick={() => toggleAnswer("answer11")}
          className={styles.preguntaFaq}
        >
          <h5>
            11. ¿Es seguro comprar tickets en simplepass?{" "}
            <Icon className={styles.iconoFaq} icon={activeQuestion === "answer1" ? "solar:alt-arrow-up-bold" : "solar:alt-arrow-down-bold"} />
          </h5>
          <div
            className={styles.contenidoPregunta}
            style={{
              display: activeQuestion === "answer11" ? "block" : "none",
            }}
            id="answer11"
          >
            <p>
              100%. Tu seguridad es nuestra prioridad, siempre. Por eso,
              utilizamos encriptación de última generación y cumplimos con todos
              los estándares de seguridad para proteger tus datos. Además,
              trabajamos con sistemas de pago confiables para asegurarte una
              experiencia de compra segura y sin preocupaciones. Simplepass no
              utiliza ni almacena los datos de tus tarjetas, y jamás te
              solicitaremos ningún tipo de claves personales.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faq;
