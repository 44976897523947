import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import PhoneInputComponent from '../PhoneInputComponent/PhoneInputComponent';
import { isValidPhoneNumber } from 'react-phone-number-input';
import axios from 'axios';
import Alert from '../Alert/Alert';
import 'sweetalert2/src/sweetalert2.scss';
import variables from '../../Context/Variables';
import styles from './DetallesUsuarioModal.module.scss';
import { useAuth } from '../../Context/AuthProvider';
import { Icon } from '@iconify/react/dist/iconify.js';

const DetallesUsuarioModal = ({ show, handleClose }) => {
  const initialState = {
    nombre: '',
    dni: '',
    celular: '',
    terminos: false,
  };

  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { logout } = useAuth();

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails') || sessionStorage.getItem('userDetails'));
    if (userDetails) {
      setFormData({
        nombre: '',
        dni: '',
        celular: '',
        terminos: false,
      });
    }
  }, []);

  const validateForm = () => {
    const nombreValido = formData.nombre.trim().length >= 4;
    const dniValido = /^\d{7,15}$/.test(formData.dni);
    const celularValido = isValidPhoneNumber(formData.celular);
    const terminosAceptados = formData.terminos;
  
    setErrors({
      nombre: nombreValido ? '' : 'El nombre debe tener al menos 4 caracteres.',
      dni: dniValido ? '' : 'El DNI/Pasaporte debe tener entre 7 y 15 dígitos.',
      celular: celularValido ? '' : 'Debes ingresar un número de teléfono válido.',
      terminos: terminosAceptados ? '' : 'Debes aceptar los términos y condiciones.',
    });
  
    setIsFormValid(nombreValido && dniValido && celularValido && terminosAceptados);
  };

  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleChange = (e) => {
    const { id, value, checked, type } = e.target;
    const newValue = type === "checkbox" ? checked : value;
  
    // Extraer el campo sin el prefijo 'modal_'
    const field = id.replace('modal_', '');
  
    setFormData((prev) => ({ ...prev, [field]: newValue }));
  };  

  const handlePhoneChange = (phone) => {
    setFormData((prev) => ({ ...prev, celular: phone }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) return;

    setIsLoading(true);
    try {
      const token = localStorage.getItem('userDetails')
        ? JSON.parse(localStorage.getItem('userDetails')).jwt
        : JSON.parse(sessionStorage.getItem('userDetails')).jwt;

      await axios.post(
        `${variables.API_BASE_URL}/api/usuarios/actualizarDatos`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const userDetails = JSON.parse(localStorage.getItem('userDetails') || sessionStorage.getItem('userDetails'));
      const updatedUserDetails = {
        ...userDetails,
        necesitaCompletarInformacion: false
      };

      const storage = localStorage.getItem('userDetails') ? localStorage : sessionStorage;
      storage.setItem('userDetails', JSON.stringify(updatedUserDetails));

      await Alert({
        tipo: 'success',
        titulo: 'Actualización exitosa',
        descripcion: 'Tus datos han sido actualizados correctamente.',
        duracion: 3000
      });

      handleClose();
    } catch (error) {
      await Alert({
        tipo: 'error',
        titulo: 'Error',
        descripcion: 'Hubo un problema actualizando tus datos. Por favor, inténtalo de nuevo.',
        duracion: 3000
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} backdrop="static" keyboard={false} className={styles.modalContent}>
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title className={styles.modalTitle}>Información requerida</Modal.Title>
        <p>Para mejorar tu experiencia en simplepass, <b>completa por única vez este formulario con tu información.</b></p>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="nombre">
            <Form.Label className={styles.label}>Nombre completo</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingresa tu nombre completo"
              value={formData.nombre}
              onChange={handleChange}
              isInvalid={!!errors.nombre}
              className={errors.nombre ? styles.inputError : styles.inputValid}
            />
            {errors.nombre && <div className={styles.error}>{errors.nombre}</div>}
          </Form.Group>

          <Form.Group controlId="dni">
            <Form.Label className={styles.label}>DNI/Pasaporte</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingresa tu DNI sin puntos"
              value={formData.dni}
              onChange={handleChange}
              isInvalid={!!errors.dni}
              className={errors.dni ? styles.inputError : styles.inputValid}
            />
            {errors.dni && <div className={styles.error}>{errors.dni}</div>}

          </Form.Group>

          <Form.Group controlId="celular">
            <Form.Label className={styles.label}>Celular</Form.Label>
            <PhoneInputComponent value={formData.celular} onChange={handlePhoneChange} />
            {errors.celular && <div style={{ margin: "0" }} className={styles.error}>{errors.celular}</div>}
          </Form.Group>

          <Form.Group controlId="terminos">
            <Form.Label style={{ marginTop: "23px" }} className={styles.label}>Terminos y condiciones</Form.Label>
            <div className={styles.containerCheckbox}>
              <input
                type="checkbox"
                id="modal_terminos"
                checked={formData.terminos}
                onChange={handleChange}
                className={errors.terminos ? styles.inputError : styles.inputValid}
              />
              <label htmlFor="modal_terminos" className={styles.checkboxLabel}>
                <svg viewBox="0 0 64 64" height="1em" width="1em">
                  <path
                    d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                    className={styles.path}
                  ></path>
                </svg>
                <span>
                  He leído y acepto los{' '}
                  <a style={{ color: "#106bbd", textDecoration: "underline" }} href="/terminosYCondiciones" target="_blank" rel="noopener noreferrer">términos y condiciones</a>
                </span>
              </label>
            </div>
          </Form.Group>

          {errors.terminos && <div className={styles.error}>{errors.terminos}</div>}
        </Form>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!isFormValid || isLoading}
          className={styles.submitButton}
        >
          {isLoading ? 'Actualizando...' : 'Actualizar mis datos'}
        </Button>
        <Button
          variant="link"
          onClick={logout}
          className={styles.continueAsGuestButton}
          style={{ textDecoration: "none", color: "#1b1b1b", fontSize: "14px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
        >
          Cerrar sesión <Icon icon="carbon:chevron-right" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DetallesUsuarioModal;