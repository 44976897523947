import React, { useState, useEffect, useMemo } from 'react';
import { Icon } from '@iconify/react';
import styles from "./3deFebrero.module.scss";

// Función auxiliar para formatear moneda
const formatCurrency = (value) => {
  return new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(value);
};

const Teatro3deFebrero = ({ evento, onSeatsSelected }) => {
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const tiposDeTickets = useMemo(() => {
    return evento.tiposDeTickets.filter(ticket => ticket.fila && ticket.numeroAsiento);
  }, [evento.tiposDeTickets]);

  const palcos = useMemo(() => {
    return evento.tiposDeTickets.filter(ticket => ticket.seccion && ticket.seccion.startsWith('PALCO'));
  }, [evento.tiposDeTickets]);

  // Optimiza el uso del useEffect para que solo se ejecute cuando selectedSeats realmente cambie
  useEffect(() => {
    if (onSeatsSelected) {
      const selectedSeatsDetails = selectedSeats.map((seatId) => {
        const seat = tiposDeTickets.find((ticket) => ticket.id === seatId);
        return {
          id: seat.id,
          nombre: seat.nombre,
          precio: seat.precio,
          seccion: seat.seccion,
          fila: seat.fila,
          numeroAsiento: seat.numeroAsiento
        };
      });
      onSeatsSelected(selectedSeatsDetails);
    }
  }, [selectedSeats, tiposDeTickets, onSeatsSelected]);

  const handleSectionClick = (sectionName) => {
    setSelectedSection(sectionName);
    setModalIsOpen(true);
  };

  const toggleSeatSelection = (ticketId) => {
    setSelectedSeats((prevSelectedSeats) => {
      if (prevSelectedSeats.includes(ticketId)) {
        return prevSelectedSeats.filter(id => id !== ticketId);
      } else {
        return [...prevSelectedSeats, ticketId];
      }
    });
  };

  const removeSeatSelection = (ticketId) => {
    setSelectedSeats((prevSelectedSeats) =>
      prevSelectedSeats.filter((id) => id !== ticketId)
    );
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const groupedByRow = useMemo(() => {
    const grouped = tiposDeTickets.reduce((acc, ticket) => {
      if (!acc[ticket.fila]) {
        acc[ticket.fila] = [];
      }
      acc[ticket.fila].push(ticket);
      return acc;
    }, {});
    Object.keys(grouped).forEach(fila => {
      grouped[fila].sort((a, b) => a.numeroAsiento - b.numeroAsiento);
    });
    return grouped;
  }, [tiposDeTickets]);

  const getSeatColor = (ticket) => {
    if (ticket.estado === 'VENDIDA') return 'red';
    if (ticket.estado === 'RESERVADA') return 'orange';
    if (selectedSeats.includes(ticket.id)) return 'green';
    return '#ccc'; 
  };

  const getPalcoButtonStyle = (palco) => {
    if (palco.estado !== "DISPONIBLE") return { color: '#a1a1a1', cursor: 'not-allowed' };
    return { color: selectedSeats.includes(palco.id) ? '#fff' : '#fff' };
  };

  return (
    <div className={styles.theaterContainer}>
      <div className={styles.descripcion}>
        <p className={styles.precios}>Precios:</p>
        <p className={styles.item}>Palco (incluyen 4 butacas): <span>$20.000</span></p>
        <p className={styles.item}>Platea General: <span>$5.000</span></p>
      </div>
      <div className={styles.contMap}>

        <img
          src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Butacas/PLANO-TEATRO+(1).png"
          alt="Plano del teatro"
          className={styles.theaterImage}
        />

        {/* Botones invisibles para seleccionar secciones */}
        <button
          className={`${styles.invisibleButton} ${styles.genIzq}`}
          style={{ top: '20%', left: '23%' }}
          onClick={() => handleSectionClick('PLATEA GENERAL IZQ')}
        />
        <button
          className={`${styles.invisibleButton} ${styles.genMed}`}
          style={{ top: '21%', left: '35%' }}
          onClick={() => handleSectionClick('PLATEA GENERAL')}
        />
        <button
          className={`${styles.invisibleButton} ${styles.genDer}`}
          style={{ top: '20%', left: '68%' }}
          onClick={() => handleSectionClick('PLATEA GENERAL DER')}
        />
        <button
          className={`${styles.invisibleButton} ${styles.anfIzq}`}
          style={{ top: '16%', left: '2.5%' }}
          onClick={() => handleSectionClick('ANFITEATRO IZQ')}
        />
        <button
          className={`${styles.invisibleButton} ${styles.anfDer}`}
          style={{ top: '16%', left: '89.5%' }}
          onClick={() => handleSectionClick('ANFITEATRO DER')}
        />

        {/* Botones de Palcos */}
        {palcos.map((palco) => (
          <button
            key={palco.id}
            className={`${styles.palcoButton} ${styles[`palco${palco.seccion.split(' ')[1]}`]}`}
            style={getPalcoButtonStyle(palco)}
            onClick={() => {
              if (palco.estado === 'DISPONIBLE') toggleSeatSelection(palco.id);
            }}
            disabled={palco.estado !== 'DISPONIBLE'}
          >
            {selectedSeats.includes(palco.id) ? (
              <Icon width={16} icon="fluent-mdl2:favorite-star-fill" />
            ) : (
              `PALCO\n${palco.seccion.split(' ')[1]}`
            )}
          </button>
        ))}

      </div>

      <div className={styles.selectedSeatsContainer}>
        <h4 className={styles.seleccionados}>Butacas seleccionadas:</h4>
        {selectedSeats.map((seatId) => {
          const seat = [...tiposDeTickets, ...palcos].find(
            (ticket) => ticket.id === seatId
          );
          return (
            <div key={seat.id} className={styles.selectedSeat}>
              {/* Mostrar solo el nombre y el precio */}
              <span>{`${seat.nombre} - ${formatCurrency(seat.precio)}`}</span>
              <button
                className={styles.removeButton}
                onClick={() => removeSeatSelection(seat.id)}
              >
                X
              </button>
            </div>
          );
        })}
      </div>

      {/* Modal personalizado para la selección de asientos */}
      {modalIsOpen && (
        <div className={styles.modalOverlay} onClick={closeModal}>

          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            <button className={styles.closeIcon} onClick={closeModal}>&times;</button>
            <div className={styles.modalHeader}>
              <h2>Escenario</h2>
            </div>
            <div className={styles.seatsContainer}>
              {Object.keys(groupedByRow)
                .map(fila => (
                  <div key={fila} className={styles.rowContainer}>
                    {groupedByRow[fila]
                      .filter(ticket => ticket.seccion === selectedSection)
                      .map(ticket => (
                        <button
                          key={ticket.id}
                          className={styles.seatButton}
                          style={{ backgroundColor: getSeatColor(ticket) }}
                          onClick={() => toggleSeatSelection(ticket.id)}
                          disabled={ticket.estado !== 'DISPONIBLE'}
                        >
                          {`${ticket.fila}-${ticket.numeroAsiento}`}
                        </button>
                      ))}
                  </div>
                ))}
            </div>
          </div>

        </div>
      )}
    </div>
  );
};

export default Teatro3deFebrero;