import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Icon } from "@iconify/react"; // Importa Icon de Iconify
import styles from '../exito/DetallePagoExito.module.scss';

const DetallePagoFallo = () => {
    const [detallesPago, setDetallesPago] = useState({
        pagoId: '',
        estado: '',
        medioPago: '',
        total: ''
    });

    useEffect(() => {
        document.title = "Pago fallido";
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation();

    const translatePaymentMethod = (method) => {
        const methods = {
            'credit_card': 'Tarjeta de Crédito',
            'debit_card': 'Tarjeta de Débito',
            'account_money': 'Dinero en Cuenta'
        };
        return methods[method] || 'Método Desconocido';
    };

    useEffect(() => {
        const getURLParams = () => {
            const searchParams = new URLSearchParams(location.search);
            const params = {};
            for (const [key, value] of searchParams) {
                params[key] = value;
            }
            return params;
        };

        const urlParams = getURLParams();

        let estadoDePago = urlParams["status"] || "";
        if (estadoDePago === "rejected") {
            estadoDePago = "Rechazado";
        } else if (estadoDePago === "cancelled") {
            estadoDePago = "Cancelado";
        }
        const externalReference = urlParams["external_reference"] || "";
        const monto = externalReference.split("|")[1] || "";

        setDetallesPago({
            pagoId: urlParams["payment_id"] || "",
            estado: estadoDePago,
            medioPago: translatePaymentMethod(urlParams["payment_type"]),
            total: "$" + monto
        });
    }, [location]);

    return (
        <main className={styles.exitoBody}>
            <section className={styles.bodyDetalle}>
                <h1>
                    Tu pago no se ha podido procesar 
                    <Icon icon="mdi:close-circle" style={{ color: '#D9534F' }} /> 
                </h1>
                <div className={styles.fondoSub}>
                    <h4 className={styles.aclaracionExito}>
                        Algo salió mal con el procesamiento de tu pago. Por favor, intenta de nuevo o utiliza otro método de pago.
                    </h4>
                </div>
                <div className={styles.tablaDetallePago}>
                    <table>
                        <tbody>
                            <tr>
                                <th colSpan="2">Detalles del pago:</th>
                            </tr>
                            <tr>
                                <td>Pago id:</td>
                                <td className={styles.resultado}>{detallesPago.pagoId}</td>
                            </tr>
                            <tr>
                                <td>Medio de pago:</td>
                                <td className={styles.resultado}>{detallesPago.medioPago}</td>
                            </tr>
                            <tr>
                                <td>Estado del pago:</td>
                                <td className={styles.resultado}>{detallesPago.estado}</td>
                            </tr>
                            <tr className={styles.br}>
                                <td className={styles.br10bl}>Total:</td>
                                <td className={styles.resultado}>{detallesPago.total}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <Link to="/" className={`${styles.botonVolver} ${styles.btnFallo}`}>
                    <Icon icon="mdi:arrow-left" /> 
                    Volver al inicio
                </Link>
            </section>
        </main>
    );
};

export default DetallePagoFallo;
