import React, { useEffect, useRef, useState } from "react";
import SubmitButton from "../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton";
import styles from "./ChangePasswordForm.module.scss";
import variables from "../../Context/Variables";
import { useNavigate } from "react-router-dom";
import Alert from '../../Components/Alert/Alert';

const CambiarContraseña = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [jwt, setJwt] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  //Modificacion de head
  useEffect(() => {
    document.title = "Cambiar contraseña";
  }, []);

  // Verificar JWT al cargar el componente
  useEffect(() => {
   ensureAuthenticated()
  }, []);

  const ensureAuthenticated = () => {
    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!userDetails) {
      navigate("/login");
    } else {
      const details = JSON.parse(userDetails);
      setJwt(details.jwt);
      return details;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmNewPassword) {
      await Alert({
        tipo: 'error',
        titulo: 'Error',
        descripcion: 'Las contraseñas no coinciden.',
        duracion: 3000
      });
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(variables.API_BASE_URL + "/api/usuarios/changeCurrentPassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ currentPassword, newPassword }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const message = await response.text();
      await Alert({
        tipo: 'success',
        titulo: 'Cambio exitoso',
        descripcion: message,
        duracion: 3000
      });
      window.location.href = "/perfil";
    } catch (error) {
      await Alert({
        tipo: 'error',
        titulo: 'Error',
        descripcion: error.message,
        duracion: 3000
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className={styles.bodyCambiarContraseña}>
      <div className={styles.fotoCont}>
        <img src="" />
      </div>
      <div className={styles.registroForm}>
        <h2>Cambiar Contraseña</h2>
        <form
          id="changePasswordForm"
          onSubmit={handleSubmit}
          className={styles.form}
        >
          <div className={styles.formGroup}>
            <label htmlFor="currentPassword">Contraseña Actual</label>
            <input
              type="password"
              id="currentPassword"
              required
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className={styles.input}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="newPassword">Nueva Contraseña</label>
            <input
              type="password"
              id="newPassword"
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className={styles.input}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="confirmNewPassword">
              Confirmar Nueva Contraseña
            </label>
            <input
              type="password"
              id="confirmNewPassword"
              required
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              className={styles.input}
            />
          </div>
          <SubmitButton
            type="submit"
            isDisabled={isLoading}
            color="#252525"
            isLoading={isLoading}
          >
            Cambiar Contraseña
          </SubmitButton>
        </form>
      </div>
    </section>
  );
};

export default CambiarContraseña;
