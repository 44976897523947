import React from 'react';
import styles from './ContactSection.module.scss';
import ButtonEmail from '../../Buttons-loaders-inputs/SendEmail/SendEmail'; 
import SendWhatsApp from '../../Buttons-loaders-inputs/SendWhatsApp/SendWhatsApp';

const ContactSection = () => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.textContainer}>
                    <div className={styles.question}>¿Te gustaría saber más?</div>
                    <div className={styles.subheading}>¡Comunicate con nosotros sin dudarlo!</div>
                </div>
                <div className={styles.buttonContainer}>
                    <ButtonEmail
                        label="Enviar correo"
                        email="simplesistemasweb@gmail.com"
                        subject="Información sobre como crear mi evento"
                        body="Hola, me gustaría recibir más información sobre la asistencia para eventos."
                        variant="primary"
                    />
                    <SendWhatsApp
                        phoneNumber="+5493436114446"
                        message="Hola, estoy interesado en crear mi evento. ¿Podrían darme más información?"
                    />
                </div>
            </div>
        </div>
    );
};

export default ContactSection;
