import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Ticket from "../../Components/Ticket/Ticket";
import ButacaTicket from "../../Components/ButacaTicket/ButacaTicket"; 
import styles from "./MisTickets.module.scss";
import variables from "../../Context/Variables";
import TicketSkeleton from "../../Components/Ticket/Skeleton/TicketSkeleton";
import ErrorPage from "../../Components/ErrorPage/ErrorPage";
import { Icon } from "@iconify/react";

const MisTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [butacas, setButacas] = useState([]); 
  const [cargando, setCargando] = useState(true);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Mis tickets";
    ensureAuthenticated();
  }, []);

  const ensureAuthenticated = () => {
    const userDetails = sessionStorage.getItem("userDetails") || localStorage.getItem("userDetails");
    if (!userDetails) {
      navigate("/login");
    } else {
      buscarTicketsYButacas();
    }
  };

  const buscarTicketsYButacas = async () => {
    const userDetails = sessionStorage.getItem("userDetails") || localStorage.getItem("userDetails");
    const details = JSON.parse(userDetails);
    const jwt = details.jwt;

    const endpointTickets = variables.API_BASE_URL + "/api/tickets/comprados";
    const endpointButacas = variables.API_BASE_URL + "/api/butacas/compradas";

    try {
      const [ticketsResponse, butacasResponse] = await Promise.all([
        axios.get(endpointTickets, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        }),
        axios.get(endpointButacas, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        }),
      ]);
      setTickets(ticketsResponse.data);
      setButacas(butacasResponse.data); 
      setCargando(false);
    } catch (error) {
      console.error("Error fetching tickets or butacas:", error);
      setError("Error al buscar los tickets o butacas.");
      setCargando(false);
    }
  };

  if (cargando)
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <h2 style={{ color: "#ccc", fontSize: "20px", marginTop: "16px" }}>Cargando tickets...</h2>
        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
          <TicketSkeleton />
          <TicketSkeleton />
          <TicketSkeleton />
        </div>
      </div>
    );

  if (error) return <ErrorPage />;

  return (
    <section className={styles.cuerpoTickets}>
      <div className={styles.infoTickets}>
        <h4 className={styles.tituloEntradas}>
          Mis entradas válidas <Icon icon="solar:check-circle-bold"  style={{ color: "#47BD8F" }} />
        </h4>
        <div className={styles.fondoSub}>
          <p>
            Podés <b>descargarlas</b>, <b>hacerle captura</b> o <b>abrir esta misma página.</b>
            <br />
            Recuerda que sea en el formato que sea, deberás <b>presentarlas en el punto de ingreso</b> del evento donde serán escaneadas y verificadas.
          </p>
        </div>
      </div>

      <div className={styles.lineaDivisora}></div>

      <div className={styles.ticketsContainer}>
        {tickets.length > 0 ? (
          tickets.map((ticket) => <Ticket key={ticket.id} ticket={ticket} />)
        ) : (
          <p style={{ width: "80%", textAlign: "center", margin: "40px auto" }}>No tenes tickets comprados.</p>
        )}

        {butacas.length > 0 ? (
          butacas.map((butaca) => <ButacaTicket key={butaca.id} butaca={butaca} />)
        ) : (
          null
        )}
      </div>
    </section>
  );
};

export default MisTickets;