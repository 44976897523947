import React from "react";
import styles from "../../Pages/evento/EventoDetalles/EventoDetalles.module.scss";

const ResumenCarrito = ({ total, cargoPorServicio, formatCurrency }) => {
  return (
    <>
      <section className={`${styles.totalContenedor} ${styles.contenedorTotalComisiones}`}>
        <div>Cargo por servicio:</div>
        <div>
          <b className={styles.totalComision}>{formatCurrency(cargoPorServicio)}</b>
        </div>
      </section>
      <section className={styles.totalContenedor}>
        <div>
          Total <small>(todo incluido):</small>
        </div>
        <div>
          <b className={styles.totalCompra}>{formatCurrency(total)}</b>
        </div>
      </section>
    </>
  );
};

export default ResumenCarrito;
