import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './MisEventos.module.scss';
import EventoCardProductor from '../../Components/EventoCardProductor/EventoCardProductor';
import variables from '../../Context/Variables';

const MisEventos = () => {

  const [eventos, setEventos] = useState([]);
  const [auth, setAuth] = useState(sessionStorage.getItem("auth") || localStorage.getItem("auth"));

  //Modificacion de head
  useEffect(() => {
    document.title = "Mis eventos";
  }, []);

  useEffect(() => {
    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!userDetails) {
      renderizarError("No se encontro ningun usuario.")
      return;
    }
    const details = JSON.parse(userDetails);
    const jwt = details.jwt;
    const rol = details.rol;
    if (rol === "USER") {
      renderizarError("Una cuenta con solo permisos de usuarios no puede tener eventos. Creese una cuenta de productor.")
    } else if (rol === "PRODUCTOR") {
      setAuth(2)
    } else if (rol === "STAFF") {
      setAuth(3)
    }

    const getEventos = async () => {
      try {
        const response = await axios.get(variables.API_BASE_URL + "/api/eventos/misEventos", {
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "application/json",
          },
        });
        setEventos(response.data);
      } catch (error) {
        console.error("Error al obtener la lista de eventos:", error);
      }
    };

    getEventos();
  }, []);

  function renderizarError(contenido) {
    return (
      <p style={{ color: "red" }}>{contenido}</p>
    )
  }
  return (
    <div className={styles.contenedorEventos}>
      {[...eventos].reverse().map((evento) => (
        <EventoCardProductor key={evento.id} evento={evento} auth={auth} />
      ))}
    </div>
  );
};

export default MisEventos;

