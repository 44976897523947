import React, { useState, useRef } from "react";
import SubmitButton from "../Buttons-loaders-inputs/SubmitButton/SubmitButton";
import styles from "./FormularioCBUCVU.module.scss";
import Alert from '../Alert/Alert';
import { useNavigate } from "react-router-dom";
import variables from "../../Context/Variables";

const FormularioCBUCVU = ({ eventoId, jwt }) => {
  const [cbuCVUAlias, setCbuCVUAlias] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    fetch((variables.API_BASE_URL + `/api/eventos/${eventoId}/updateCbuCvuAlias`), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cbuCvuOAliasDeProdcutor: cbuCVUAlias }),
    })
      .then(async (response) => {
        const contentType = response.headers.get("Content-Type");

        if (!response.ok) {
          const errorMessage =
            contentType && contentType.includes("application/json")
              ? (await response.json()).message
              : await response.text();
          throw new Error(errorMessage || "Hubo un problema con la solicitud.");
        }

        // Si la respuesta es exitosa pero no es JSON, se asume éxito y se devuelve un mensaje predeterminado.
        if (!contentType.includes("application/json")) {
          return { message: "Operación exitosa" }; // Mensaje predeterminado para respuestas exitosas que no son JSON
        }

        // Para respuestas exitosas que sí son JSON
        return await response.json();
      })
      .then((data) => {
        Alert({
          tipo: "success",
          titulo: "¡Éxito!",
          descripcion: data.message || "Datos enviados correctamente.",
          duracion: 3000
        }).then(() => {
          navigate(`/panel-productor`);
        });
      })
      .catch((error) => {
        Alert({
          tipo: "error",
          titulo: "Error",
          descripcion: error.message,
          duracion: 4000
        });
      })
      .finally(() => {
        setIsLoading(false);
        setCbuCVUAlias("");
        if (inputRef.current) {
          inputRef.current.focus();
        }
      });
  };

  return (
    <form onSubmit={handleSubmit} className={styles.cbuForm}>
      <input
        type="text"
        ref={inputRef}
        value={cbuCVUAlias}
        onChange={(e) => setCbuCVUAlias(e.target.value)}
        placeholder="Ingresa CBU/CVU o Alias"
        required
        className={styles.tuInput}
      />
      <SubmitButton
        isDisabled={isLoading}
        color="#007bff"
        isLoading={isLoading}
      >
        {isLoading ? "Enviando..." : "Enviar"}
      </SubmitButton>
    </form>
  );
};

export default FormularioCBUCVU;
