import React, { useEffect, useState } from 'react';
import ConectorPlugin from '../../Util/ConectorPluginV3';

const ListaImpresoras = () => {
  const [impresoras, setImpresoras] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const obtenerImpresoras = async () => {
      try {
        const impresorasDisponibles = await ConectorPlugin.obtenerImpresoras();
        setImpresoras(impresorasDisponibles);
      } catch (err) {
        setError('Error al obtener las impresoras.');
        console.error(err);
      }
    };

    obtenerImpresoras();
  }, []);

  return (
    <div>
      <h1>Lista de Impresoras</h1>
      {error ? (
        <p>{error}</p>
      ) : (
        <select>
          {impresoras.map((impresora, index) => (
            <option key={index} value={impresora}>
              {impresora}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default ListaImpresoras;