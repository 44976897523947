import React from 'react'
import styles from './SignUp.module.scss'; 
import SignUpForm from './SignUpWindow';
import { Link } from 'react-router-dom';


const SignUp = () => {
  return (
    <div className={styles.bodyLogin}>
      <Link to="/index"><h1>simple<span className={styles.pass}>pass</span></h1></Link>
      <SignUpForm />
    </div>
  )
}

export default SignUp
