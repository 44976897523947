import React, { useEffect, useState } from "react";
import { useAuth } from "../../Context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import styles from "./Perfil.module.scss";
import { Icon } from "@iconify/react";

const Perfil = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [rol, setRol] = useState("");

  useEffect(() => {
    document.title = "Perfil";
    const userDetailsString = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');

    if (userDetailsString) {
      const userDetails = JSON.parse(userDetailsString);
      setUsername(userDetails.username);
      setRol(userDetails.rol);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <section className={styles.perfilUsuario}>
      <div>
        <h1>¡Bienvenido/a {username.split(" ")[0]}!</h1>
      </div>
      <p>Desde acá podrás ver todos tus datos, modificarlos y también ver todas tus entradas.</p>

      <div>
        <h4>Panel de control</h4>
      </div>

      <div className={`${styles.actionUser} ${(rol === "PRODUCTOR" || rol === "STAFF") ? styles.actionUserCenter : ''}`}>
        {/* Muestra todos los botones si el rol es USER */}
        {rol === "USER" && (
          <>
            <Link className={styles.perfilAccion} to="/mis-tickets">
              <Icon color="#252525" width={40} height={40} icon="solar:ticket-linear" />
              Mis entradas
            </Link>
            <Link className={styles.perfilAccion} to="/mis-consumiciones">
              <Icon width={40} height={40} icon="mingcute:glass-cup-line" />
              Mis consumiciones
            </Link>
            <Link className={styles.perfilAccion} to="/mis-datos">
              <Icon width={40} height={40} icon="solar:user-id-broken" />
              Mis datos
            </Link>
            <Link className={styles.perfilAccion} to="/mis-pagos">
              <Icon width={40} height={40} icon="solar:card-linear" />
              Mis pagos
            </Link>
            <Link className={styles.perfilAccion} to="/cambiar-contraseña">
              <Icon width={40} height={40} icon="solar:key-broken" />
              Cambiar contraseña
            </Link>
            <Link onClick={logout} className={styles.perfilAccion} to="/login">
              <Icon width={40} height={40} icon="solar:logout-linear" />
              Cerrar sesión
            </Link>
          </>
        )}
        {/* Muestra una selección limitada de botones si el rol es PRODUCTOR */}
        {(rol === "PRODUCTOR") && (
          <>
            <Link className={styles.perfilAccion} to="/mis-datos">
              <Icon width={40} height={40} icon="solar:card-linear" />
              Mis datos
            </Link>
            <Link className={styles.perfilAccion} to="/cambiar-contraseña">
              <Icon width={40} height={40} icon="solar:key-broken" />
              Cambiar contraseña
            </Link>
            <Link className={styles.perfilAccion} to="/gestion-rrpps">
              <Icon width={40} height={40} icon="solar:users-group-two-rounded-bold" />
              Gestionar RRPPs
            </Link>
            <Link className={styles.perfilAccion} to="/gestion-staff">
              <Icon width={40} height={40} icon="lets-icons:group-scan" />
              Gestionar STAFF
            </Link>
            <Link onClick={logout} className={styles.perfilAccion} to="/login">
              <Icon width={40} height={40} icon="solar:logout-linear" />
              Cerrar sesión
            </Link>
          </>
        )}
        {(rol === "STAFF") && (
          <>
            <Link className={styles.perfilAccion} to="/mis-datos">
              <Icon width={40} height={40} icon="solar:card-linear" />
              Mis datos
            </Link>
            <Link className={styles.perfilAccion} to="/cambiar-contraseña">
              <Icon width={40} height={40} icon="solar:key-broken" />
              Cambiar contraseña
            </Link>
            <Link onClick={logout} className={styles.perfilAccion} to="/login">
              <Icon width={40} height={40} icon="solar:logout-linear" />
              Cerrar sesión
            </Link>
          </>
        )}
      </div>
    </section>
  );
};

export default Perfil;

