import React, { useEffect } from 'react';
import LoginWindow from './LoginWindow';
import styles from './LoginPage.module.scss'; 
import { Link } from 'react-router-dom';


const LoginPage = () => {
  useEffect(() => {
    document.title = 'Ingresa a tu cuenta';
  }, []);

  return (
    <div className={styles.bodyLogin}>
      <Link to={"/index"}>
      <h1>simple<span className={styles.pass}>pass</span></h1>
      </Link>
      <LoginWindow />
    </div>
  );
};

export default LoginPage;
