import React from 'react';
import styles from './PrincipalSection.module.scss';

const PrincipalSection = () => {
  return (
    <section className={styles.principalContainer}>
      <div className={styles.blurElement1}></div>
      <div className={styles.blurElement2}></div>
      <div className={styles.blurElement3}></div>
      <div className={styles.blurElement4}></div>

      <div className={styles.mainContent}>
        <div className={styles.textContainer}>
          <h2>¿Quiénes somos?</h2>
          <h1>Transformamos la industria de eventos, un código QR a la vez</h1>
          <p>Somos pioneros en la venta online de boletos y consumiciones mediante códigos QR.</p>
          <div className={styles.poweredBy}>
            <span>Powered by </span><strong>HERGON SOFT S.A.S.</strong>
          </div>
        </div>

        <div className={styles.imageContainer}>
        </div>
      </div>
    </section>
  );
}

export default PrincipalSection;
