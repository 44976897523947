import React from "react";
import Select from 'react-select';
import styles from "../../Pages/evento/EventoDetalles/EventoDetalles.module.scss";

const ListaMesas = ({ evento, mesasDisponiblesRef, isModalOpen, setIsModalOpen, descripcionMesa, precioMesaDesde, handleMesaChange, formatCurrency, customStylesSelect }) => {
  if (mesasDisponiblesRef.current.length === 0) return null;

  return (
    <div className={styles.contMesas}>
      <div className={styles.tituloYPrecios}>
        <div className={styles.contDescripcion}>
          <h4>Mesas</h4>
          <p className={styles.descripcionMesas}>{descripcionMesa}</p>
        </div>
        <h5>Desde: <span>{precioMesaDesde}</span></h5>
      </div>
      {evento.urlImgMesas && (
        <div className={styles.contImgMesas}>
          <img
            style={{ maxWidth: "600px", margin: "0 auto", width: "90%", cursor: "pointer" }}
            src={evento.urlImgMesas}
            alt="Mapa de las mesas"
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      )}
      <div className={styles.contenedorMesas}>
        <label htmlFor="mesasSelect">Disponibles:</label>
        <Select
          id="mesasSelect"
          isMulti
          options={mesasDisponiblesRef.current.map(mesa => ({ value: mesa.id, label: `${mesa.nombre} - ${formatCurrency(mesa.precio)}` }))}
          onChange={handleMesaChange}
          classNamePrefix="select"
          placeholder="Selecciona tu mesa"
          styles={customStylesSelect}
        />
      </div>
      {isModalOpen && (
        <div className={styles.modal} onClick={() => setIsModalOpen(false)}>
          <span className={styles.close} onClick={() => setIsModalOpen(false)}>&times;</span>
          <img className={styles.modalContent} src={evento.urlImgMesas} alt="Mapa ampliado de las mesas" />
        </div>
      )}
    </div>
  );
};

export default ListaMesas;
