import React, { memo } from "react";
import styles from "./EventCard.module.scss";
import moment from "moment";
import "moment/locale/es";
import { Link } from "react-router-dom";
import LazyImage from "../LazyImage/LazyImage";

const EventCard = memo(({ evento, expirado }) => {

  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }

    moment.locale("es");
    const date = moment(dateString, "DD/MM/YYYY HH:mm");
    return date.format("D MMM YYYY");
  }

  const EventImage = expirado ? (
    <LazyImage className={styles.cardEventoImg} src={evento.imagenUrl} alt="Imagen del evento" />
  ) : (
    <Link to={`/evento/${evento.id}`} className={styles.cardEventoImgLink}>
      <LazyImage className={styles.cardEventoImg} src={evento.imagenUrl} alt="Imagen del evento" />
    </Link>
  );
  
  

  return (
    <article className={styles.cardEvento}>
      {EventImage}
      <div className={styles.contenidoEvento}>
        <div className={styles.eventDetails}>
          <div className={styles.eventTitle}>
            <h5>{evento.nombre}</h5>
          </div>
          <div className={styles.atributoCard}>
            {formatDate(evento.fechaRealizacion)}
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.atributoUbicacion}>
            <p className={styles.ciudadOLugar}>{`${evento.localidad},`}</p>
            <p className={styles.provinciaOCiudad}>{`${evento.provincia}`}</p>
          </div>
          {expirado ? (
            <button disabled style={{ backgroundColor: "#ccc", color: "#fff" }} className={styles.botonComprar}>
              Finalizado
            </button> 
          ) : (
            <Link to={`/evento/${evento.id}`} className={styles.botonComprar}>
                Comprar
            </Link>
          )}
        </div>
      </div>
    </article>
  );
});

export default EventCard;
