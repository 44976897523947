import React, { useState, useEffect } from "react";
import EventCard from "../EventCard/EventCard";
import axios from "axios";
import styles from "./EventosContenedor.module.scss";
import variables from "../../Context/Variables";
import EventoCardSkeleton from "../EventCard/Skeleton/EventoCardSkeleton";

function EventosContenedor() {
  const [eventos, setEventos] = useState([]);
  const [error, setError] = useState(null);
  const [estaCargando, setEstaCargando] = useState(true);

  // Función para convertir 'DD/MM/YYYY HH:mm' a un objeto Date
  const convertirFecha = (fechaStr) => {
    const [fecha, hora] = fechaStr.split(" ");
    const [dia, mes, año] = fecha.split("/");

    // Crear un objeto Date con formato 'YYYY-MM-DDTHH:mm' (ISO 8601)
    return new Date(`${año}-${mes}-${dia}T${hora}:00`);
  };

  // Función para ordenar eventos por fecha
  const ordenarEventosPorFecha = (eventos) => {
    return eventos.sort((a, b) => {
      const fechaA = convertirFecha(a.fechaRealizacion);
      const fechaB = convertirFecha(b.fechaRealizacion);
      return fechaA - fechaB; // De más pronto a más tarde
    });
  };

  useEffect(() => {
    axios
      .get(variables.API_BASE_URL + "/api/eventos")
      .then((response) => {
        const eventosOrdenados = ordenarEventosPorFecha(response.data);
        setEventos(eventosOrdenados);
        setEstaCargando(false);
      })
      .catch((error) => {
        console.error("Error al obtener los eventos:", error);
        setError(error.toString());
        setEstaCargando(false);
      });
  }, []);

  if (error) {
    return <div>Error al cargar los eventos: {error}</div>;
  }

  if (estaCargando) {
    return (
      <div className={styles.eventos}>
        <EventoCardSkeleton />
        <EventoCardSkeleton />
        <EventoCardSkeleton />
        <EventoCardSkeleton />
      </div>
    );
  }

  return (
    <div className={styles.eventos}>
      {Array.isArray(eventos) &&
        eventos.map((evento) => <EventCard key={evento.id} evento={evento} />)}
    </div>
  );
}

export default EventosContenedor;