import React from "react";
import styles from "./EventosNoEncontrados.module.scss"; 

const EventosNoEncontrados = ({query}) => {
  return (
    <section className={styles.bodyEventoDetalles}>
      <div className={styles.eventoNoDisponible}>
        <img
          src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Imagenes+para+errores/pagina-no-encontrada.png"
          alt="Página no encontrada"
          className={styles.imagenErrorEvento}
        />
        <h1>No se encontró ningún evento para "{query}"</h1>
      </div>
    </section>
  );
};

export default EventosNoEncontrados;
