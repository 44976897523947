import React, { useEffect } from "react";
import styles from "./TerminosYCondiciones.module.scss";

const TerminosYCondiciones = () => {
  // Modificación de head
  useEffect(() => {
    document.title = "Terminos y condiciones - simplepass";
  }, []);

  /*contenido*/

  return (
    <main className={styles.terminosYCondicionesBody}>
      <h2 className={styles.tituloTerminosYCondiciones}>Términos y Condiciones para Usuarios de Simplepass</h2>
      
      <section className={styles.condiciones}>
        <section>
          <h3>1. Consentimiento</h3>
          <p>
            HERGON SOFT S.A.S., CUIT 30-71859773-7, (en adelante, “LA EMPRESA"), a través
            de la plataforma online simplepass.com.ar (en adelante, "SIMPLEPASS"), actúa
            como intermediaria en la venta de entradas y consumiciones en nombre de los
            organizadores (en adelante, "EL ORGANIZADOR") de determinados eventos
            artísticos, culturales y deportivos (en adelante, “EL EVENTO”), con quienes
            mantiene un vínculo de mandato. Al registrarse en SIMPLEPASS, usted adquiere 
            la condición de usuario (en adelante, “EL USUARIO"). Al hacer click en el casillero 
            titulado "Acepto los Términos y Condiciones", usted acepta y suscribe el presente 
            contrato conforme a las cláusulas que se detallan a continuación.
          </p>
        </section>

        <section>
          <h3>2. Uso Personal y Prohibiciones</h3>
          <p>
            2.1.- Uso personal. EL USUARIO se compromete a hacer uso de SIMPLEPASS con
            fines exclusivamente personales y no de otro tipo.
          </p>
          <p>
            2.2.- Prohibiciones. EL USUARIO no podrá usar SIMPLEPASS con fines ilícitos, que
            lesionen derechos o intereses de terceros, o que de cualquier forma puedan
            dañar, inutilizar, sobrecargar, deteriorar o impedir la normal utilización de la
            plataforma.
          </p>
          <p>
            2.3.- Sanciones. La infracción de las condiciones de uso de SIMPLEPASS por parte
            del USUARIO implicará la cancelación de su cuenta y la anulación de las compras
            realizadas por eventos pendientes.
          </p>
        </section>

        <section>
          <h3>3. Servicios Provistos para el Usuario</h3>
          <p>
            3.1.- Información sobre eventos determinados. A través de SIMPLEPASS, LA
            EMPRESA proporciona al USUARIO acceso a la información principal sobre
            eventos específicos organizados por EL ORGANIZADOR. Las características
            particulares de esta información están sujetas a las directrices establecidas
            previamente por EL ORGANIZADOR del EVENTO correspondiente, quien será
            exclusivamente responsable de la exactitud y pertinencia de la información del
            mismo.
          </p>
          <p>
            3.2.- Sistema de compra de entradas. EL USUARIO podrá adquirir entradas
            físicas o electrónicas para EL EVENTO, siempre y cuando cumpla con las
            condiciones estipuladas previamente por EL ORGANIZADOR.
          </p>
          <p>
            3.3.- Sistema de compra de consumiciones. EL USUARIO podrá adquirir
            consumiciones para EL EVENTO, sujeto al cumplimiento de las condiciones
            establecidas previamente por EL ORGANIZADOR.
          </p>
        </section>

        <section>
          <h3>4. Adquisición de Entradas</h3>
          <p>
            4.1.- Compra. EL USUARIO podrá adquirir su entrada para EL EVENTO siempre y
            cuando cumpla con las pautas establecidas previamente por EL ORGANIZADOR,
            quien es el exclusivo responsable por la definición de los tipos de entradas (física
            y/o virtual), los medios de pago, los precios y los cargos de servicio asociados a la
            adquisición de entradas.
          </p>
          <p>
            4.2.- Procedimiento. EL USUARIO deberá ingresar sus datos y será
            exclusivamente responsable de la revisión de todos los antecedentes que
            proporcione en el proceso de compra. Es responsabilidad del USUARIO verificar
            que toda la información ingresada y el detalle de la orden sean correctos.
          </p>
          <p>
            4.3.- Verificación de la transacción. La transacción quedará sujeta a la
            verificación por parte de la empresa emisora de la tarjeta bancaria o del medio de
            pago elegido por EL USUARIO dentro de las opciones ofrecidas. Hasta que LA
            EMPRESA no reciba la autorización de la institución financiera correspondiente o
            procesador de servicios de pago (PSP), la transacción no se considerará completa.
          </p>
          <p>
            4.4 Falta de confirmación. En caso de que LA EMPRESA no reciba la
            confirmación de la transacción por parte de la institución financiera
            correspondiente o procesador de servicios de pago (PSP) la entrada no será
            generada.
          </p>
          <p>
            4.5.- Entrega de entradas. Al adquirir sus entradas a través de SIMPLEPASS, EL
            USUARIO recibirá un código QR en la dirección de correo electrónico
            proporcionada al registrarse en SIMPLEPASS. Este código QR será considerado,
            para todos los efectos, la entrada válida para EL EVENTO.
            En caso de que EL ORGANIZADOR así lo disponga, a través de SIMPLEPASS se
            comunicarán los puntos de venta designados para el retiro de entradas físicas.
          </p>
          <p>
            4.6.- Prohibiciones. Las entradas no podrán ser utilizadas en ningún caso para su
            reventa y/o aplicación comercial o de promoción alguna. La entrada adquirida por
            EL USUARIO es intransferible.
          </p>
        </section>

        <section>
          <h3>5. Devoluciones</h3>
          <p>
            5.1.- Principio general. No se realizarán cambios ni devoluciones una vez finalizado
            el proceso de compra, por lo que EL USUARIO asume la responsabilidad de revisar
            detenidamente los detalles del evento y sus propios compromisos antes de
            completar la compra. Ello sin perjuicio de lo dispuesto en los párrafos siguientes.
          </p>
          <p>
            5.2 Excepciones. Podrá haber devoluciones en casos excepcionales, cuando se
            presenten circunstancias extraordinarias y no imputables al USUARIO que
            justifiquen la devolución.
          </p>
          <p>
            5.3 Responsabilidad. Todas las devoluciones serán responsabilidad del
            ORGANIZADOR, pudiendo LA EMPRESA colaborar en la gestión. EL
            ORGANIZADOR determinará el procedimiento, los plazos y las condiciones
            específicas para llevar a cabo la devolución. LA EMPRESA no es responsable por la
            suspensión ni modificación del EVENTO, ni por la negativa del ORGANIZADOR a
            realizar devoluciones en casos excepcionales.
          </p>
          <p>
            5.4.- Reclamos. Cualquier reclamo podrá ser realizado mediante
            hola@simplesistemas.com.ar para que así LA EMPRESA, como intermediaria,
            colabore con su comunicación al ORGANIZADOR quien es el único responsable.
            EL USUARIO reconoce y acepta que cualquier reclamo deberá ser dirigido al
            ORGANIZADOR.
          </p>
          <p>
            5.5.- Modalidad. Las devoluciones serán efectuadas por el mismo medio por el
            cual EL USUARIO realizó la compra.
          </p>
          <p>
            5.6.- Plazo. La devolución se realizará sin demoras indebidas y en un plazo no
            superior a 30 (treinta) días corridos a partir de la notificación de la cancelación.
          </p>
        </section>

        <section>
          <h3>6. Propiedad Intelectual</h3>
          <p>
            6.1.- Contenidos protegidos. Todo el contenido publicado en SIMPLEPASS
            incluyendo, pero no limitando, textos, gráficos, logotipos, iconos, imágenes, clips
            de audio y video, marcas, compilaciones de datos y software, es propiedad de LA
            EMPRESA o de terceros que hayan autorizado su uso, y está protegido por la
            normativa de propiedad intelectual vigente.
          </p>
          <p>
            6.2.- Prohibiciones. Queda estrictamente prohibida la descarga, modificación,
            reproducción, distribución, transmisión, exhibición, ejecución, publicación,
            licencia, creación de obras derivadas, transferencia o venta de cualquier
            contenido, software, productos o servicios obtenidos a través de SIMPLEPASS,
            salvo que se cuente con el consentimiento previo y por escrito de LA EMPRESA.
          </p>
          <p>
            6.3.- Violaciones. Cualquier uso no autorizado del contenido de SIMPLEPASS
            puede violar las leyes de propiedad intelectual, de privacidad y publicidad, y otras
            normativas y leyes aplicables en la República Argentina. LA EMPRESA se reserva
            el derecho de ejercer todas las acciones legales que correspondan contra quienes
            infrinjan estos derechos.
          </p>
          <p>
            6.4.- Reporte de infracciones. Si EL USUARIO considera que alguno de los
            contenidos de SIMPLEPASS infringe sus derechos de propiedad intelectual, debe
            notificar a LA EMPRESA proporcionando toda la información relevante para que
            pueda evaluarse y, de ser necesario, tomar las medidas pertinentes. Para ello, EL
            USUARIO deberá enviar una notificación escrita a la dirección de correo
            electrónico hola@simplesistemas.com.ar, detallando los derechos presuntamente
            infringidos y el contenido en cuestión.
          </p>
        </section>

        <section>
          <h3>7. Responsabilidades</h3>
          <p>
            7.1.- Responsabilidad de LA EMPRESA. LA EMPRESA será responsable del
            cumplimiento adecuado de los servicios proporcionados a través de SIMPLEPASS,
            de acuerdo con los presentes Términos y Condiciones. LA EMPRESA no se hace
            responsable de la información esencial de los eventos, ni de las condiciones
            comerciales o cambios realizados por EL ORGANIZADOR. LA EMPRESA no es
            responsable por la organización, ejecución y condiciones del EVENTO, así como de
            cualquier situación que pueda surgir durante el mismo. Tampoco es responsable de
            la veracidad y exactitud de los datos proporcionados por EL USUARIO al registrarse y al utilizar SIMPLEPASS.
          </p>
          <p>
            7.2.- Responsabilidad del ORGANIZADOR. EL ORGANIZADOR asume la
            exclusiva responsabilidad de todas las obligaciones, cargas y demás aspectos
            relacionados con la organización, ejecución y condiciones del EVENTO, así como
            de cualquier situación que pueda surgir durante el mismo. Su responsabilidad
            incluye, sin limitar, la información proporcionada para publicidad, la elección de
            las condiciones comerciales, los cambios en la programación, las cancelaciones y
            la calidad del EVENTO.
          </p>
          <p>
            7.3.- Responsabilidad del USUARIO. EL USUARIO es el único responsable de la
            veracidad, exactitud, integridad, vigencia, autenticidad y certeza de la información
            de los datos proporcionados al registrarse y al utilizar SIMPLEPASS. Por ello,
            asume todas las consecuencias derivadas de la falsedad o inexactitud de dicha
            información.
          </p>
        </section>

        <section>
          <h3>8. Política de Privacidad y Tratamiento de Datos Personales del Usuario</h3>
          <p>
            8.1.- Responsabilidad. HERGON SOFT S.A.S. es el responsable del tratamiento de
            los datos personales en cumplimiento con la Ley 25.326 de Protección de Datos
            Personales.
          </p>
          <p>
            8.2.- Finalidades. LA EMPRESA recolecta y almacena los datos proporcionados
            por EL USUARIO al registrarse, a fin de:
            <ol>
              <li>Prestar los servicios provistos en SIMPLEPASS.</li>
              <li>Permitir al usuario acceder a SIMPLEPASS y sus funcionalidades.</li>
              <li>Facturar y cobrar de los servicios contratados.</li>
              <li>Realizar todas las comunicaciones que se cursen con EL USUARIO, incluyendo
              las respectivas a modificaciones de servicios, Términos y Condiciones, soporte
              técnico y atención al cliente.</li>
              <li>Difundir, publicitar, comunicar, comercializar, y promocionar EVENTOS futuros.</li>
              <li>Mejorar el servicio al analizar el comportamiento del USUARIO.</li>
            </ol>
          </p>
          <p>
            8.3.- Datos recolectados. LA EMPRESA podrá recopilar y tratar los datos del
            USUARIO relativos a su nombre completo, número de documento nacional de
            identidad o pasaporte, número de teléfono y dirección de correo electrónico.
            LA EMPRESA no almacena ni trata la información y datos de los medios de pago
            del USUARIO, como pueden ser los números de las tarjetas de crédito, débito o
            cuentas bancarias, ni de cualquier otro medio de pago que EL USUARIO utilice
            para la compra de entradas para EL EVENTO.
          </p>
          <p>
            8.4.- Veracidad de los datos y responsabilidad. EL USUARIO será el único
            responsable por la veracidad, exactitud, integridad, vigencia, autenticidad y
            certeza de la información provista al registrarse y utilizar SIMPLEPASS, debiendo
            notificar a LA EMPRESA de cualquier actualización o modificación que
            corresponda en forma inmediata.
          </p>
          <p>
            8.5.- Datos sensibles. En ningún caso LA EMPRESA solicitará al usuario datos
            sensibles, entendidos como aquellos que impliquen revelar origen racial y étnico,
            opiniones o convicciones religiosas, filosóficas, políticas o morales, información
            referente a la salud o a la vida sexual.
          </p>
          <p>
            8.6.- Base de datos registrada. Las bases de datos de las que LA EMPRESA es
            responsable están debidamente registradas ante el Registro Nacional de
            Protección de Datos Personales, dependiente de la Agencia de Acceso a la
            Información Pública de la Dirección Nacional de Protección de Datos personales.
          </p>
        </section>

        <section>
          <h3>9. Contraseña y su Seguridad</h3>
          <p>
            9.1.- Responsabilidad. EL USUARIO será el único y exclusivo responsable de la
            confidencialidad de sus contraseñas, así como también de todas las operaciones
            que realice con las mismas. Esto abarca toda la actividad de su cuenta en
            SIMPLEPASS y Usuario Google. Se obliga a indemnizar y mantener indemne a LA
            EMPRESA, los demás usuarios y terceros, por todo costo, gasto, pérdida, daño o
            perjuicio que pudieren sufrir como consecuencia, directa o indirecta, de cualquier
            operación realizada durante su registro y/o utilización de SIMPLEPASS.
          </p>
          <p>
            9.2.- Uso sin autorización. En caso de que EL USUARIO tome conocimiento o
            sospeche sobre una vulneración de la confidencialidad de sus contraseñas,
            deberá modificarlas de inmediato. EL USUARIO deberá notificar inmediatamente
            a LA EMPRESA sobre cualquier uso sin autorización, por un tercero, de su cuenta,
            contraseña o cualquier otra violación de seguridad, y, en ese sentido, ofrecer la
            prueba documentada a requerimiento de LA EMPRESA.
            Asimismo, LA EMPRESA no será responsable por cualquier costo, gasto, pérdida,
            daño o perjuicio incurridos por EL USUARIO como consecuencia de la utilización
            de su cuenta por parte de un tercero, haya sido esto con o sin el conocimiento del
            USUARIO.
          </p>
        </section>

        <section>
          <h3>10. Confidencialidad y Seguridad de la Información</h3>
          <p>
            LA EMPRESA utiliza y adopta las medidas técnicas, organizativas, tecnológicas y
            de seguridad razonables para proteger y garantizar la seguridad y
            confidencialidad de los datos personales e información proporcionados por EL
            USUARIO. Estas medidas tienen como objetivo prevenir la adulteración, pérdida o
            tratamiento no autorizado de los datos, así como detectar cualquier desviación,
            intencional o no, de la información.
          </p>
        </section>

        <section>
          <h3>11. Transferencia de Datos y Relevamiento de la Confidencialidad</h3>
          <p>
            11.1.- Principio general. Los datos personales proporcionados por EL USUARIO no
            serán cedidos, vendidos ni transmitidos a terceros sin el consentimiento previo del
            mismo. Ello sin perjuicio de lo dispuesto en los párrafos siguientes.
          </p>
          <p>
            11.2.- Intervención de terceros. En los casos en que LA EMPRESA contrate a
            terceros para prestar servicios en su nombre, podrá transmitir los datos
            proporcionados por EL USUARIO. Estos serán utilizados conforme las
            instrucciones encomendadas a dichos terceros y con las finalidades detalladas en
            los presentes Términos y Condiciones.
            LA EMPRESA no autorizará a estos terceros a revelar o divulgar los datos
            personales del USUARIO, salvo que sea estrictamente necesario para la prestación
            de los servicios de LA EMPRESA o para cumplir con obligaciones legales.
            LA EMPRESA informa al USUARIO que todas las entidades involucradas en el
            tratamiento de datos personales están obligadas a mantener la confidencialidad
            de dicha información. Asimismo, se implementan políticas y procesos destinados
            a garantizar la protección y discreción de los datos.
          </p>
          <p>
            11.3.- Relevamiento de la confidencialidad. EL USUARIO reconoce y acepta que
            LA EMPRESA podrá verse obligada a relevar la confidencialidad en los siguientes
            casos:
            <ol>
              <li>Si así lo demanda la ley aplicable, en cumplimiento con los procedimientos y
              garantías que establece.</li>
              <li>Si lo requiere una autoridad judicial o administrativa competente.</li>
              <li>Para proteger los derechos y la seguridad de LA EMPRESA, EL ORGANIZADOR
              y otros usuarios.
              .</li>
            </ol>
            En dicho relevamiento, LA EMPRESA adoptará todas las medidas necesarias para
            asegurar que la información sea manejada con la máxima confidencialidad y
            protección posible.
          </p>
          <p>
            11.4.- Transferencia internacional. EL USUARIO entiende y consiente que las
            transferencias de datos mencionadas previamente pueden implicar su
            transferencia a jurisdicciones que no cuenten con la misma normativa de
            protección de datos que la vigente en Argentina y/o que no proporcionen niveles
            de protección adecuados según la normativa local. Cuando la EMPRESA transfiera 
            los datos personales del USUARIO a otros países, adoptará las medidas necesarias 
            para proteger los mismos conforme la legislación aplicable y a lo descrito en estos 
            Términos y Condiciones.
          </p>
          <p>
            11.5.- Cambios en la estructura organizacional. EL USUARIO autoriza a la
            EMPRESA y a las entidades vinculadas a ella, a transferir sus datos personales en
            caso que sufran alguna eventualidad societaria (como concurso, quiebra, fusión,
            adquisición, reorganización, venta de activos, disolución o liquidación).
          </p>
        </section>

        <section>
          <h3>12. Modificación, Eliminación o Cancelación de Datos Almacenados</h3>
          <p>
            12.1.- Procedimiento. EL USUARIO podrá acceder, modificar o eliminar los datos
            proporcionados a LA EMPRESA a través de SIMPLEPASS, utilizando la opción "Mis
            Datos" disponible en el panel de control del perfil. También podrá hacerlo
            enviando un correo electrónico a hola@simplesistemas.com.ar, solicitando la
            modificación o eliminación de los datos y adjuntando la documentación
            pertinente.
          </p>
          <p>
            12.2.- Eliminación de cuenta. No obstante el punto anterior, EL USUARIO podrá
            eliminar, en cualquier momento, su cuenta y los datos que se contengan en ella
            desde la plataforma.
          </p>
          <p>
            12.3.- Datos esenciales. Si los datos que EL USUARIO desea eliminar son
            esenciales para la existencia de su cuenta registrada, acepta y reconoce que esto
            podría resultar en la terminación de la prestación del servicio.
          </p>
        </section>

        <section>
          <h3>13. Uso de Cookies y Seguridad</h3>
          <p>
            Las cookies comprenden información sobre el navegador utilizado por EL
            USUARIO, el tipo de dispositivo, sistemas operativos, proveedores de servicios de
            Internet, las secciones de la plataforma visitadas por EL USUARIO, los vínculos
            establecidos y otra información similar. Sin embargo, por sí mismas, las cookies no
            contienen ni permiten obtener información personal del USUARIO.
          </p>
          <p>
            EL USUARIO reconoce y acepta que LA EMPRESA puede utilizar cookies para
            proporcionar un servicio más completo, mejorar su experiencia y permitirle
            acceder a los servicios recordando sus preferencias. La información recopilada
            puede incluir el comportamiento de navegación, dirección IP, logs y otros tipos de
            datos, que serán utilizados, entre otras finalidades, para ofrecer campañas
            publicitarias y promocionales, así como para medir la audiencia de SIMPLEPASS y
            el flujo de tráfico en los distintos sitios visitados por EL USUARIO.
          </p>
        </section>

        <section>
          <h3>14. Comunicaciones. Modificaciones de Términos y Condiciones</h3>
          <p>
            14.1.- Comunicaciones. Todas las comunicaciones al USUARIO, incluidas aquellas
            que informen sobre cambios en los Términos y Condiciones u otras cuestiones
            aplicables a los servicios, se realizarán a través del correo electrónico
            hola@simplesistemas.com.ar.
          </p>
          <p>
            14.2.- Modificaciones. LA EMPRESA se reserva el derecho de modificar los
            presentes Términos y Condiciones en cualquier momento, comprometiéndose a
            dar aviso conforme el párrafo anterior.
          </p>
        </section>

        <section>
          <h3>15. Servicio de Atención al Cliente</h3>
          <p>
            EL USUARIO podrá realizar cualquier consulta respecto a los servicios ofrecidos y
            estos Términos y Condiciones, en cualquier momento, vía correo electrónico a
            hola@simplesistemas.com.ar.
          </p>
        </section>

        <section>
          <h3>16. Jurisdicción, Legislación Aplicable y Acciones Legales</h3>
          <p>
            16.1.- Jurisdicción y legislación aplicable. Los presentes Términos y Condiciones
            están regidos por las leyes vigentes de la República Argentina. Cualquier
            controversia derivada del presente, su existencia, validez, interpretación, alcance o
            cumplimiento, será sometida a la jurisdicción de los Tribunales Ordinarios de la
            Ciudad de Paraná renunciando a cualquier otro fuero que pudiera corresponder.
          </p>
          <p>
            16.2.- Acciones legales. Todo lo anterior se extiende en relación a la expresa
            reserva de acciones legales tanto penales como civiles para los casos de
            infracción.
          </p>
        </section>
      </section>
    </main>
  );
};

export default TerminosYCondiciones;