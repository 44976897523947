import React from 'react';
import "./HamburgerToggle.css";

const HamburgerToggle = ({ isOpen, toggleMenu }) => {
    return (
        <>
            <input type="checkbox" id="checkbox" className="checkbox" checked={isOpen} onChange={toggleMenu} />
            <label htmlFor="checkbox" className="toggle">
                <div className="bars" id="bar1"></div>
                <div className="bars" id="bar2"></div>
                <div className="bars" id="bar3"></div>
            </label>
        </>
    );
};

export default HamburgerToggle;
