import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import variables from "../../Context/Variables";
import skeleton from "./Skeleton/InformeCajaSkeleton.module.scss";
import { Icon } from "@iconify/react/dist/iconify.js";
import InformeCaja from "./InformeCaja/InformeCaja";
import styles from "./InformeCaja/InformeCaja.module.scss";
import NoPermissionPage from "../../Components/NoPermissionPage/NoPermissionPage";

const InformeCajaPage = () => {
    const [loading, setLoading] = useState(true);
    const { eventoId, cajaId } = useParams();
    const navigate = useNavigate();
    const [datosInforme, setDatosInforme] = useState(null);
    const [hasPermission, setHasPermission] = useState(true);
    const hasFetchedData = useRef(false);

    useEffect(() => {
        if (hasFetchedData.current) return;
        hasFetchedData.current = true;

        document.title = "Informe de Ventas de Caja";
        window.scrollTo(0, 0);
        ensureAuthenticated();

        const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
        const userData = JSON.parse(userDetails);
        const token = userData.jwt;

        if (!token) {
            setHasPermission(false)
        } else {
            if (userData.rol !== "PRODUCTOR") {
                setHasPermission(false)
            }
            fetchDatosInforme(token);
        }
    }, [eventoId, cajaId]);

    const fetchDatosInforme = (token) => {
        axios.get(`${variables.API_BASE_URL}/api/cantina/${eventoId}/informeVentas/${cajaId}`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            setDatosInforme(response.data);
            setLoading(false);
        }).catch(error => {
            console.error("Error al obtener los datos del informe:", error);
            setLoading(false);
            if (error.response && error.response.status === 401) {
                setHasPermission(false);
            }
        });
    };

    const ensureAuthenticated = () => {
        const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
        if (!userDetails) {
            navigate("/login");
        } else {
            const details = JSON.parse(userDetails);
            if (details.rol !== "PRODUCTOR") {
                setHasPermission(false);
            }
        }
    };

    if (!hasPermission) {
        return <NoPermissionPage />;
    }

    if (loading) {
        return (
            <div className={skeleton.cuerpo}>
                <div className={skeleton.titulo}>Cargando informe...</div>
                <div className={skeleton.lineaDivisora}></div>
                <div className={skeleton.tabla}>
                    <div className={`${skeleton.th} ${skeleton.brtop}`}></div>
                    <div className={skeleton.td}></div>
                    <div className={skeleton.td}></div>
                    <div className={`${skeleton.td} ${skeleton.brbottom}`}></div>
                </div>
                <div className={skeleton.tabla}>
                    <div className={`${skeleton.th} ${skeleton.brtop}`}></div>
                    <div className={skeleton.td}></div>
                    <div className={skeleton.td}></div>
                    <div className={`${skeleton.td} ${skeleton.brbottom}`}></div>
                </div>
                <div className={skeleton.tabla}>
                    <div className={`${skeleton.th} ${skeleton.brtop}`}></div>
                    <div className={skeleton.td}></div>
                    <div className={skeleton.td}></div>
                    <div className={`${skeleton.td} ${skeleton.brbottom}`}></div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.body}>
            <div className={styles.header}>
                <button className={styles.volver} onClick={() => { window.history.back() }}>
                    <Icon width={30} icon="solar:arrow-left-linear" />
                </button>
            </div>
            {datosInforme && <InformeCaja datos={datosInforme} />}
        </div>
    );
};

export default InformeCajaPage;