import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';
import styles from './Alert.module.scss';

// Import Lottie animations for different alert types
import successAnimation from './animations/success.json';
import errorAnimation from './animations/error.json';
import warningAnimation from './animations/warning.json';

/**
 * AlertComponent - A reusable alert component with animations
 * 
 * @param {Object} props - Component props
 * @param {string} props.tipo - Type of alert ('success', 'error', 'warning')
 * @param {string} props.titulo - Title of the alert
 * @param {string} props.descripcion - Description text for the alert
 * @param {Function} props.onClose - Callback function to be called when alert is closed
 * @param {number} props.duracion - Duration in milliseconds before auto-closing the alert (optional, default 3000ms)
 */
const AlertComponent = ({ 
    tipo, 
    titulo, 
    descripcion, 
    onClose,
    duracion = 3000 
}) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);

        const timer = setTimeout(() => {
            handleClose();
        }, duracion);

        return () => clearTimeout(timer);
    }, [duracion]);

    const handleClose = () => {
        setShow(false);
        if (onClose) onClose();
    };

    // Select the appropriate animation based on alert type
    const getAnimation = () => {
        switch(tipo) {
            case 'success':
                return successAnimation;
            case 'error':
                return errorAnimation;
            case 'warning':
                return warningAnimation;
            default:
                return successAnimation;
        }
    };

    return (
        <div className={styles.overlay}>
            <div className={`${styles.modal} ${show ? styles['modal-enter'] : ''}`}>
                <Player
                    autoplay
                    loop={false}
                    keepLastFrame
                    speed={1}
                    src={getAnimation()}
                    style={{ height: '150px', width: '150px' }}
                />
                <h2 className={styles.tituloAlert}>{titulo}</h2>
                <p className={styles.descripcionAlert}>{descripcion}</p>
                <button className={styles.closeButton} onClick={handleClose}>
                    Cerrar
                </button>
            </div>
        </div>
    );
};

AlertComponent.propTypes = {
    tipo: PropTypes.oneOf(['success', 'error', 'warning']).isRequired,
    titulo: PropTypes.string.isRequired,
    descripcion: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    duracion: PropTypes.number
};

/**
 * Alert - Function to create and display an alert similar to Swal.fire
 * 
 * @param {Object} options - Configuration options for the alert
 * @returns {Promise} A promise that resolves when the alert is closed
 */
const Alert = (options) => {
    const container = document.createElement('div');
    document.body.appendChild(container);

    const root = ReactDOM.createRoot(container);

    return new Promise((resolve) => {
        const handleClose = () => {
            root.unmount();
            document.body.removeChild(container);
            if (options.onClose) options.onClose();
            resolve();
        };

        root.render(
            <AlertComponent
                {...options}
                onClose={handleClose}
            />
        );
    });
};

export default Alert;