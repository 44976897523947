import React from 'react';
import styles from './LoaderBolas.module.scss'; 

const LoaderBolas = () => {

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.ball} ${styles.blue}`}></div>
      <div className={`${styles.ball} ${styles.red}`}></div>
      <div className={`${styles.ball} ${styles.yellow}`}></div>
      <div className={`${styles.ball} ${styles.green}`}></div>
    </div>
  );
};

export default LoaderBolas;
