import React, { useEffect } from "react";
import styles from "./TerminosYCondicionesProductores.module.scss";
import TerminosYCondiciones from "../terminosYCondiciones/TerminosYCondiciones";
const TerminosYCondicionesProductores = () => {
  // Modificación de head
  useEffect(() => {
    document.title = "Terminos y condiciones Productores - simplepass";
  }, []);

  /*contenido*/

  return (
    <main className={styles.terminosYCondicionesBody}>
      
      <section className={styles.condiciones}>
        <section>
        <h2 className={styles.tituloTerminosYCondiciones}>
        TÉRMINOS Y CONDICIONES PARA ORGANIZADORES DE SIMPLEPASS.COM.AR
      </h2>
        <p> 
        HERGON SOFT S.A.S., CUIT 30-71859773-7, (en adelante, “LA EMPRESA"), a
        través de la plataforma online simplepass.com.ar (en adelante,
        "SIMPLEPASS"), actúa como intermediaria en la venta de entradas y
        consumiciones en nombre de los ORGANIZADORES (en adelante, "EL
        ORGANIZADOR") de determinados EVENTOS artísticos, culturales y
        deportivos (en adelante, “EL EVENTO”), con quienes mantiene un vínculo
        de mandato. Al efectivamente crearse EL EVENTO solicitado por su persona
        mediante SIMPLEPASS, usted adquiere la condición de ORGANIZADOR. Al
        hacer click en el casillero titulado "Acepto los Términos y
        Condiciones", usted acepta y suscribe el presente contrato conforme a
        las cláusulas que se detallan a continuación.
      </p>
          <h3>1. DEFINICIONES PREVIAS</h3>
          <p>
            A los efectos de los presentes Términos y Condiciones, los
            siguientes términos tendrán el significado que se les atribuye a
            continuación:
            <ol>
              <li>
                - SIMPLEPASS: plataforma en línea propiedad de LA EMPRESA
                dedicada a la gestión, comercialización y distribución de
                entradas y consumiciones para EVENTOS.
              </li>
              <li>
                - EVENTO: cualquier actividad artística, cultural, deportiva,
                espectáculo, concierto, conferencia, exposición o similar, para
                el cual se emitan y vendan entradas y/o consumiciones a través
                de SIMPLEPASS.
              </li>
              <li>
                - Cargos de Servicio: porcentaje adicional al precio de la
                entrada, destinado a LA EMPRESA por la publicidad del EVENTO,
                intermediación, gestión y procesamiento de ventas de entradas
                y/o consumiciones, en carácter de retribución. Todo ello en
                concordancia con las cláusulas 8 y 9 de los presentes Términos y
                Condiciones.
              </li>
              <li>
                - Usuario: toda persona que, habiéndose registrado como tal,
                acceda, navegue, o utilice SIMPLEPASS conforme los Términos y
                Condiciones (link ) específicos.
              </li>
              <li>
                  - Usuario: toda persona que, habiéndose registrado como tal,
                  acceda, navegue, o utilice SIMPLEPASS conforme los 
                  <a href="TerminosYCondiciones" target="_blank"><u><strong>Términos y Condiciones</strong></u></a> específicos.
              </li>
              <li>
                - Comprador: toda persona que adquiere entradas para EL EVENTO a
                través de SIMPLEPASS.
              </li>
            </ol>
          </p>
        </section>

        <section>
          <h3>2. CONSENTIMIENTO</h3>
          <p>
            <strong>2.1.-Capacidad legal para contratar.</strong>Al registrarse,
            EL ORGANIZADOR otorga su consentimiento libre, previo y expreso.
            Sólo podrán aceptar estos Términos y Condiciones aquellas personas
            que tengan capacidad legal para contratar.
          </p>
        </section>

        <section>
          <h3>3. NATURALEZA JURÍDICA DEL CONTRATO</h3>
          <p>
            <strong>3.1.-Mandato.</strong> Entre LA EMPRESA y EL ORGANIZADOR
            existe una relación de mandato, mediante el cual el ORGANIZADOR
            otorga a LA EMPRESA, a través de SIMPLEPASS, el poder de actuar en
            su nombre y representación para gestionar la venta de entradas y
            consumiciones relacionadas con EL EVENTO. LA EMPRESA, en su calidad
            de mandataria, se compromete a ejecutar el mandato de acuerdo a las
            limitaciones expresas en los presentes Términos y Condiciones
          </p>
          <p>
            <strong>
              3.2.- Independencia de las partes y personal a cargo del
              organizador
            </strong>
          </p>
          <p>
            <strong>3.2.a.-</strong> El presente contrato de mandato no implica
            la existencia de una relación societaria, de asociación, o de
            cualquier tipo de vínculo comercial o laboral entre LA EMPRESA y el
            ORGANIZADOR más allá de las obligaciones derivadas del propio
            contrato.
          </p>
          <p>
            <strong>3.2.b.-</strong>EL ORGANIZADOR es el único y exclusivo
            responsable de la contratación, supervisión, y pago del personal
            necesario para la preparación, desarrollo y desenvolvimiento del
            EVENTO, lo que incluye, sin limitarse a:
            <ol>
              <li>- Personal de seguridad;</li>
              <li>- Personal de asistencia y logística;</li>
              <li>
                - Vendedores de productos o servicios relacionados con el
                evento;
              </li>
              <li>
                - Técnicos de sonido, iluminación, y otros profesionales
                necesarios para la realización del EVENTO.
              </li>
            </ol>
            Cualquier relación laboral, contractual o de cualquier otra índole
            que surja con el personal mencionado será exclusivamente
            responsabilidad de EL ORGANIZADOR, eximiendo a LA EMPRESA de
            cualquier tipo de reclamo, acción o contingencia que pudieran
            derivarse de dichas relaciones.
          </p>
        </section>

        <section>
          <h3>
            <strong>4. OBJETO</strong>
          </h3>
          <p>
            <strong>4.1.- Intermediación.</strong> El objeto del contrato es la
            intermediación en la venta de entradas y/o consumiciones para EL
            EVENTO, delegada por EL ORGANIZADOR a la EMPRESA a través de
            SIMPLEPASS, conforme los presentes Términos y Condiciones.
          </p>
          <p>
            <strong>4.2.- Actos incluidos.</strong> LA EMPRESA estará facultada
            para realizar todas aquellas diligencias que resulten necesarias o
            convenientes para el cumplimiento del objeto, siempre que estén
            directamente relacionadas con su rol de intermediaria, como sería el
            caso, incluyendo pero no limitando, de acciones de publicidad,
            gestión y procesamiento de ventas.
          </p>
          <p>
            <strong>4.3.- Exención de responsabilidad.</strong> Las diligencias
            del punto anterior no implicarán la asunción de ninguna
            responsabilidad adicional por parte de LA EMPRESA más allá de las
            que correspondan estrictamente a su función de intermediación. LA
            EMPRESA actuará exclusivamente como intermediaria, encargándose de
            facilitar la plataforma para la comercialización de entradas y
            -según corresponda- la recaudación de los fondos correspondientes a
            dicha actividad, sin asumir responsabilidad alguna sobre la
            organización, ejecución o cualquier otra actividad relacionada con
            el EVENTO
          </p>
        </section>

        <section>
          <h3>5. VIGENCIA</h3>
          <p>
            <strong>5.1.-</strong> Término. Los presentes Términos y Condiciones
            estarán en vigencia desde su aceptación por parte del ORGANIZADOR y
            se mantendrán efectivos hasta la transferencia de los fondos
            obtenidos por la venta de entradas o hasta la ejecución del EVENTO,
            lo que ocurra primero. Todo ello conforme el plan elegido por EL
            ORGANIZADOR al momento de crear el EVENTO.
          </p>
        </section>

        <section>
          <h3>6. CREACIÓN DE EVENTOS</h3>
          <p>
            <strong>6.1.-</strong> Información requerida. EL ORGANIZADOR al
            momento de solicitar la creación del EVENTO, haciendo uso de
            SIMPLEPASS, deberá indicar bajo su exclusiva y única
            responsabilidad, en carácter de declaración jurada en cuanto a la
            veracidad, los siguientes datos respectivos al EVENTO:
            <ol>
              <li>- Nombre </li>
              <li>- Descripción</li>
              <li>
                - Nombre de la persona, organización o productora creadora{" "}
              </li>
              <li>
                - Restricciones y requisitos para los potenciales asistentes
              </li>
              <li>- Ubicación exacta</li>
              <li>-Duración, estableciendo día/s y horarios </li>
              <li>
                - Tipos de entradas a vender con su nombre, precio expresado en
                moneda de curso legal -pesos argentinos- y número máximo de
                venta
              </li>
              <li>- Imágenes publicitarias</li>
            </ol>
          </p>
          <p>
            <strong>6.2.- Cancelación de la solicitud.</strong> EL ORGANIZADOR
            podrá cancelar EL EVENTO siempre y cuando el mismo aún no haya sido
            publicado en SIMPLEPASS.
          </p>
          <p>
            <strong>6.2.a- Procedimiento.</strong> EL ORGANIZADOR deberá enviar
            una solicitud a la dirección de correo electrónico{" "}
            <link rel="stylesheet" href="hola@simplesistemas.com.ar" />
            indicando su intención de cancelación del EVENTO.
          </p>
        </section>

        <section>
          <h3>7. RESERVA</h3>
          <p>
            <strong>7.1.- Facultad discrecional.</strong> LA EMPRESA se reserva
            el derecho de rechazar o cancelar cualquier solicitud de creación de
            EVENTO, a su exclusivo criterio, ya sea por incumplimiento de estos
            Términos y Condiciones, de la normativa legal, o por cualquier otro
            motivo que LA EMPRESA considere válido.
          </p>
        </section>

        <section>
          <h3>8. PLANES</h3>
          <p>
            <strong>8.1.- Alternativas.</strong> EL ORGANIZADOR, al solicitar
            crear un EVENTO mediante SIMPLEPASS, podrá elegir entre diferentes
            planes, conforme las siguientes modalidades:
          </p>
          <p>
            <strong>8.1.a.- Plan Básico: </strong>
            <ol>
              <li>
                - Permite la venta de entradas y, opcionalmente, consumiciones.
              </li>
              <li>
                - Los fondos recaudados se transfieren al ORGANIZADOR en un 100%
                dentro de los tres (3) días posteriores a la finalización del
                EVENTO.
              </li>
              <li>
                - Se aplica un cargo de servicio del 15% sobre el precio de las
                entradas a los usuarios que las adquieren.
              </li>
              <li>
                Incluye scanners virtuales de alta velocidad para el control de
                acceso al EVENTO.
              </li>
              <li>
                {" "}
                - El ORGANIZADOR podrá acceder a informes de ventas en tiempo
                real y realizar ediciones al EVENTO.
              </li>
              <li>
                - Este plan permite al ORGANIZADOR iniciar la gestión del EVENTO
                de forma inmediata, una vez aprobada su creación.
              </li>
            </ol>
          </p>
          <p>
            <strong>8.1.b.- Plan Directo: </strong>
            <ol>
              <li>
                - Permite la venta de entradas y, opcionalmente, consumiciones
                <li>
                  - Todos los pagos se realizan directamente en la cuenta del
                  ORGANIZADOR.
                </li>
              </li>
              <li>- Los pagos se efectúan de manera instantánea.</li>
              <li>
                - Se aplica un cargo del 15% a los usuarios que adquieren las
                entradas.
              </li>
              <li>
                - Incluye scanners virtuales de alta velocidad para el control
                de acceso al EVENTO.
              </li>
              <li>
                - El ORGANIZADOR podrá acceder a informes de ventas en tiempo
                real y realizar ediciones al EVENTO.
              </li>
              <li>
                - Este plan permite al ORGANIZADOR iniciar la gestión del EVENTO
                de forma inmediata, una vez aprobada su creación.
              </li>
            </ol>
          </p>
          <p>
            <strong>8.1.c.- Plan Personalizado:</strong>
            <ol>
              <li>
                Esta opción permite la personalización del EVENTO en cuanto a la
                venta de entradas, consumiciones y ubicaciones específicas (como
                butacas o mesas), adaptada a las necesidades particulares del
                ORGANIZADOR.
              </li>
              <li>
                - LA EMPRESA trabajará de manera personalizada con el
                ORGANIZADOR ajustando su intermediación en la venta de entradas
                y consumiciones a los requerimientos específicos del EVENTO.
                Para ello, LA EMPRESA se reserva la facultad de realizar
                acuerdos específicos con EL ORGANIZADOR.
              </li>
              <li>
                - Incluye scanners virtuales de alta velocidad para el control
                de acceso al EVENTO.
              </li>
              <li>
                - El ORGANIZADOR podrá acceder a informes de ventas en tiempo
                real y realizar ediciones al EVENTO.
              </li>
              <li>
                Este plan permite al ORGANIZADOR iniciar la gestión del EVENTO
                de forma inmediata, una vez aprobada su creación.
              </li>
            </ol>
          </p>
        </section>

        <section>
          <h3>9. ASISTENCIA ADICIONAL</h3>
          <p>
            <strong>9.1. Solicitud.</strong> Independientemente del plan
            seleccionado por EL ORGANIZADOR para la gestión del evento, éste
            podrá solicitar a LA EMPRESA la prestación de ciertos servicios
            adicionales que no se encuentren incluidos en el plan inicialmente
            contratado. Asimismo, ante la petición formulada por el ORGANIZADOR
            quedará a libre arbitrio y discrecionalidad de LA EMPRESA
            prestarlos. En caso de afirmativa de la EMPRESA, serán provistos
            bajo las siguientes condiciones:
          </p>
          <p>
            <strong>9.2. Entrega física de entradas.</strong> EL ORGANIZADOR
            podrá solicitar a LA EMPRESA la implementación de la funcionalidad
            de envío de entradas y/o consumiciones de forma física, destinada a
            facilitar la distribución, accesos a los asistentes del evento. Este
            adicional estará sujeto al pago de un cargo por servicio, el cual
            será abonado bajo la modalidad de contrafactura. El monto
            correspondiente al servicio será liquidado por EL ORGANIZADOR a LA
            EMPRESA dentro del plazo de 5 días hábiles, una vez finalizado el
            evento
          </p>
          <p>
            <strong>
              9.3. Entrega en comodato de equipos para la operativa del EVENTO.
            </strong>{" "}
            EL ORGANIZADOR podrá solicitar a LA EMPRESA la entrega en comodato
            de equipos y dispositivos necesarios para la correcta operativa del
            evento, tales como computadoras, impresoras de tickets, lectores de
            códigos QR, terminales de puntos de venta, entre otros. Estos
            equipos podrán ser utilizados para la gestión de la boletería del
            evento, la venta de entradas en el lugar, la comercialización de
            consumiciones dentro del evento, y otros fines relacionados con el
            desarrollo del mismo. LA EMPRESA entregará los equipos solicitados a
            título de comodato, sin transferencia de dominio, por lo que el uso
            y custodia de los mismos será responsabilidad exclusiva de EL
            ORGANIZADOR durante el período en que los equipos estén en su poder.
            Al finalizar el evento, EL ORGANIZADOR deberá proceder a la
            devolución de los equipos en el mismo estado en que fueron
            recibidos, asumiendo los costos por cualquier daño, pérdida o
            desperfecto que pudiera haber ocurrido durante su uso. En caso de
            que EL ORGANIZADOR opte por solicitar estos equipos, deberá abonar
            los costos adicionales relacionados con la logística de entrega y
            retiro de los mismos, conforme a los términos pactados en este
            contrato o aquellos que se acuerden en el momento de la solicitud
            del servicio.
          </p>
          <p>
            <strong>9.4. Condiciones</strong>
          </p>
          <p>
            <strong>9.4.a.- Solicitud formal.</strong> EL ORGANIZADOR deberá
            solicitar los servicios opcionales mencionados con una antelación
            mínima de 15 días hábiles antes de la fecha del evento, a fin de que
            LA EMPRESA pueda coordinar la provisión adecuada de los mismos.
          </p>
          <p>
            <strong>9.4.b.- Disponibilidad.</strong> La prestación de los
            servicios opcionales estará sujeta a la disponibilidad de LA
            EMPRESA, quien podrá rechazar la solicitud si no dispone de los
            recursos necesarios para cumplir con la petición, o si las
            condiciones del evento no permiten la implementación adecuada de los
            servicios
          </p>
          <p>
            <strong>9.4.c.- Responsabilidad por el uso de los equipos.</strong>{" "}
            EL ORGANIZADOR será responsable del uso adecuado de los equipos
            entregados en comodato y deberá garantizar su buen funcionamiento.
            Cualquier mal uso, daño o deterioro será responsabilidad exclusiva
            de EL ORGANIZADOR, quien deberá responder por los costos de
            reparación o reposición.
          </p>
        </section>

        <section>
          <h3>10. RETRIBUCIÓN POR LA INTERMEDIACIÓN</h3>
          <p>
            <strong>10.1.- Cargos de Servicio.</strong> La retribución por la
            intermediación de LA EMPRESA se encuentra representada en los
            “cargos de servicio”, los cuales se discriminan del valor de la
            entrada al momento en que el usuario o comprador realiza la
            adquisición. Estos cargos son abonados directamente por el usuario o
            comprador de la entrada. EL ORGANIZADOR no deberá abonar ningún
            monto a LA EMPRESA por el uso de SIMPLEPASS, conforme a lo
            establecido en los presentes Términos y Condiciones.
          </p>
        </section>

        <section>
          <h3>11. PROHIBICIONES</h3>
          <p>
            <strong>11.1.- Utilización del sitio.</strong> EL ORGANIZADOR tiene
            prohibido utilizar SIMPLEPASS con fines ilícitos, que lesionen
            derechos o intereses de terceros, o que pudiere dañar, inutilizar,
            sobrecargar, deteriorar o impedir la normal utilización de la
            plataforma.
          </p>
          <p>
            <strong>11.2.- Sanciones.</strong> La infracción de las condiciones
            de uso de SIMPLEPASS por parte del ORGANIZADOR podrá implicar la
            cancelación de su cuenta, la eliminación del EVENTO en SIMPLEPASS, e
            inicio de acciones legales civiles o penales por parte de LA
            EMPRESA.
          </p>
        </section>

        <section>
          <h3>12. OBLIGACIONES DE LAS PARTES</h3>
          <p>
            <strong>12.1- Obligaciones DEL ORGANIZADOR:</strong>
          </p>
          <p>
            <strong>12.1.a.-</strong> Proporcionar a LA EMPRESA datos e
            información veraz y precisa del EVENTO a realizarse para un correcto
            cumplimiento del objeto de los presentes Términos y Condiciones
          </p>
          <p>
            <strong>12.1.b.-</strong> Proporcionar a LA EMPRESA todos los
            materiales promocionales necesarios para la publicación y publicidad
            del EVENTO, asegurando que los mismos no infrinjan derechos de
            terceros.
          </p>
          <p>
            <strong>12.1.c.-</strong> Garantizar la capacidad del EVENTO en
            concordancia a la disponibilidad de las entradas en venta, evitando
            la sobreventa.
          </p>
          <p>
            <strike>12.1.d.-</strike> Facilitar el acceso al EVENTO a los
            compradores de las entradas.
          </p>
          <p>
            <strong>12.1.e.-</strong> Responder ante los reclamos, devoluciones
            y revocaciones solicitados por los usuarios y/o consumidores. A tal
            efecto, se considerará el correo electrónico registrado en la cuenta
            del ORGANIZADOR como medio válido y fehaciente para recibir dichas
            comunicaciones.
          </p>
          <p>
            <strong>12.1.f.-</strong> Notificar fehacientemente a LA EMPRESA con
            al menos 48 horas de anticipación sobre cualquier modificación en
            las condiciones del EVENTO. EL ORGANIZADOR debe garantizar que se
            comunique de manera efectiva a los compradores sobre los reembolsos
            o cambios, siendo responsable de dicha comunicación.
          </p>
          <p>
            <strong>12.1.g.-</strong> El ORGANIZADOR se obliga a tener un plan
            de contingencias para los casos en que la ejecución del EVENTO se
            vea obstaculizada.
          </p>
          <p>
            <strong>12.1.h.-</strong> Cumplir con toda la normativa aplicable al
            EVENTO -incluyendo pero no limitando- permisos, licencias y
            regulaciones de seguridad.
          </p>
          <p>
            <strong>12.1.i.-</strong>Proporcionar a LA EMPRESA toda la
            información y documentación adicional que ésta requiera
          </p>
          <p>
            <strong>12.2.- Obligaciones de LA EMPRESA:</strong>
          </p>
          <p>
            <strong>12.2.a-</strong> Poner a disposición del ORGANIZADOR
            SIMPLEPASS.
          </p>
          <p>
            <strong>12.2.b.-</strong>Facilitar mediante SIMPLEPASS al
            ORGANIZADOR informes en tiempo real detallados sobre la venta de
            entradas y consumiciones. Los mismos detallarán:
            <ol>
              <li>
                - Total de entradas vendidas-distinguiendo por tipo- y
                consumiciones{" "}
              </li>
              <li>- Montos de liquidación</li>
              <li>- Montos en concepto de cargos de servicio de SIMPLEPASS</li>
              <li>- Montos en concepto de cargos de servicio de SIMPLEPASS</li>
              <li>- Información de pagos de los Usuarios</li>
            </ol>
          </p>
          <p>
            <strong>12.2.c.-</strong> Ofrecer un soporte técnico al ORGANIZADOR
            en caso de tener problemas con el uso de SIMPLEPASS.
          </p>
          <p>
            <strong>12.2.d.-</strong>Comunicar al ORGANIZADOR los reclamos
            efectuados, mediante SIMPLEPASS, por los usuarios o compradores
            respecto al EVENTO.
          </p>
          <p>
            <strong>12.2.e.-</strong>Si corresponde según el plan elegido por EL
            ORGANIZADOR, rendir cuentas al ORGANIZADOR.
          </p>
        </section>

        <section>
          <h3>13. RESPONSABILIDADES</h3>
          <p>
            <strong>13.1.-</strong> Responsabilidad específicas del ORGANIZADOR.
            El ORGANIZADOR será responsable único y exclusivo frente a LA
            EMPRESA, usuarios, compradores y terceros por:
          </p>
          <p>
            <strong>13.1.a.-</strong> El contenido publicitario proporcionado
            para la promoción mediante SIMPLEPASS
          </p>
          <p>
            <strong>13.1.b.-</strong>La ejecución, calidad, medidas de seguridad
            del EVENTO y toda contingencia derivada del mismo.
          </p>
          <p>
            <strong>13.1.c.-</strong> Las consecuencias derivadas del
            incumplimiento de la normativa aplicable al EVENTO, incluyendo pero
            no limitando, permisos, licencias, contratación de seguros y
            regulaciones de seguridad.
          </p>
          <p>
            <strong>13.1.d.-</strong> La falta de notificación fehaciente a LA
            EMPRESA en el plazo de 48 hs hábiles previas al EVENTO acerca de su
            modificación, postergación, suspensión y/o cancelación.
          </p>
          <p>
            <strong>13.1.e.-</strong> La devolución del precio de las entradas,
            así como su comunicación con los USUARIOS y COMPRADORES.
          </p>
          <p>
            <strong>13.2.-</strong>Reprogramación del EVENTO. Si un EVENTO es
            reprogramado por cualquier circunstancia, EL ORGANIZADOR se obliga a
            recibir como válidas las entradas adquiridas previamente por los
            usuarios.
          </p>
          <p>
            <strong>13.3-</strong> Caso fortuito. El caso fortuito o la fuerza
            mayor no exime al ORGANIZADOR de la responsabilidad ante los
            reclamos, devoluciones ni revocaciones de los usuarios o
            compradores.
          </p>
          <p>
            <strong>13.4-</strong> Exención de responsabilidad de LA EMPRESA por
            circunstancias del EVENTO. LA EMPRESA no garantiza ni es responsable
            ante el ORGANIZADOR por la concurrencia ni participación de los
            usuarios y compradores al EVENTO. En ningún caso LA EMPRESA será
            responsable por cambios en las fechas, postergaciones, suspensiones
            (parciales o totales), o cancelaciones del EVENTO.
          </p>
        </section>

        <section>
          <h3>14. POLÍTICAS DE DEVOLUCIÓN</h3>
          <p>
            <strong>
              14.1.-.Cancelación o modificación de condiciones del EVENTO.
            </strong>{" "}
            En caso de cancelación o modificación ulterior a la publicación en
            SIMPLEPASS de las condiciones del EVENTO, EL ORGANIZADOR se obliga a
            realizar la devolución del precio de las entradas de manera directa
            a los compradores. En ningún caso LA EMPRESA será responsable de la
            devolución o reintegro del precio de las entradas.
          </p>
          <p>
            <strong>14.2.- Contenido del reembolso.</strong> En todos los casos,
            la devolución se realizará sin los “cargos por servicio” por la
            utilización de SIMPLEPASS.
          </p>
          <p>
            <strong>14.3.- Devoluciones incorrectas</strong>. El ORGANIZADOR
            asume plena responsabilidad -y se obliga a mantener indemne a LA
            EMPRESA- por cualquier error en el proceso de devolución de entradas
            a los usuarios y compradores. Esto incluye, pero no se limita, a la
            siguientes situaciones:
          </p>
          <p>
            <strong>14.3.1.-</strong> Reembolso a toda persona que no haya
            adquirido la entrada mediante SIMPLEPASS
          </p>
          <p>
            <strong>14.3.2.-</strong> Reembolsos duplicados o múltiple
          </p>
          <p>
            <strong>14.3.3.-</strong> Reembolsos por montos incorrectos.
          </p>
        </section>

        <section>
          <h3>15. INDEMNIDAD Y REPETICIÓN</h3>
          <p>
            <strong>15.1-</strong> EL ORGANIZADOR será el único y exclusivo
            responsable frente a LA EMPRESA, usuarios, compradores y terceros
            por cualquier daño, perjuicio o menoscabo que se derive del EVENTO,
            obligándose a indemnizar a LA EMPRESA por cualquier monto que ésta
            pague en virtud de dicha responsabilidad.
          </p>
          <p>
            <strong>15.2-</strong> LA EMPRESA se reserva el derecho de exigir al
            ORGANIZADOR la repetición de cualquier monto que pague al usuario,
            compradores y terceros en concepto de indemnización por daños y/o
            perjuicios -reclamados por vía judicial o extrajudicial- sufridos
            como consecuencia directa o indirecta del EVENTO incluyendo, pero no
            limitado a, gastos médicos, pérdida de ingresos, daños a bienes y
            cualquier otro perjuicio económicamente evaluable.
          </p>
        </section>

        <section>
          <h3>16. EXCLUSIVIDAD</h3>
          <p>
            El ORGANIZADOR se obliga a contratar exclusivamente con LA EMPRESA
            para la intermediación en la venta de entradas y consumiciones del
            EVENTO, y no con ninguna otra persona física o jurídica para estos
            fines. LA EMPRESA no se compromete a tener exclusividad de servicio
            con EL ORGANIZADOR.
          </p>
        </section>
        <section>
          <h3>17.PROPIEDAD INTELECTUAL</h3>
          <p>
            <strong>17.1.-</strong> Contenidos protegidos. Todo el contenido
            publicado en SIMPLEPASS incluyendo, pero no limitando, textos,
            gráficos, logotipos, iconos, imágenes, clips de audio y video,
            marcas, compilaciones de datos y software, es propiedad de LA
            EMPRESA o de terceros que hayan autorizado su uso, y está protegido
            por la normativa de propiedad intelectual vigente.
          </p>
          <p>
            <strong>17.2.- Prohibiciones.</strong> Queda estrictamente prohibida
            la descarga, modificación, reproducción, distribución, transmisión,
            exhibición, ejecución, publicación, licencia, creación de obras
            derivadas, transferencia o venta de cualquier contenido, software,
            productos o servicios obtenidos a través de SIMPLEPASS, salvo que se
            cuente con el consentimiento previo y por escrito de LA EMPRESA.
          </p>
          <p>
            <strong>17.3.- Violaciones.</strong> Cualquier uso no autorizado del
            contenido de SIMPLEPASS puede violar las leyes de propiedad
            intelectual, de privacidad y publicidad, y otras normativas y leyes
            aplicables en la República Argentina. LA EMPRESA se reserva el
            derecho de ejercer todas las acciones legales que correspondan
            contra quienes infrinjan estos derechos.
          </p>
        </section>
        <h3>
          18. POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES DEL
          ORGANIZADOR
        </h3>
        <p>
          <strong>18.1.- Responsabilidad.</strong> HERGON SOFT S.A.S. es el
          responsable del tratamiento de los datos personales en cumplimiento
          con la Ley 25.326 de Protección de Datos Personales.
        </p>
        <p>
          <strong>18.2.- Finalidades.</strong> LA EMPRESA recolecta y almacena
          los datos proporcionados por EL ORGANIZADOR al registrarse, a fin de:
          <ol>
            <li>- Prestar los servicios provistos en SIMPLEPASS.</li>
            <li>
              - Permitir al ORGANIZADOR acceder a SIMPLEPASS y sus
              funcionalidades.
            </li>
            <li>
              Facturar y realizar las rendiciones de cuentas correspondientes.
            </li>
            <li>
              - Realizar todas las comunicaciones que se cursen con EL
              ORGANIZADOR, incluyendo las respectivas a modificaciones de
              Términos y Condiciones, soporte técnico y atención al cliente.
            </li>
          </ol>
        </p>
        <p>
          <strong>18.3.- Datos recolectados.</strong> LA EMPRESA podrá recopilar
          y tratar los datos del ORGANIZADOR relativos a su nombre completo,
          número de documento nacional de identidad o pasaporte, número de
          teléfono y dirección de correo electrónico.
        </p>
        <p>
          <strong>18.4.- Veracidad de los datos y responsabilidad.</strong> EL
          ORGANIZADOR será el único responsable por la veracidad, exactitud,
          integridad, vigencia, autenticidad y certeza de la información
          provista tanto al registrarse como utilizar SIMPLEPASS, debiendo
          notificar a LA EMPRESA de cualquier actualización o modificación que
          corresponda.
        </p>
        <p>
          <strong>18.5.- Datos sensibles.</strong> En ningún caso LA EMPRESA
          solicitará al ORGANIZADOR datos sensibles, entendidos como aquellos
          que impliquen revelar origen racial y étnico, opiniones o convicciones
          religiosas, filosóficas, políticas o morales, información referente a
          la salud o a la vida sexual.
        </p>
        <p>
          <strong>18.6.- Base de datos registrada.</strong> Las bases de datos
          de las que LA EMPRESA es responsable están debidamente registradas
          ante el Registro Nacional de Proteccion de Datos Personales,
          dependiente de la Agencia de Acceso a la Información Pública de la
          Dirección Nacional de Protección de Datos personales.
        </p>
        <section>
          <h3>19. CONTRASEÑA Y SU SEGURIDAD</h3>
          <p>
            <strong>19.1- Responsabilidad.</strong> EL ORGANIZADOR será el único
            y exclusivo responsable de la confidencialidad de sus contraseñas,
            así como también de todas las operaciones que realice con las
            mismas. Esto abarca toda la actividad de su cuenta en SIMPLEPASS y
            Usuario Google. Se obliga a indemnizar y mantener indemne a LA
            EMPRESA, los demás usuarios y terceros, por todo costo, gasto,
            pérdida, daño o perjuicio que pudieren sufrir como consecuencia,
            directa o indirecta, de cualquier operación realizada durante su
            registro y/o utilización de SIMPLEPASS.
          </p>
          <p>
            <strong>19.2.- Uso sin autorización.</strong> En caso de que EL
            ORGANIZADOR tome conocimiento o sospeche sobre una vulneración de la
            confidencialidad de sus contraseñas, deberá modificarlas de
            inmediato. EL ORGANIZADOR deberá notificar inmediatamente a LA
            EMPRESA sobre cualquier uso sin autorización, por un tercero, de su
            cuenta, contraseña o cualquier otra violacion de seguridad, y, en
            ese sentido, ofrecer la prueba documentada a requerimiento de LA
            EMPRESA. Asimismo, LA EMPRESA no será responsable por cualquier
            costo, gasto, pérdida, daño o perjuicio incurridos por EL
            ORGANIZADOR como consecuencia de la utilización de su cuenta por
            parte de un tercero, haya sido esto con o sin el conocimiento del
            ORGANIZADOR.
          </p>
        </section>
        <section>
          <h3>20. CONFIDENCIALIDAD Y SEGURIDAD DE LA INFORMACIÓN</h3>
          <p>
            LA EMPRESA utiliza y adopta las medidas técnicas, organizativas,
            tecnológicas y de seguridad razonables para proteger y garantizar la
            seguridad y confidencialidad de los datos personales e información
            proporcionados por EL ORGANIZADOR. Estas medidas tienen como
            objetivo prevenir la adulteración, pérdida o tratamiento no
            autorizado de los datos, así como detectar cualquier desviación,
            intencional o no, de la información.
          </p>
        </section>
        <section>
          <h3>
            21. TRANSFERENCIA DE DATOS Y RELEVAMIENTO DE LA CONFIDENCIALIDAD
          </h3>
          <p>
            <strong>21.1.- Principio general.</strong> Los datos personales
            proporcionados por EL ORGANIZADOR no serán cedidos, vendidos ni
            transmitidos a terceros sin el consentimiento previo del mismo. Ello
            sin perjuicio de lo dispuesto en los párrafos siguientes.
          </p>
          <p>
            <strong>21.2.- Intervención de terceros.</strong> En los casos en
            que LA EMPRESA contrate a terceros para prestar servicios en su
            nombre, podrá transmitir los datos proporcionados por EL
            ORGANIZADOR. Estos serán utilizados conforme las instrucciones
            encomendadas a dichos terceros y con las finalidades detalladas en
            los presentes Términos y Condiciones. LA EMPRESA no autorizará a
            estos terceros a revelar o divulgar los datos personales del
            ORGANIZADOR, salvo que sea estrictamente necesario para la
            prestación de los servicios de LA EMPRESA o para cumplir con
            obligaciones legales. LA EMPRESA informa al ORGANIZADOR que todas
            las entidades involucradas en el tratamiento de datos personales
            están obligadas a mantener la confidencialidad de dicha información.
            Asimismo, se implementan políticas y procesos destinados a
            garantizar la protección y discreción de los datos.
          </p>
          <p>
            <strong>21.3.- Relevamiento de la confidencialidad.</strong> EL
            ORGANIZADOR reconoce y acepta que LA EMPRESA podrá verse obligada a
            relevar la confidencialidad en los siguientes casos:
            <ol>
              <li>
                - Si así lo demanda la ley aplicable, en cumplimiento con los
                procedimientos y garantías que establece.
              </li>
              <li>
                - Si lo requiere una autoridad judicial o administrativa
                competente.
              </li>
              <li>
                - Para proteger los derechos y la seguridad de LA EMPRESA, EL
                ORGANIZADOR y usuarios.
              </li>
            </ol>
            En dicho relevamiento, LA EMPRESA adoptará todas las medidas
            necesarias para asegurar que la información sea manejada con la
            máxima confidencialidad y protección posible.
          </p>
          <p>
            <strong>21.4.- Transferencia internacional.</strong> EL ORGANIZADOR
            entiende y consiente que las transferencias de datos mencionadas
            previamente pueden implicar su transferencia a jurisdicciones que no
            cuenten con la misma normativa de protección de datos que la vigente
            en Argentina y/o que no proporcionen niveles de protección adecuados
            según la normativa local. Cuando la EMPRESA transfiera los datos
            personales del ORGANIZADOR a otros países, adoptará las medidas
            necesarias para proteger los mismos conforme la legislación
            aplicable y a lo descrito en estos Términos y Condiciones.
          </p>
          <p>
            <strong>21.5.- Cambios en la estructura organizacional.</strong> EL
            ORGANIZADOR autoriza a la EMPRESA y a las entidades vinculadas a
            ella, a transferir sus datos personales en caso que sufran alguna
            eventualidad societaria (como concurso, quiebra, fusión,
            adquisición, reorganización, venta de activos, disolución o
            liquidación).
          </p>
        </section>
        <section>
          <h3>22. ELIMINACIÓN O CANCELACIÓN DE DATOS ALMACENADOS</h3>
          <p>
            <strong>22.1.- Eliminación de cuenta.</strong> EL ORGANIZADOR podrá
            eliminar, en cualquier momento, su cuenta y los datos que se
            contengan en ella desde la plataforma.
          </p>
          <p>
            <strong>22.2.- Datos esenciales.</strong> Si los datos que EL
            ORGANIZADOR desea eliminar son esenciales para la existencia de su
            cuenta registrada, acepta y reconoce que esto podría resultar en la
            terminación del vínculo contractual.
          </p>
        </section>
        <section>
          <h3>23. USO DE COOKIES Y SEGURIDAD</h3>
          <p>
            Las cookies comprenden información sobre el navegador utilizado por
            EL ORGANIZADOR, el tipo de dispositivo, sistemas operativos,
            proveedores de servicios de Internet, las secciones de la plataforma
            visitadas por EL ORGANIZADOR, los vínculos establecidos y otra
            información similar. Sin embargo, por sí mismas, las cookies no
            contienen ni permiten obtener información personal del ORGANIZADOR.
            EL ORGANIZADOR reconoce y acepta que LA EMPRESA puede utilizar
            cookies para proporcionar un uso de SIMPLEPASS más completo y
            mejorar su experiencia. La información recopilada puede incluir el
            comportamiento de navegación, dirección IP, logs y otros tipos de
            datos, que serán utilizados, entre otras finalidades, para ofrecer
            campañas publicitarias y promocionales, así como para medir la
            audiencia de SIMPLEPASS y el flujo de tráfico en los distintos
            sitios visitados por EL ORGANIZADOR.
          </p>
        </section>
        <section>
          <h3>
            24.INTERCAMBIO DE DOCUMENTACIÓN. COMUNICACIONES. MODIFICACIONES DE
            TÉRMINOS Y CONDICIONES
          </h3>
          <p>
            <strong>24.1.- Idioma.</strong> Toda la documentación intercambiada
            entre LA EMPRESA y EL ORGANIZADOR debe estar en español.
          </p>
          <p>
            <strong>24.2.-</strong> Comunicaciones. Todas las comunicaciones al
            ORGANIZADOR, incluidas aquellas que informen sobre cambios en los
            Términos y Condiciones u otras cuestiones relativas al uso de
            SIMPLEPASS, se realizarán a través del correo electrónico
            <link rel="stylesheet" href="hola@simplesistemas.com.ar." />
          </p>
          <p>
            <strong>24.3 Modificaciones.</strong> LA EMPRESA se reserva el
            derecho de modificar los presentes Términos y Condiciones en
            cualquier momento, comprometiéndose a dar aviso conforme el párrafo
            anterior
          </p>
        </section>
        <section>
          <h3>25. JURISDICCIÓN, LEGISLACIÓN APLICABLE Y ACCIONES LEGALES</h3>
          <p>
            <strong>25.1.- </strong>Jurisdicción y legislación aplicable. Los
            presentes Términos y Condiciones están regidos por las leyes
            vigentes de la República Argentina. Cualquier controversia derivada
            del presente, su existencia, validez, interpretación, alcance o
            cumplimiento, será sometida a la jurisdicción de los Tribunales
            Ordinarios de la Ciudad de Paraná renunciando a cualquier otro fuero
            que pudiera corresponder.
          </p>
          <p>
            <strong>25.2.-</strong> Acciones legales. Todo lo anterior se
            extiende en relación a la expresa reserva de acciones legales tanto
            penales como civiles para los casos de infracción.
          </p>
        </section>
        <section></section>
      </section>
    </main>
  );
};

export default TerminosYCondicionesProductores;
