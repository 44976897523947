import React, { useState, useEffect } from 'react';
import styles from './CarouselInfo.module.scss';
import { Icon } from "@iconify/react/dist/iconify.js";

// Usando la URL de la imagen remota
const img1 = "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/logos-clientes/viale_1.webp";
const img2 = "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/logos-clientes/guiso.webp";
const img3 = "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/logos-clientes/carnavales.webp";
const img4 = "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/logos-clientes/fna.webp";
const img5 = "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/logos-clientes/arizona_1.webp";

const CarouselInfo = () => {
  const [activeIndex, setActiveIndex] = useState(2);
  const [direction, setDirection] = useState("slideInRight");

  const sections = [
    {
      content: (
        <div className={`${styles.container} ${activeIndex === 0 ? styles[direction] : ""}`}>
          <div className={styles.commentsGoogleYInforme}>
            <span className={styles.iconContainer}>
              <Icon icon="devicon:google" />
            </span>
            <p className={styles.comment}>
              No necesitas <b>crear una cuenta o recordar contraseñas,</b> simplemente ingresá con tu cuenta de Google
            </p>
          </div>
        </div>
      ),
    },
    {
      content: (
        <div className={`${styles.container} ${activeIndex === 1 ? styles[direction] : ""}`}>
          <div className={styles.commentsGoogleYInforme}>
            <span className={styles.iconContainer}>
              <Icon color="#99419D" width={30} height={30} icon="solar:chart-2-bold-duotone" />
            </span>
            <p className={styles.comment}>
              Podés seguir la evolución de las ventas en todo momento en tiempo real.
            </p>
          </div>
        </div>
      ),
    },
    {
      content: (
        <div className={`${styles.container} ${activeIndex === 2 ? styles[direction] : ""}`}>
          <div className={styles.comments}>
            <p className={styles.comment}>Utilizan simplepass:</p>
            <div className={styles.imageContainer}>
              <img src={img1} alt="Descripción de imagen 1" className={styles.image} />
              <img src={img2} alt="Descripción de imagen 2" className={styles.image} />
              <img src={img3} alt="Descripción de imagen 3" className={styles.image} />
              <img src={img4} alt="Descripción de imagen 4" className={styles.image} />
              <img src={img5} alt="Descripción de imagen 5" className={styles.image} />
            </div>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setDirection("slideOutLeft");
      setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % sections.length);
        setDirection("slideInRight");
      }, 600);
    }, 6000);

    return () => clearInterval(interval);
  }, [sections.length]);

  return (
    <div className={styles.infoGoogle}>
      {sections[activeIndex].content}
    </div>
  );
};

export default CarouselInfo;
