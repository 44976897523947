import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../Pages/resultados-busqueda/SearchForm.module.scss";
import { Icon } from "@iconify/react/dist/iconify.js";

const SearchForm = ({style }) => {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/resultados-busqueda?q=${encodeURIComponent(query)}`);
  };

  return (
    <div className={styles.searchContainer} style={style}>
      <form onSubmit={handleSubmit} className={styles.searchForm} id="searchForm">
        <input
          type="text"
          id="eventSearch"
          className={styles.eventSearch}
          placeholder="Buscar evento..."
          name="q"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <button className={styles.searchButton} id="searchButton" type="submit">
          <Icon width={16} icon="oi:magnifying-glass" />
        </button>
      </form>
    </div>
  );
};

export default SearchForm;