import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import styles from './GestionStaffEvento.module.scss';
import variables from "../../Context/Variables";
import Swal from "sweetalert2";
import Alert from '../../Components/Alert/Alert';
import { useAuth } from '../../Context/AuthProvider';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import '../../Pages/gestion-staff/Skeleton/staffSkeleton.css';
import { Icon } from "@iconify/react";

const GestionStaffEvento = () => {
    const [staffAsignado, setStaffAsignado] = useState([]);
    const [staffDisponible, setStaffDisponible] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isAdding, setIsAdding] = useState(false);
    const [mostrarPassword, setMostrarPassword] = useState({});
    const navigate = useNavigate();
    const { estaAutenticado, jwt } = useAuth();
    const [noPermission, setNoPermission] = useState(false);
    const { eventoId, nombre } = useParams();

    useEffect(() => {
        document.title = "Gestión de Staff del Evento";
        const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
        if (!estaAutenticado) {
            navigate("/login");
        } else {
            const details = JSON.parse(userDetails);

            if (details.rol !== "PRODUCTOR") {
                setNoPermission(true);
            } else {
                fetchStaffEvento(jwt);
            }
        }
    }, [navigate, estaAutenticado]);

    const fetchStaffEvento = async (jwt) => {
        try {
            const responseAsignados = await axios.get(`${variables.API_BASE_URL}/api/staff/${eventoId}/staff`, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            });

            const responseDisponibles = await axios.get(`${variables.API_BASE_URL}/api/staff/list/noEvento/${eventoId}`, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            });

            setStaffAsignado(responseAsignados.data);
            setStaffDisponible(responseDisponibles.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching staff for event', error);
            setError('Failed to fetch staff for the event.');
            setLoading(false);
        }
    };

    const togglePasswordVisibility = (staffId) => {
        setMostrarPassword(prevState => ({
            ...prevState,
            [staffId]: !prevState[staffId]
        }));
    };

    const assignStaff = async (staffId) => {
        setIsAdding(true);
        try {
            const response = await axios.post(`${variables.API_BASE_URL}/api/staff/${eventoId}/assignStaff/${staffId}`, {}, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            });

            if (response.status === 200) {
                fetchStaffEvento(jwt);
                setError(null);
            } else {
                setError(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error('Error assigning staff to event', error);
            setError(`Error: ${error.message}`);
        } finally {
            setIsAdding(false);
        }
    };

    const unassignStaff = async (staffId) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "Esto desasignará al staff del evento.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, desasignar!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${variables.API_BASE_URL}/api/staff/${eventoId}/unassignStaff/${staffId}`, {
                    headers: {
                        Authorization: `Bearer ${jwt}`
                    }
                }).then(async response => {
                    await Alert({
                        tipo: 'success',
                        titulo: 'Desasignado!',
                        descripcion: 'El staff ha sido desasignado del evento exitosamente.'
                    });
                    fetchStaffEvento(jwt);
                }).catch(async error => {
                    await Alert({
                        tipo: 'error',
                        titulo: 'Error!',
                        descripcion: 'No se pudo desasignar el staff. Asegúrese de que tiene permiso para realizar esta acción.'
                    });
                });
            }
        });
    };

    if (noPermission) {
        return <NoPermissionPage />;
    }

    return (
        <div className={styles.bodyGR}>
            <h1 className={styles.titulo}>Gestión de Staff del evento<Icon icon="solar:users-group-two-rounded-bold" width={25}/>
            </h1>
            <p className={styles.descrip}>Desde acá podés asignar, desasignar y visualizar todos los Staff asociados al evento.</p>
            <div className={styles.listaStaff}>
                <h3 className={styles.subtitulo} style={{ width: "100%" }}>Staff Asignado</h3>
                {loading ? (
                    <div className={styles.contSkeletons}>
                        {[...Array(3)].map((_, index) => (
                            <div key={index} className="staffItem"></div>
                        ))}
                    </div>
                ) : (
                    staffAsignado.length > 0 ? staffAsignado.map(staffMember => (
                        <div key={staffMember.id} className={styles.staffItem}>
                            <div className={styles.header}>
                                <div className={styles.contIcono}>
                                <Icon icon="solar:user-bold"width={16} />
                                </div>
                                <div className={styles.caracteristicaId}>
                                    <span>ID</span>
                                    <h3>{staffMember.id}</h3>
                                </div>
                            </div>
                            <div className={styles.caracteristica}>
                                <span>Email</span>
                                <h3>{staffMember.email}</h3>
                            </div>
                            <div className={styles.caracteristica}>
                                <span>Contraseña</span>
                                <div className={styles.passwordContainer}>
                                    <input
                                        type={mostrarPassword[staffMember.id] ? "text" : "password"}
                                        value={staffMember.contrasena}
                                        readOnly
                                        className={styles.passwordInput}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => togglePasswordVisibility(staffMember.id)}
                                        className={styles.togglePassword}
                                        aria-label={
                                            mostrarPassword[staffMember.id] ? "Ocultar contraseña" : "Mostrar contraseña"
                                        }
                                    >
                                        {mostrarPassword[staffMember.id] ? (
                                            <Icon icon="mdi:eye-off" width={16}/>
                                        ) : (
                                            <Icon icon="mdi:eye" width={16}/>
                                        )}
                                    </button>
                                </div>
                            </div>
                            <button className={styles.eliminar} style={{ color: "#FF5C5D" }} onClick={() => unassignStaff(staffMember.id)}>Desasignar <Icon icon="ri:subtract-fill" /></button>
                        </div>
                    )) : <p style={{ color: "red", margin: "40px auto", textAlign: "center" }}>Aún no tienes Staff asignado.</p>
                )}
            </div>
            <h3 className={styles.subtitulo}>Staff Disponible</h3>
            <div className={styles.listaStaff}>
                {staffDisponible.length > 0 ? staffDisponible.map(staffMember => (
                    <div key={staffMember.id} className={styles.staffItem}>
                        <div className={styles.header}>
                            <div className={styles.contIcono}>
                            <Icon icon="solar:user-bold"width={16} />
                            </div>
                            <div className={styles.caracteristicaId}>
                                <span>ID</span>
                                <h3>{staffMember.id}</h3>
                            </div>
                        </div>
                        <div className={styles.caracteristica}>
                            <span>Email</span>
                            <h3>{staffMember.email}</h3>
                        </div>
                        <button className={styles.agregar} onClick={() => assignStaff(staffMember.id)} disabled={isAdding}>
                            {isAdding ? <Icon icon="line-md:loading-loop" width={16}/> : <>Asignar <Icon icon="mingcute:add-fill"width={16} /></>}
                        </button>
                    </div>
                )) : <p style={{ color: "red", margin: "40px auto", textAlign: "center" }}>No hay Staff disponible para asignar.</p>}
            </div>
            {error && <p className="error">{error}</p>}
        </div>
    );
};

export default GestionStaffEvento;