import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './TimerAlert.module.scss';

const TimerAlert = ({ isActive, onTimeUp }) => {
    const [timeLeft, setTimeLeft] = useState(600); // 10 minutos en segundos
    const timerRef = useRef(null);

    useEffect(() => {
        if (isActive) {
            timerRef.current = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime <= 1) {
                        clearInterval(timerRef.current);
                        onTimeUp();
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        } else {
            clearInterval(timerRef.current);
            setTimeLeft(600);
        }

        return () => clearInterval(timerRef.current);
    }, [isActive, onTimeUp]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs.toString().padStart(2, '0')}`;
    };

    if (!isActive) return null;

    return ReactDOM.createPortal(
        <div className={styles.timerAlert}>
            Tenés 10 minutos para completar tu compra: <b>{formatTime(timeLeft)}</b>
        </div>,
        document.body
    );
};

TimerAlert.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onTimeUp: PropTypes.func.isRequired,
};

export default TimerAlert;
