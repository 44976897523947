import React from 'react';
import estilos from './PasosCrearEvento.module.scss';

const PasosCrearEvento = () => {
    return (
        <div className={estilos.contenedorPasos}>
            <h2 className={estilos.titulo}>¿Cómo crear un evento?</h2>
            <p className={estilos.subtitulo}>Con nuestra plataforma, crear nuevos eventos es super simple y sencillo de realizar</p>
            <div className={estilos.pasos}>
                <div className={estilos.pasoItem}>
                    <div className={estilos.contNumero}>
                        <div className={estilos.numero}>1</div>
                    </div>

                    <div className={estilos.conTexto}>
                        <h3>Crea una cuenta de productor</h3>
                        <p>Para crearte una cuenta de productor podés hacerlo desde <b><a style={{textDecoration:"underline"}} href="/signup-productor">acá</a></b> o al crear el mismo evento.</p>
                    </div>
                </div>
                <div className={estilos.pasoItem}>
                    <div className={estilos.contNumero}>
                        <div className={estilos.numero}>2</div>
                    </div>

                    <div className={estilos.conTexto}>
                        <h3>Configura tu evento y los precios</h3>
                        <p>Elegí entre el plan básico o directo y completa los datos de tu evento.</p>
                    </div>

                </div>
                <div className={estilos.pasoItem}>
                    <div className={estilos.contNumero}>
                        <div className={estilos.numero}>3</div>
                    </div>
                    <div className={estilos.conTexto}>
                        <h3>Configura donde cobrar y ¡listo!</h3>
                        <p>Si elegiste directo debes vincular tu cuenta de Mercado Pago con un solo click y sino poner tu cbu/alias.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasosCrearEvento;