import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styles from './EventoPagos.module.scss';
import variables from '../../Context/Variables';
import { useAuth } from '../../Context/AuthProvider';
import { Icon } from '@iconify/react/dist/iconify.js';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import PagoSkeleton from './Skeleton/PagoSkeleton';

const EventoPagos = () => {
    const [pagos, setPagos] = useState([]);
    const [primerLoading, setPrimerLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [error, setError] = useState('');
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const { estaAutenticado, jwt } = useAuth();
    const { eventoId, nombre } = useParams();
    const abortControllerRef = useRef(null);

    const cancelPreviousRequest = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(value);
    };

    const fetchInitialPagos = async (jwt) => {

        if (primerLoading) {
            return;
        }

        try {
            setPrimerLoading(true);
            const response = await axios.get(`${variables.API_BASE_URL}/api/pagos/evento/${eventoId}`, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                },
                params: {
                    page: 0,
                    size: 15
                }
            });

            if (response.data.length === 0) {
                setHasMore(false);
            } else {
                setPagos(response.data);
                setPage(1); 
            }

            setTimeout(() => {
                setPrimerLoading(false);
            }, 4000);

            setPrimerLoading(false);
            console.log("se ejecuto false");
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Previous request cancelled');
            } else {
                console.error('Error fetching initial pagos for event', error);
                setError('Failed to fetch pagos for the event.');
            }
            setPrimerLoading(false);
            console.log("se ejecuto false");
        }
    };

    const fetchMorePagos = async (jwt) => {
        cancelPreviousRequest();

        try {
            setLoadingMore(true);
            const response = await axios.get(`${variables.API_BASE_URL}/api/pagos/evento/${eventoId}`, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                },
                signal: abortControllerRef.current.signal,
                params: {
                    page: page,
                    size: 15
                }
            });

            if (response.data.length === 0) {
                setHasMore(false);
            } else {
                setPagos(prevPagos => [...prevPagos, ...response.data]);
                setPage(prevPage => prevPage + 1);
            }

            setLoadingMore(false);
            console.log("se ejecuto false");
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Previous request cancelled');
            } else {
                console.error('Error fetching more pagos for event', error);
                setError('Failed to fetch pagos for the event.');
            }
            setLoadingMore(false);
            console.log("se ejecuto false");
        }
    };

    useEffect(() => {
        document.title = "Pagos del evento";
        if (!estaAutenticado) {
            return;
        }
        window.scroll(0, 0);

        fetchInitialPagos(jwt);

        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [estaAutenticado, jwt]);

    const loadMorePagos = () => {
        fetchMorePagos(jwt);
    };

    const downloadReport = async () => {
        setDownloading(true);
        try {
            const response = await axios.get(`${variables.API_BASE_URL}/api/pagos/evento/${eventoId}/completo`, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            });

            const pagosData = response.data.map(pago => ({
                'ID': pago.id,
                'Nombre Completo': pago.username,
                'Email': pago.email,
                'DNI': pago.dni,
                'Collection ID': pago.collectionId,
                'Estado del Pago': pago.estadoDelPago,
                'Subtotal': pago.subtotal,
                'Cargo por servicio': pago.cargoServicio,
                'Total': pago.total,
                'Medio de Pago': pago.medioDePago,
                'Fecha y Hora de Realización': pago.fechaYHoraRealizacion,
                'Pasarela': pago.pasarela
            }));

            const worksheet = XLSX.utils.json_to_sheet(pagosData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Pagos");

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(data, 'Informe_Pagos_Evento.xlsx');
        } catch (error) {
            console.error('Error downloading report', error);
            setError('Failed to download the report.');
        } finally {
            setDownloading(false);
        }
    };

    if (!estaAutenticado) {
        return <NoPermissionPage />;
    }

    return (
        <div className={styles.bodyGR}>
            <div className={styles.header}>
                <h2 className={styles.titulo}>Pagos del evento: <span>{nombre}</span></h2>
                <button className={styles.downloadButton} onClick={downloadReport} disabled={downloading}>
                    {downloading ? "Descargando..." : "Descargar informe completo"}
                    {!downloading && <Icon width={25} icon="mdi:microsoft-excel" />}
                </button>
                <div className={styles.contIcon}>
                    <p>Para poder ver toda la informacion desliza</p><Icon width={30} icon="hugeicons:drag-03" />
                </div>
            </div>
            <div className={styles.pagosTabla}>
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Email</th>
                            <th>Username</th>
                            <th>DNI</th>
                            <th>Subtotal</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {primerLoading ? (
                            <PagoSkeleton />  
                        ) : (
                            pagos.map((pago) => (
                                <tr key={pago.id}>
                                    <td>{pago.id}</td>
                                    <td>{pago.email}</td>
                                    <td>{pago.username}</td>
                                    <td>{pago.dni}</td>
                                    <td>{formatCurrency(pago.subtotal)}</td>
                                    <td>{formatCurrency(pago.total)}</td>
                                </tr>
                            ))
                        )}
                        {loadingMore && <PagoSkeleton />}  
                    </tbody>
                </table>
            </div>
            {hasMore && !loadingMore && (
                <button onClick={loadMorePagos} className={styles.loadMoreBtn}>
                    Cargar más
                </button>
            )}
            {error && <p className="error">{error}</p>}
        </div>
    );
};

export default EventoPagos;