import React, { useState } from 'react';
import './SeccionesForm.module.scss';

const SeccionesForm = ({ onSubmit }) => {
  const [sections, setSections] = useState([]);

  // Función para añadir una nueva sección
  const addSection = () => {
    setSections([...sections, { name: '', rows: [{ seats: 0 }] }]);
  };

  // Función para actualizar el nombre de la sección
  const updateSectionName = (index, value) => {
    const updatedSections = [...sections];
    updatedSections[index].name = value;
    setSections(updatedSections);
  };

  // Función para añadir una fila a una sección
  const addRow = (sectionIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].rows.push({ seats: 0 });
    setSections(updatedSections);
  };

  // Función para actualizar la cantidad de asientos en una fila
  const updateSeatCount = (sectionIndex, rowIndex, value) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].rows[rowIndex].seats = parseInt(value, 10);
    setSections(updatedSections);
  };

  // Función para manejar el envío del formulario
  const handleSubmit = () => {
    onSubmit(sections);
  };

  return (
    <div className="section-form">
      <h2>Agrega las secciones</h2>
      {sections.map((section, sectionIndex) => (
        <div key={sectionIndex} className="section">
          <h3>Sección {sectionIndex + 1}</h3>
          <input
            type="text"
            placeholder="Nombre de la sección"
            value={section.name}
            onChange={(e) => updateSectionName(sectionIndex, e.target.value)}
          />
          <h4>Filas</h4>
          {section.rows.map((row, rowIndex) => (
            <div key={rowIndex} className="row">
              <label>Fila {rowIndex + 1} cantidad de asientos:</label>
              <input
                type="number"
                value={row.seats}
                onChange={(e) => updateSeatCount(sectionIndex, rowIndex, e.target.value)}
              />
            </div>
          ))}
          <button type="button" onClick={() => addRow(sectionIndex)}>Añadir Fila</button>
        </div>
      ))}
      <button type="button" onClick={addSection}>Agregar Sección</button>
      <button type="button" onClick={handleSubmit}>Guardar</button>
    </div>
  );
};

export default SeccionesForm;