import React, { useEffect, useState } from "react";
import PagoCard from "../../Components/PagoCard/PagoCard"; 
import styles from './MisPagosPage.module.scss';
import variables from "../../Context/Variables";
import { useNavigate } from "react-router-dom";
import PagoCardSkeleton from "../../Components/PagoCard/Skeleton/PagoCardSkeleton";
import { Icon } from "@iconify/react";
const MisPagosPage = () => {
    const [pagos, setPagos] = useState([]); // Cambiado de ticketsIndividuales a pagos
    const navigate = useNavigate();
    const [cargando, setCargando] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        document.title = "Mis pagos";
        asegurarQueEsteAutenticadoYBuscarPagos();
    }, []);

    const asegurarQueEsteAutenticadoYBuscarPagos = () => {
        const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
        if (!userDetails) {
            navigate("/login");
        } else {
            const details = JSON.parse(userDetails);
            if (!details || !details.jwt) {
                navigate("/login");
            } else {
                const jwt = details.jwt;
                fetchPagos(jwt);
            }
        }
    };

    const fetchPagos = async (jwt) => {

        try {
            const response = await fetch(variables.API_BASE_URL + "/api/pagos/mios", { 
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`,
                },
            });
            const data = await response.json();
            if (Array.isArray(data)) {
                setPagos(data);
                setCargando(false);
            } else {
                console.log("No se encontraron pagos");
                setCargando(false);
            }
        } catch (error) {
            console.error("Error fetching pagos:", error);
            setError("Error al buscar los pagos: " + error);
            setCargando(false);
        }
    };

    if (cargando) return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <h2 style={{ color: "#ccc", fontSize: "20px", marginTop: "30px", width: "70%", textAlign: "center" }}>Cargando tus pagos...</h2>
            <PagoCardSkeleton/>
            <PagoCardSkeleton/>
            <PagoCardSkeleton/>
        </div>
    );
    if (error) return <div>{error}</div>;

    return (
        <main className={styles.cuerpoTickets}>
            {/* Contenido informativo aquí */}
            <div className={styles.contDescripcion}>
                <h2 className={styles.tituloMisConsumiciones}>Mis pagos<Icon icon="solar:card-linear"width={16} />
                </h2>
                <div className={styles.fondoSub}>
                    <p>Acá podes encontrar todos los pagos que has realizado con sus respectiva información.</p>
                </div>
            </div>
            <div className={styles.lineaDivisora}></div>
            <section className={styles.seccionConsumiciones}>
                {pagos.length > 0 ? (
                    pagos.map((pago) => (
                        <PagoCard key={pago.id} pago={pago} /> // Usando PagoCard en lugar de TicketConsumicion
                    ))
                ) : (
                    <p style={{width:"80%", textAlign:"center", margin:"40px auto"}}> No tenes pagos realizados aún.</p>
                )}
            </section>
        </main>
    );
};

export default MisPagosPage;
