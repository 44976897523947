import React, { useState, useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";
import "../Ventas ult. 3 meses/GraficoSkeleton.scss";
import variables from "../../../Context/Variables";
import styles from "../Ventas por semana/VentasPorSemanaGrafico.module.scss";
import "./Skeleton/GraficoDeBarraSkeleton.scss";

// Define los colores de la empresa como un arreglo para utilizar en el gráfico
const coloresEmpresa = ["rgb(154, 66, 158)", "#862f8b", "#c970ce"];

const GraficoComparativoBarras = ({ jwt }) => {

  const [data, setData] = useState([]);
  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    const fetchInformePagos = async () => {
      setCargando(true);
      try {
        const response = await fetch(
          variables.API_BASE_URL + "/api/eventos/informePagos",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${jwt}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok)
          throw new Error("Error al cargar el informe de pagos");

        const jsonData = await response.json();
        console.log(jsonData);
        const sortedData = jsonData
          .map((item) => ({ ...item, fechaObj: new Date(item.fecha) }))
          .sort((a, b) => a.fechaObj - b.fechaObj);

        const transformedData = jsonData.map((item) => ({
          ...item,
          fecha: item.fecha.substring(0, 5), 
          "Suma Subtotales": item.sumaSubtotales,
        }));

        setData(transformedData);
        setCargando(false);
      } catch (error) {
        console.error("Error al obtener el informe de pagos:", error);
        setCargando(false);
      }
    };

    fetchInformePagos();
  }, [jwt]);

  if (cargando) {
    return (
      <div className="skeleton-chart">
        <div className="bar"></div>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
        <div className="bar4"></div>
      </div>
    );
  }

  return (
    <div className={styles.contGrafico}>
      <h3>Ventas por evento <span>(monto/fecha evento)</span></h3>
      <ResponsiveBar
        data={data}
        keys={["Suma Subtotales"]}
        indexBy="fecha"
        margin={{ top: 20, right: 20, bottom: 50, left: 65 }}
        padding={0.2}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={coloresEmpresa}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 4,
          tickPadding: 4,
          tickRotation: 0
          // Leyenda eliminada
        }}
        axisLeft={{
          tickSize: 4,
          tickPadding: 4,
          tickRotation: 0
          // Leyenda eliminada
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  );
};

export default GraficoComparativoBarras;
