import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import styles from "./SubmitButton.module.scss";

const SubmitButton = ({ isDisabled, color, isLoading, onClick, children, className, filling = true }) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    if (isLoading && filling) {
      buttonRef.current.classList.add(styles.filling);
    } else {
      const transitionEnd = () => {
        buttonRef.current.classList.remove(styles.filling);
        buttonRef.current.removeEventListener('animationend', transitionEnd);
      };
      buttonRef.current.addEventListener('animationend', transitionEnd);
    }
  }, [isLoading, filling]);

  const buttonStyle = {
    backgroundColor: color,
    position: "relative",
    overflow: "hidden",
  };

  return (
    <button
      ref={buttonRef}
      className={classNames(styles.buttonSubmit, className)}
      style={buttonStyle}
      disabled={isDisabled}
      onClick={onClick}
    >
      <span className={styles.content} style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
        {children}
      </span>
      {isLoading && (
        <svg viewBox="25 25 50 50" className={styles.container}>
          <circle cx="50" cy="50" r="20" className={styles.loader}></circle>
        </svg>
      )}
    </button>
  );
};

export default SubmitButton;