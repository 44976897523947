import React, { useState, useEffect } from "react";
import styles from "./SignUpWindow.module.scss";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../Context/AuthProvider";
import { GoogleLogin } from "@react-oauth/google";
import Alert from "../../Components/Alert/Alert";
import "sweetalert2/src/sweetalert2.scss";
import SubmitButton from "../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton";
import variables from "../../Context/Variables";
import { isValidPhoneNumber } from 'libphonenumber-js';
import PhoneInputComponent from "../../Components/PhoneInputComponent/PhoneInputComponent";
import { Icon } from "@iconify/react";

const SignUp = () => {
  // Definir initialState dentro del componente
  const initialState = {
    nombre: "",
    email: "",
    dni: "",
    celular: "",
    contraseña: "",
    contraseñaConfirmada: "",
    terminos: false,
  };

  //Estados para manejar errores y registro con google
  const { login } = useAuth();
  const [error, setError] = useState("");

  // Estado para almacenar los datos del formulario
  const [formData, setFormData] = useState(initialState);

  // Estado para manejar la visibilidad de la contraseña
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  //Navegador para redirigir
  const navigate = useNavigate();

  // Estado para el botón submit (añadido)
  const [isLoading, setIsLoading] = useState(false);

  // Estado para almacenar los errores de validación
  const [errors, setErrors] = useState({});

  // Estado para almacenar si el formulario es válido
  const [isFormValid, setIsFormValid] = useState(false);

  // Manejador de cambios en los campos del formulario
  const handleChange = (e) => {
    const { id, value, checked, type } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    // Actualizar el estado del formulario
    setFormData((prev) => ({ ...prev, [id]: newValue }));

    // Validar el campo actualizado
    const errorMsg = validateField(id, newValue);

    // Aplicar estilos de borde basados en la validación
    if (errorMsg) {
      e.target.style.borderColor = "red";
    } else if (newValue !== "") {
      e.target.style.borderColor = "green";
    } else {
      e.target.style.removeProperty("border-color");
    }

    // Iniciar la validación específica para el campo que cambió
    validateField(id, newValue);
  };

  const handlePhoneChange = (phone) => {
    setFormData((prev) => ({ ...prev, celular: phone }));

    const errorMsg = validateField("celular", phone);

    if (errorMsg) {
      setErrors((prev) => ({ ...prev, celular: errorMsg }));
    } else {
      setErrors((prev) => ({ ...prev, celular: "" }));
    }
  };

  const validateForm = () => {

    const nombreValido = validarNombreCompleto(formData.nombre);
    const emailValido = validarEmail(formData.email);
    const dniValido = validarDNI(formData.dni);
    const celularValido = isValidPhoneNumber(formData.celular);
    const contraseñaValida = validarContraseña(formData.contraseña);
    const contraseñasCoinciden = formData.contraseña === formData.contraseñaConfirmada;
    const terminosAceptados = formData.terminos;

    const allFieldsValid = nombreValido && emailValido && dniValido && celularValido && contraseñaValida && contraseñasCoinciden && terminosAceptados;

    setIsFormValid(allFieldsValid);
  };

  // Observar cambios en formData y errors para validar el formulario
  useEffect(() => {
    validateForm();
  }, [formData, errors]);

  // Validar cada campo del formulario
  const validateField = (id, value) => {
    let errorMsg = "";

    switch (id) {
      case "nombre":
        if (!validarNombreCompleto(value)) {
          errorMsg = "El nombre debe tener al menos 4 caracteres.";
        }
        break;
      case "email":
        if (!validarEmail(value)) {
          errorMsg = "Ingresa un email válido.";
        }
        break;
      case "dni":
        if (!validarDNI(value)) {
          errorMsg = "El DNI/Pasaporte debe tener entre 7 y 15 dígitos.";
        }
        break;
      case "celular":
        if (!isValidPhoneNumber(value)) {
          errorMsg = "Debes ingresar un número de teléfono válido.";
        }
        break;
      case "contraseña":
        if (!validarContraseña(value)) {
          errorMsg = "La contraseña debe tener al menos 6 caracteres, incluir una mayúscula y una minúscula.";
        }
        break;
      case "contraseñaConfirmada":
        if (formData.contraseña !== value) {
          errorMsg = "Las contraseñas no coinciden.";
        }
        break;
      default:
        break;
    }

    setErrors((prev) => ({ ...prev, [id]: errorMsg }));
    return errorMsg;
  };

  // Función para alternar la visibilidad de la contraseña
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Función para alternar la visibilidad de la confirmación de contraseña
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validarNombreCompleto = (nombre) => {
    return nombre.trim().length >= 4;
  };

  const validarDNI = (dni) => {
    const regexDNI = /^\d{7,15}$/;
    return regexDNI.test(dni);
  };

  const validarContraseña = (contraseña) => {
    const regexContraseña = /^(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
    return regexContraseña.test(contraseña);
  };

  const validarEmail = (email) => {
    const regexEmail = /\S+@\S+\.\S+/;
    return regexEmail.test(email);
  };

  // Manejador del envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    let newErrors = {};

    if (!validarNombreCompleto(formData.nombre)) {
      newErrors.nombre = "El nombre debe tener al menos 4 caracteres.";
    }
    if (!validarEmail(formData.email)) {
      newErrors.email = "Ingresa un email válido.";
    }
    if (!validarDNI(formData.dni)) {
      newErrors.dni = "El DNI/Pasaporte debe tener entre 7 y 15 dígitos.";
    }
    if (!isValidPhoneNumber(formData.celular)) {
      newErrors.celular = "Debes ingresar un celular válido.";
    }
    if (!validarContraseña(formData.contraseña)) {
      newErrors.contraseña = "La contraseña debe tener al menos 6 caracteres, incluir una mayúscula y una minúscula.";
    }
    if (formData.contraseña !== formData.contraseñaConfirmada) {
      newErrors.contraseñaConfirmada = "Las contraseñas no coinciden.";
    }
    if (!formData.terminos) {
      newErrors.terminos = "Debes aceptar los términos y condiciones.";
    }

    setErrors(newErrors);

    setIsLoading(true);
    if (Object.keys(newErrors).length === 0) {
      try {
        const datosParaEnviar = {
          username: formData.nombre,
          password: formData.contraseña,
          email: formData.email,
          dni: formData.dni,
          celular: formData.celular,
        };

        let response = await axios.post(
          variables.API_BASE_URL + "/api/usuarios/registrarUsuarioYLoguearlo",
          datosParaEnviar
        );

        await Alert({
          tipo: 'success',
          titulo: 'Éxito',
          descripcion: 'Tu cuenta ha sido creada correctamente.',
          duracion: 3000
        });

        const jwt = response.data.jwt;
        if (jwt) {
          login(response.data, false);
          const event = new CustomEvent("userAuthenticated", { detail: jwt });
          window.dispatchEvent(event);
          navigate("/index");
        } else {
          console.error("No se recibió un token JWT válido.");
        }

        setFormData(initialState);
      } catch (error) {
        setIsLoading(false);
        if (error.response && error.response.status === 400) {
          await Alert({
            tipo: 'error',
            titulo: 'Error',
            descripcion: error.response.data,
            duracion: 4000
          });
        } else {
          await Alert({
            tipo: 'error',
            titulo: 'Error',
            descripcion: "Hubo un problema con la solicitud: " + (error.response ? error.response.data.message : error.message),
            duracion: 4000
          });
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      await Alert({
        tipo: 'error',
        titulo: 'Error',
        descripcion: 'Por favor, completa el formulario correctamente antes de enviar.',
        duracion: 4000
      });
    }
  };

  function handleError() {
    setError(
      "No se pudo iniciar sesión con Google. Por favor, inténtalo de nuevo."
    );
  }

  // Lógica para determinar si el botón debe estar deshabilitado
  const isButtonDisabled = !isFormValid;

  const handleGoogleLogin = async (googleData) => {
    const apiUrl = variables.API_BASE_URL + "/api/usuarios/authGoogle";
    try {
      const response = await axios.post(apiUrl, {
        tokenId: googleData.credential,
      });

      if (response.data && response.data.jwt) {
        login(response.data, false);
        navigate("/index");
      } else {
        setError(
          "No se pudo iniciar sesión con Google. Por favor, inténtalo de nuevo."
        );
      }
    } catch (error) {
      // Manejar errores de la petición
      console.error("Error al iniciar sesión con Google:", error);
      setError(
        "Error al iniciar sesión con Google. Por favor, inténtalo de nuevo."
      );
    }
  };

  return (
    <section className={`${styles.grupoLogin} ${styles.signupUserGrupo}`}>
      <div className={styles.fotoSignUp}></div>
      <div
        className={`${styles.loginForm} ${styles.parteFormLogin} ${styles.productorForm}`}
      >
        <form
          id="agregar-simpleUser"
          className={styles.nright}
          onSubmit={handleSubmit}
        >
          <h3>¡Creá tu cuenta!</h3>
          <h4>Este es el primer paso para disfrutar tus eventos favoritos.</h4>
          {error && <div className={styles.errorMesagge}>{error}</div>}

          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={handleError}
            className={styles.google}
          />

          <small>o</small>

          <h5>Nombre completo</h5>
          <input
            type="text"
            id="nombre"
            placeholder="Ingresa tu nombre completo"
            value={formData.nombre}
            onChange={handleChange}
          />
          {errors.nombre && <p className={styles.error}>{errors.nombre}</p>}

          <h5>Email</h5>
          <input
            type="email"
            id="email"
            placeholder="ejemplo@mail.com"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <p className={styles.error}>{errors.email}</p>}

          <h5>DNI/Pasaporte</h5>
          <input
            type="text"
            id="dni"
            placeholder="Ingresa tu DNI sin puntos"
            value={formData.dni}
            onChange={handleChange}
          />
          {errors.dni && <p className={styles.error}>{errors.dni}</p>}

          <h5>Celular</h5>
          <PhoneInputComponent
            value={formData.celular}
            onChange={handlePhoneChange}
          />

          {errors.celular && <p style={{ margin: "0" }} className={styles.error}>{errors.celular}</p>}

          <h5>Contraseña</h5>
          <div className={styles.inputGroup}>
            <input
              type={showPassword ? "text" : "password"}
              id="contraseña"
              placeholder="Ingresa una contraseña"
              className={styles.passwordInput}
              value={formData.contraseña}
              onChange={handleChange}
            />
            <button
              type="button"
              className={styles.togglePassword}
              onClick={togglePasswordVisibility}
            >
              <Icon icon={showPassword ?  "mdi:eye-off" : "mdi:eye"}width={16}/>
            </button>
          </div>
          {errors.contraseña && (
            <p className={styles.error}>{errors.contraseña}</p>
          )}

          <h5>Confirma tu contraseña</h5>
          <div className={styles.inputGroup}>
            <input
              type={showConfirmPassword ? "text" : "password"}
              id="contraseñaConfirmada"
              placeholder="Reingresa tu contraseña"
              className={styles.passwordInput}
              value={formData.contraseñaConfirmada}
              onChange={handleChange}
              onPaste={(e) => e.preventDefault()}
            />
            <button
              type="button"
              className={styles.togglePassword}
              onClick={toggleConfirmPasswordVisibility}
            >
              <Icon icon={showPassword ?  "mdi:eye-off" : "mdi:eye"}width={16}/>
            </button>
          </div>
          {errors.contraseñaConfirmada && (
            <p className={styles.error}>{errors.contraseñaConfirmada}</p>
          )}

          <div className={styles.containerCheckbox}>
            <input
              type="checkbox"
              id="terminos"
              checked={formData.terminos}
              onChange={handleChange}
            />
            <label htmlFor="terminos" className={styles.checkboxLabel}>
              <svg viewBox="0 0 64 64" height="1em" width="1em">
                <path
                  d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                  className={styles.path}
                ></path>
              </svg>
              <p>
                He leído y acepto los{" "}
                <a href="/terminosYCondiciones">términos y condiciones.</a>
              </p>
            </label>
          </div>

          {errors.terminos && <p className={styles.error}>{errors.terminos}</p>}

          <div id="resultado"></div>

          <div className={styles.contSubmit}>
            <SubmitButton
              isDisabled={isButtonDisabled}
              color="#99419d" // Color principal de tu tema
              isLoading={isLoading}
              onClick={handleSubmit} // Ya manejado por el onSubmit del form
            >
              Crear mi cuenta
            </SubmitButton>
          </div>

          <p style={{ margin: "0" }}>¿Ya tenes una cuenta?</p>
          <p style={{ margin: "0", marginBottom: "15px" }}>
            <Link to="/login">Inicia sesión</Link>
          </p>
          <p style={{ margin: "0" }}>¿Sos productor?</p>
          <p style={{ margin: "0", marginBottom: "15px" }}>
            <Link to="/signup-productor">Registrarme como productor</Link>
          </p>
        </form>
      </div>
      <div style={{ clear: "both" }}></div>
    </section>
  );
};

export default SignUp;
