import React from 'react';
import styles from './Banner.module.scss';

const EventBanner = () => {
  return (
    <section className={styles.banner}>
      <div className={styles.content}>
        <div className={styles.textContent}>
          <h1 className={styles.title}>Creá un evento inolvidable y compartilo con el mundo</h1>
          <p className={styles.subtitle}>Vendé entradas, butacas y consumiciones, todo en un mismo lugar desde tu celular!</p>
          <div className={styles.infoBox}>
            <span className={styles.infoText}>Tu evento online en menos de 24hs</span>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img
           decoding="async"
            src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/INDEX/1.webp"
            alt="Aplicación móvil"
            className={styles.phoneImageFront}
          />
          <img
          decoding="async"
            src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/INDEX/2.webp"
            alt="Ticket QR"
            className={styles.phoneImageBack}
          />
        </div>
      </div>
    </section>
  );
};

export default EventBanner;
