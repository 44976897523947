import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import EventoDetalles from './EventoDetalles/EventoCompra';
import styles from "./EventoDetalles/EventoDetalles.module.scss"; 
import variables from '../../Context/Variables';
import EventoDetallesSkeleton from './EventoDetalles/Skeleton/EventoDetallesSkeleton';

const EventoPage = () => {
  const { id } = useParams();
  const { rrppId } = useParams();
  const [evento, setEvento] = useState(null);
  const [rrpp, setRrpp] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorCarga, setErrorCarga] = useState(false);

  useEffect(() => {
    document.title = "Evento detalles";
    window.scrollTo(0, 0);
    fetchEventoAndRrpp();
  }, [id, rrppId]);

  const fetchEventoAndRrpp = async () => {
    setLoading(true);
    try {
      const eventoResponse = await axios.get(`${variables.API_BASE_URL}/api/eventos/${id}`);
      setEvento(eventoResponse.data);
      document.title = eventoResponse.data.nombre;
      if (rrppId) {
        const rrppResponse = await axios.get(`${variables.API_BASE_URL}/api/rrpps/${rrppId}`);
        setRrpp(rrppResponse.data);
      }
      setLoading(false);
      setErrorCarga(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setErrorCarga(true);
      setLoading(false);
    }
  };

  if (loading) {
    return <EventoDetallesSkeleton />;
  }

  if (errorCarga) {
    return (
      <section className={styles.bodyEventoDetalles}>
        <div className={styles.eventoNoDisponible}>
          <img
            src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Imagenes+para+errores/error.png"
            alt="Evento no disponible"
            className={styles.imagenErrorEvento}
          />
          <h1 style={{textAlign:"center"}}>Disculpe pero este evento no está disponible o no existe.</h1>
        </div>
      </section>
    );
  }

  return <EventoDetalles evento={evento} rrpp={rrpp} />;
};

export default EventoPage;
