import React from 'react'
import "./PagoCardSkeleton.css"

const PagoCardSkeleton = () => {
  return (
    <div class="pagoCardSkeleton">
    <div class="iconoSkeleton"></div>
    <div class="contInfoSkeleton">
        <div class="fondoSubSkeleton">
            <div class="tituloSkeleton"></div>
        </div>
        <div class="lineaSkeleton"></div>
        <div class="lineaSkeleton"></div>
        <div class="lineaSkeleton"></div>
        <div class="lineaSkeleton"></div>
        <div class="lineaSkeleton"></div>
        <div class="lineaSkeleton"></div>
        <div class="lineaSkeleton"></div>
    </div>
</div>
  )
}

export default PagoCardSkeleton
