import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import Alert from '../../Components/Alert/Alert';
import styles from './Caja.module.scss';
import { useAuth } from '../../Context/AuthProvider';
import variables from '../../Context/Variables';
import { Icon } from '@iconify/react/dist/iconify.js';
import ConectorPlugin from '../../Util/ConectorPluginV3';
import CierreLoteModal from '../../Components/CierreLoteModal/CierreLoteModal';
import SkeletonProducto from './SkeletonProducto/SkeletonProducto';

const Caja = () => {
    const { eventoId, cajaId } = useParams();
    const [productos, setProductos] = useState([]);
    const [error, setError] = useState(null);
    const [errorImpresion, setErrorImpresion] = useState(null);
    const { jwt } = useAuth();
    const [nombreEvento, setNombreEvento] = useState('');
    const [loadingProductId, setLoadingProductId] = useState(null);
    const [impresoras, setImpresoras] = useState([]);
    const [cargandoProductos, setCargandoProductos] = useState(true)
    const [impresoraSeleccionada, setImpresoraSeleccionada] = useState('');
    const [cargandoImpresoras, setCargandoImpresoras] = useState(false);
    const cargandoImpresorasRef = useRef(false);
    const [totalCarrito, setTotalCarrito] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [imagenLogo, setImagenLogo] = useState(null);
    const [noInternetModalVisible, setNoInternetModalVisible] = useState(false);
    const coneccion = useRef(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    const userData = JSON.parse(userDetails);

    // Función para verificar la conexión a Internet
    const verificarConexion = () => {
        if (navigator.onLine) {
            coneccion.current = true;
            setNoInternetModalVisible(false);
        } else {
            coneccion.current = false;
            setNoInternetModalVisible(true);
        }
    };

    // Reiniciar la página si no hay internet
    const handleReloadPage = () => {
        window.location.reload();
    };

    // Checkear si hay internet cada vez que se dispare un evento de conexión/desconexión
    useEffect(() => {
        verificarConexion(); // Verificar al cargar el componente
        window.addEventListener('online', verificarConexion);
        window.addEventListener('offline', verificarConexion);

        return () => {
            window.removeEventListener('online', verificarConexion);
            window.removeEventListener('offline', verificarConexion);
        };
    }, []);

    // Ejecutar la verificación de ventas pendientes cada 60 segundos
    useEffect(() => {
        const interval = setInterval(verificarVentasPendientes, 20000);

        return () => clearInterval(interval);
    }, [eventoId, cajaId, jwt]);

    // Función para cerrar el lote
    const handleCierreLote = async (nombreEncargado, setIsLoading) => {
        setIsLoading(true); // Mostrar el loader
        await verificarVentasPendientes(); // Verificar si hay ventas pendientes y procesarlas
    
        const productosEnProceso = obtenerProductosEnProceso();
        const ventasPendientes = productosEnProceso.filter(p => p.estado === 'FALLIDO' || p.estado === 'PROCESANDO');
    
        if (ventasPendientes.length > 0) {
            await Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: 'Hay ventas pendientes. Espere a que se procesen todas las ventas antes de cerrar el lote.',
                duracion: 5000
            });
            setIsLoading(false);
            return;
        }
    
        // Cerrar el lote solo cuando no haya ventas pendientes
        try {
            const response = await axios.post(
                `${variables.API_BASE_URL}/api/cantina/${eventoId}/cajas/${cajaId}/cerrarLote`,
                { nombreEncargado },
                { headers: { Authorization: `Bearer ${jwt}` } }
            );
    
            // Si la respuesta es exitosa, se imprime el ticket
            if (response.status === 200) {
                const cierreLoteData = response.data;
                await Alert({
                    tipo: 'success',
                    titulo: 'Éxito',
                    descripcion: 'Lote cerrado con éxito.',
                    duracion: 3000
                });
                handlePrintTicketCierreLote(cierreLoteData); // Imprimir el ticket de cierre
                handleCloseModal();
            } else {
                await Alert({
                    tipo: 'error',
                    titulo: 'Error',
                    descripcion: 'No se pudo cerrar el lote. Por favor, inténtalo de nuevo.',
                    duracion: 5000
                });
            }
        } catch (error) {
            await Alert({
                tipo: 'error',
                titulo: 'Error',
                descripcion: 'No se pudo cerrar el lote. Por favor, inténtalo de nuevo.',
                duracion: 5000
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handlePrintTicketCierreLote = (cierreLoteData) => {
        const { nombreCaja, nombreEvento, nombreEncargado, fechaYHoraRealizado, montoTotalVendido } = cierreLoteData;
        const conector = new ConectorPlugin();
    
        conector
            .Iniciar()
            .EstablecerAlineacion(ConectorPlugin.ALINEACION_CENTRO)
            .EscribirTexto("_______________________\n")
            .Feed(1)
            .EscribirTexto(`Evento: ${nombreEvento}\n`)
            .Feed(1)
            .EscribirTexto("_______________________\n")
            .Feed(2)
            .EstablecerTamañoFuente(3, 3)
            .EscribirTexto("Cierre de Lote\n")
            .Feed(2)
            .EstablecerTamañoFuente(1, 1)
            .EscribirTexto(`Caja: ${nombreCaja}\n`)
            .EscribirTexto(`Encargado: ${nombreEncargado}\n`)
            .EscribirTexto(`Fecha y hora: ${new Date(fechaYHoraRealizado).toLocaleString()}\n`)
            .Feed(1)
            .EstablecerTamañoFuente(2, 2) 
            .EstablecerEnfatizado(true)    
            .EscribirTexto(`Total vendido: $${montoTotalVendido.toFixed(0)}\n`)
            .Feed(2)
            .Corte() 
            .imprimirEn(impresoraSeleccionada)
            .then(response => {
                console.log("Ticket de cierre de lote impreso exitosamente", response);
            })
            .catch(error => {
                console.error("Error al imprimir el ticket de cierre de lote", error);
            });
    };

    // Listar productos de la caja
    useEffect(() => {
        const fetchProductos = async () => {
            try {
                const response = await axios.get(variables.API_BASE_URL + `/api/cantina/${eventoId}/cajas/${cajaId}/productos`, {
                    headers: {
                        Authorization: `Bearer ${jwt}`
                    }
                });

                if (response.data.productos) {
                    setProductos(response.data.productos);
                    setNombreEvento(response.data.nombreEvento);
                    setCargandoProductos(false);
                } else {
                    setProductos([]);
                    setNombreEvento('');
                    setCargandoProductos(false);
                }

                if (response.data.imagenLogoTicket) {
                    console.log("Imagen logo ticket:", response.data.imagenLogoTicket);
                    setImagenLogo(response.data.imagenLogoTicket);
                } else{
                    setImagenLogo("https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/INDEX/logo.png");
                }
            } catch (error) {
                setError('Error al cargar los productos de la caja.');
                console.error(error);
                setCargandoProductos(false);
            }
        };

        fetchProductos();
    }, [eventoId, cajaId, jwt]);

    // Listar impresoras una sola vez cuando el componente se monta
    useEffect(() => {
        const obtenerImpresoras = async () => {
            if (!cargandoImpresorasRef.current) {
                cargandoImpresorasRef.current = true;
                setCargandoImpresoras(true);
                try {
                    const impresorasDisponibles = await ConectorPlugin.obtenerImpresoras();
                    setImpresoras(impresorasDisponibles);

                    if (impresorasDisponibles.length === 1) {
                        setImpresoraSeleccionada(impresorasDisponibles[0]);
                    }
                } catch (err) {
                    setErrorImpresion('Error al obtener las impresoras. Verifique que posee el plugin instalado en su computadora.');
                    console.error(err);
                } finally {
                    setCargandoImpresoras(false);
                    cargandoImpresorasRef.current = false;
                }
            }
        };

        obtenerImpresoras();
    }, []);

    const refrescarImpresoras = async () => {
        if (cargandoImpresorasRef.current) {
            return;
        }

        cargandoImpresorasRef.current = true;
        setCargandoImpresoras(true);

        try {
            const impresorasDisponibles = await ConectorPlugin.obtenerImpresoras();
            setImpresoras(impresorasDisponibles);

            if (impresorasDisponibles.length === 1) {
                setImpresoraSeleccionada(impresorasDisponibles[0]);
            }
        } catch (err) {
            setErrorImpresion('Error al obtener las impresoras. Verifique que posee el plugin instalado en su computadora.');
            console.error(err);
        } finally {
            setCargandoImpresoras(false);
            cargandoImpresorasRef.current = false;
        }
    };

    // Función para generar un ID único
    const generarIdUnico = () => {
        return Math.random().toString(36).substring(2, 18);
    };

    const handleProductClick = async (producto) => {
        if (!impresoraSeleccionada) {
            Alert({
                tipo: 'warning',
                titulo: 'Atención',
                descripcion: 'Por favor seleccione una impresora antes de proceder.',
                duracion: 3000
            });
            return;
        }

        verificarConexion();
        if (!coneccion.current) {
            setNoInternetModalVisible(true);
            return;
        }

        // Mostrar el indicador de carga para el producto
        setLoadingProductId(producto.id);

        const idFront = generarIdUnico();
        const nuevoProducto = { ...producto, idFront: idFront, estado: 'PROCESANDO' };

        const productosEnProceso = obtenerProductosEnProceso();

        // Agregar el producto en proceso al localStorage inmediatamente
        const productosActualizados = [...productosEnProceso, nuevoProducto];
        localStorage.setItem('productosEnProceso', JSON.stringify(productosActualizados));

        // Respuesta rápida: Imprimir ticket y actualizar carrito
        handlePrintTicket(producto);
        setTotalCarrito(prevTotal => prevTotal + producto.precio);

        // Desactivar el indicador de carga después de 500 ms (respuesta rápida al usuario)
        setTimeout(() => {
            setLoadingProductId(null);
        }, 1000);

        // Asincronía: Procesar la venta en segundo plano
        setTimeout(async () => {

            try {
                console.log("Producto ID:", producto.id, "ID Front:", idFront);
                console.log("Enviando solicitud a:", `${variables.API_BASE_URL}/api/cantina/${eventoId}/cajas/${cajaId}/venta`);

                const response = await axios.post(
                    `${variables.API_BASE_URL}/api/cantina/${eventoId}/cajas/${cajaId}/venta`,
                    { productoId: producto.id, idFront },
                    { headers: { Authorization: `Bearer ${jwt}` } }
                );

                console.log("Respuesta recibida:", response.status, response.data);

                if (response.status === 201 || response.status === 200) {
                    console.log("Venta exitosa. Actualizando productos en proceso...");

                    // Aca se vuelven a traer los productos en proceso porque entre que llega el resultado puede ser que haya cambiado 
                    const productosEnProcesoActuales = obtenerProductosEnProceso();

                    //Buscamos dentro de la lista la venta de producto que procesamos
                    const productosFiltrados = productosEnProcesoActuales.filter(p => p.idFront !== idFront);
                    console.log("Productos actualizados, antes de guardar en localStorage:", productosFiltrados);

                    localStorage.setItem('productosEnProceso', JSON.stringify(productosFiltrados));

                    console.log("Productos actualizados guardados en localStorage.");
                } else {
                    console.log("Error en la venta. Estado de respuesta:", response.status);
                    actualizarEstadoProducto(idFront, 'FALLIDO');
                }
            } catch (error) {
                console.error("Error durante la solicitud de venta:", error);
                actualizarEstadoProducto(idFront, 'FALLIDO');
            }
        }, 0);
    };

    const handleResetTotal = () => {
        setTotalCarrito(0); // Reiniciar el total del carrito a 0
    };

    const handlePrintTicket = (producto) => {
        const conector = new ConectorPlugin();
        conector
            .Iniciar()
            .EstablecerAlineacion(ConectorPlugin.ALINEACION_CENTRO)
            .EscribirTexto("_______________________\n")
            .Feed(1)
            .DescargarImagenDeInternetEImprimir(imagenLogo, 216, 0)
            .Feed(1)
            .EscribirTexto("_______________________\n")
            .Feed(2)
            .EstablecerTamañoFuente(3, 3)
            .EstablecerEnfatizado(true)
            .EscribirTexto(`${producto.titulo}\n`)
            .Feed(2)
            .EstablecerTamañoFuente(1, 1)
            .EscribirTexto(`$${producto.precio.toFixed(0)}\n`)
            .Feed(1)
            .EstablecerTamañoFuente(1, 1)
            .Feed(1)
            .EscribirTexto('simplepass.com.ar\n')
            .Feed(2)
            .EscribirTexto('-')
            .Feed(1)
            .imprimirEn(impresoraSeleccionada)
            .then(response => {
                console.log("Respuesta de impresora:", response);
            })
            .catch(error => {
                console.error("Error al imprimir el ticket", error);
            });
    };

    // Función para actualizar el estado del producto en el localStorage
    const actualizarEstadoProducto = (idFrontParam, nuevoEstado) => {

        const productosEnProceso = obtenerProductosEnProceso();
        
        // Hacemos una copia de la lista actual de productos en proceso
        const productosActualizados = productosEnProceso.map(p => {

            // Verificamos si el idFront coincide con el que queremos actualizar
            if (p.idFront == idFrontParam) {

                // Solo actualizamos el estado del producto encontrado
                return { ...p, estado: nuevoEstado };
            }
            // Si no es el producto que buscamos, lo dejamos intacto
            return p;
        });

        // Guardamos la lista actualizada en localStorage
        localStorage.setItem('productosEnProceso', JSON.stringify(productosActualizados));
    };

    // Obtener los productos en proceso directamente desde localStorage
    const obtenerProductosEnProceso = () => {
        return JSON.parse(localStorage.getItem('productosEnProceso')) || [];
    };

    // Método para verificar ventas pendientes y procesarlas de una en una
    const verificarVentasPendientes = async () => {

        // Buscamos la lista de pendientes en localstorage
        const productosEnProceso = obtenerProductosEnProceso();

        // Si posee fallidos o pendientes entonces se deben registrar uno a uno
        if (productosEnProceso.length > 0) {
            for (const venta of productosEnProceso) {
                try {
                    const response = await axios.post(
                        `${variables.API_BASE_URL}/api/cantina/${eventoId}/cajas/${cajaId}/venta`,
                        { productoId: venta.id, idFront: venta.idFront },
                        {
                            headers: { Authorization: `Bearer ${jwt}` }
                        }
                    );

                    if (response.status === 201 || response.status === 200) {
                        // Si la venta fue procesada correctamente, eliminarla de productosEnProceso
                        const productosActualizados = productosEnProceso.filter(p => p.idFront !== venta.idFront);
                        localStorage.setItem('productosEnProceso', JSON.stringify(productosActualizados));
                    }
                } catch (error) {
                    console.error('Error al verificar venta', venta, error);
                    // Si la request falla, el producto sigue en estado FALLIDO/PROCESANDO
                }
            }
        } 
    };

    return (
        <div className={styles.cajaContainer}>
            <div className={styles.header}>
                <button className={styles.volver} onClick={() => { window.history.back() }}>
                    <Icon width={30} icon="solar:arrow-left-linear" />
                </button>
            </div>
            <div className={styles.productos}>
                <h3 className={styles.subtituloProducto}>Productos</h3>
                {error && <p>{error}</p>}
                <div className={styles.productosGrid}>
                    {cargandoProductos ?
                        <>
                            {Array.from({ length: 10 }).map((_, index) => (
                                <SkeletonProducto key={index} />
                            ))}
                        </>
                        :
                        <>
                            {productos.map((producto) => (
                                <div
                                    key={producto.id}
                                    className={styles.producto}
                                    onClick={() => handleProductClick(producto)}
                                >
                                    {loadingProductId === producto.id ? (
                                        <div className={styles.loader}></div>
                                    ) : (
                                        <>
                                            <img src={producto.imgURL} alt={producto.titulo} className={styles.productoImagen} />
                                            <div className={styles.datos}>
                                                <p>{producto.titulo}</p>
                                                <p>${producto.precio.toFixed(0)}</p>
                                            </div>
                                        </>
                                    )}
                                </div>
                            ))}
                        </>
                    }
                </div>
            </div>

            <div className={styles.botones}>

                <div className={styles.contador}>
                    <h3 className={styles.aCobrar}>Total a cobrar:</h3>
                    <div className={styles.row}>
                        <span className={styles.monto}>${totalCarrito.toLocaleString('es-ES')}</span><Icon className={styles.reload} width={30} icon="solar:restart-circle-bold" onClick={handleResetTotal} style={{ cursor: 'pointer' }} />
                    </div>
                </div>

                {userData.rol === "PRODUCTOR" ?
                    <Link to={`/productos-caja/${eventoId}/${cajaId}`} className={styles.boton}>Gestión productos <Icon width={20} icon="solar:bottle-linear" /></Link>
                    : null
                }
                <button className={styles.boton} onClick={handleOpenModal}>
                    Cerrar Lote <Icon width={20} icon="uil:money-withdrawal" />
                </button>

                <div className={styles.impresorasContainer}>
                    <h2 className={styles.subtituloImpresoras}>Lista de Impresoras <Icon width={20} icon="mdi:printer-pos-outline" /></h2>
                    {!impresoraSeleccionada && errorImpresion && <p style={{ fontSize: "14px", color: "red", textAlign: "center", fontWeight: "600" }}>{errorImpresion}</p>}
                    {cargandoImpresoras ? (
                        <div className={styles.loader}></div>
                    ) : (
                        <div className={styles.impresorasList}>
                            {impresoras.length > 0 ? (
                                impresoras.map((impresora, index) => (
                                    <p
                                        key={index}
                                        className={`${styles.impresoraItem} ${impresoraSeleccionada === impresora ? styles.selected : ''}`}
                                        onClick={() => setImpresoraSeleccionada(impresora)}
                                    >
                                        {impresoraSeleccionada === impresora ? (
                                            <Icon width={16} icon="entypo:check" />
                                        ) : (
                                            <Icon width={16} icon="mdi:printer-pos-plus-outline" />
                                        )}
                                        {impresora}
                                    </p>
                                ))
                            ) : (
                                <p style={{ fontSize: "14px", color: "red", textAlign: "center", fontWeight: "600" }} className={styles.noHay}>No hay impresoras disponibles.</p>
                            )}
                        </div>
                    )}
                    <button onClick={refrescarImpresoras} className={styles.refrescar}>Refrescar <Icon icon="solar:restart-outline" /></button>
                </div>

                { userData.rol === "PRODUCTOR" ?
                    <Link className={styles.boton} to={`/informe-caja/${eventoId}/${cajaId}`}>Informe completo <Icon width={20} icon="solar:chart-outline" /></Link>
                    :
                    null
                }

            </div>

            <CierreLoteModal
                show={showModal}
                handleClose={handleCloseModal}
                onSubmit={handleCierreLote}
            />


            <div style={{ display: `${noInternetModalVisible ? "flex" : "none"}` }} className={styles.modalBackground}>
                <div className={styles.modalContent}>
                    <Icon className={styles.wifi} width={70} icon="clarity:no-wifi-solid" />
                    <p>No hay internet, verifique la conexión y recargue la página.</p>
                    <button className={styles.recargar} onClick={handleReloadPage}>
                        Recargar página <Icon icon="ci:arrows-reload-01" />
                    </button>
                </div>
            </div>

        </div>
    );
};

export default Caja;