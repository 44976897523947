const hotjarId = process.env.REACT_APP_HOTJAR_ID;

const Hotjar = (h, o, t, j, a, r) => {
    h.hj = h.hj || function () {
        (h.hj.q = h.hj.q || []).push(arguments)
    };
    h._hjSettings = { hjid: hotjarId, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script'); r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
};

export default Hotjar;