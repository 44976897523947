import React from 'react';
import styles from "./TrabajaConNosotros.module.scss";

const TrabajaConNosotros = () => {
  return (
    <div className={styles.trabajaConNosotros}>
      <h1>¡Trabaja con nosotros! te estamos buscando.</h1>
      <p>Sumate a la mision de crear la mejor ticketera del país 🚀</p>
      <h2>Puestos buscados</h2>
      <div className={styles.cardsContainer}>
        <div className={styles.card}>Desarrollador full stack semi-senior</div>
        <div className={styles.card}>Desarrollador Front-End semi-senior</div>
        <div className={styles.card}>Trafficker digital</div>
        <div className={styles.card}>Diseñador digital</div>
      </div>
      <div className={styles.contEmail}>
        <h3>Aplicar a:</h3>
        <h4>empleos@simplesistemas.com.ar</h4>
      </div>

    </div>
  );
};

export default TrabajaConNosotros;
