import React from 'react'
import styles from "./TicketConsumicionSkeleton.module.scss"

const EventoCardSkeleton = () => {
    return (
        <article className={styles.cardEvento}>

            <div style={{ width: "100%", }} className={styles.contEncabezados}>
                <div style={{ height: '30px', width: '70%', margin: "0px auto 7px" }} className={styles.skeleton} />
            </div>
            <div style={{ width: "100%", border: "none" }} className={styles.contEncabezados}>
                simplepass
            </div>

            <div className={styles.contenidoEvento}>
                <div className={styles.cardEventoImgLink + ' ' + styles.skeleton} style={{ height: '100%', borderRadius: '15px' }} />
                <div className={styles.eventDetails}>
                    <div className={styles.eventTitle + ' ' + styles.skeleton} style={{ height: '40px', width: '90%', margin: '10px auto' }}></div>
                    <div className={styles.atributoCard + ' ' + styles.skeleton} style={{ height: '30px', width: '80%', margin: '8px auto' }}></div>
                    <div className={styles.atributoCard + ' ' + styles.skeleton} style={{ height: '10px', width: '80%', margin: '8px auto' }}></div>
                </div>

                <div className={styles.botonComprar + ' ' + styles.skeleton} style={{ height: '40px' }}></div>
            </div>
        </article>
    )
}

export default EventoCardSkeleton