import React from "react";
import styles from "../NotFoundPage/NotFoundPage.module.scss"; 

const ErrorPage = () => {
  return (
    <section className={styles.bodyEventoDetalles}>
      <div className={styles.eventoNoDisponible}>
        <img
          src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Imagenes+para+errores/mensaje.png"
          alt="Error al procesar los tickets"
          className={styles.imagenErrorEvento}
        />
        <h1>Hubo un error al procesar tus tickets. Por favor, comunícate con atención al cliente si vuelve a ocurrir.</h1>
      </div>
    </section>
  );
};

export default ErrorPage;
