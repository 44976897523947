import React from 'react';
import styles from './EditarEFormSkeleton.module.scss';

function EventoLoadingSkeleton() {
  return (
    <div className={styles.bodyCrearEventoSkeleton}>
      <section className={styles.eventoFoto}>
        <img src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Crear+evento/search.png"></img>
      </section>
      <div className={styles.contFormSkeleton}>
        <div className={styles.tituloSkeleton}></div>
        <div className={styles.subtituloSkeleton}></div>
        <div className={styles.label}></div>
        <div className={styles.inputSkeleton}></div>
        <div className={styles.divisorSkeleton}></div>
        <div className={styles.label}></div>
        <div className={styles.inputSkeleton}></div>
        <div className={styles.divisorSkeleton}></div>
        <div className={styles.label}></div>
        <div className={styles.inputSkeleton}></div>
        <div className={styles.divisorSkeleton}></div>
        <div className={styles.label}></div>
        <div className={styles.inputSkeleton}></div>
        <div className={styles.divisorSkeleton}></div>
        <div className={styles.label}></div>
        <div className={styles.inputSkeleton}></div>
        <div className={styles.boton}></div>
      </div>
    </div>
  );
}

export default EventoLoadingSkeleton;
