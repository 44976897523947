import React from 'react';
import styles from "./RrppList.module.scss";

const RrppList = ({ rrpps }) => {
    return (
        <div className={styles.body}>
            <div className={styles.seccionDeInforme}>
                <h2>Informe de RRPPs</h2>
                <table className={styles.tablaInformeCantidades}>
                    <thead>
                        <tr>
                            <th className={styles.brtopleft}>ID</th>
                            <th className={styles.br0}>Nombre</th>
                            <th className={styles.brtopright}>Tickets vendidos</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rrpps.map(rrpp => (
                            <tr key={rrpp.id}>
                                <td className={styles.brbottomleft}>{rrpp.id}</td>
                                <td>{rrpp.rrppName}</td>
                                <td className={styles.brbottomright}>{rrpp.ticketsVendidos}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RrppList;