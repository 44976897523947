import React, { useState, useEffect } from 'react';
import LoginForm from './LoginForm/LoginForm';
import SignUpForm from './SignupForm/SignupForm';
import styles from './AutenticadorSimplepass.module.scss';
import { Icon } from "@iconify/react";

const AutenticadorSimplepass = ({ titulo, rol }) => {
  const [showForm, setShowForm] = useState(null);

  useEffect(() => {
    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!userDetails) {
      setShowForm(null);
    }
  }, []);

  useEffect(() => {
    const handleUserAuthenticated = () => {
      setShowForm(null);
    };

    window.addEventListener("userAuthenticated", handleUserAuthenticated);
    return () => {
      window.removeEventListener("userAuthenticated", handleUserAuthenticated);
    };
  }, []);

  return (
    <div className={styles.bodyAutenticador}>
      <h4>{titulo}</h4>
      <div className={styles.lineaDivisora}></div>
      <div className={`${styles.buttons} ${showForm === null ? styles.visible : ''} ${(showForm === "login" || showForm === "signup") ? styles.novisible : ''}`}>
        <button
          className={styles.btnIngresar}
          onClick={() => setShowForm('login')}
        >
          Ingresar con mi cuenta <Icon icon="solar:login-2-broken" />
        </button>
        <button
          className={styles.btnCrearCuenta}
          onClick={() => setShowForm('signup')}
        >
          Crear mi cuenta <Icon icon="mingcute:add-fill" />
        </button>
      </div>
      <div className={`${styles.formulario} ${showForm === 'login' ? styles.formularioVisible : ''}`}>
        <LoginForm />
        <button className={styles.btnClose} onClick={() => setShowForm(null)}>Cerrar</button>
      </div>
      <div className={`${styles.formulario} ${showForm === 'signup' ? styles.formularioVisible : ''}`}>
        <SignUpForm rol={rol} />
        <button className={styles.btnClose} onClick={() => setShowForm(null)}>Cerrar</button>
      </div>
    </div>
  );
};

export default AutenticadorSimplepass;



