import React, { useState } from 'react';
import axios from 'axios';
import Alert from '../../Alert/Alert';
import variables from '../../../Context/Variables';
import SubmitButton from '../../Buttons-loaders-inputs/SubmitButton/SubmitButton';
import styles from "./MercadoPagoButton.module.scss";

const MercadoPagoButton = ({ carritoCompraDto, evento, setCargandoCompra, setErrorCompra, setUsuarioNecesitaAutenticacion }) => {
  const [isLoading, setIsLoading] = useState(false);

  const realizarCompraMp = async () => {
    setCargandoCompra(true);
    setIsLoading(true);
    setErrorCompra("");
    try {
      const userDetails = sessionStorage.getItem("userDetails") || localStorage.getItem("userDetails");
      if (!userDetails) {
        setUsuarioNecesitaAutenticacion(true);
        return;
      }
  
      const details = JSON.parse(userDetails);
      const token = details.jwt;
  
      const response = await axios.post(
        variables.API_BASE_URL + "/api/mp/crearYRedireccionar",
        carritoCompraDto,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const data = response.data;
      window.location.href = data.init_point;
  
    } catch (error) {
      console.error("Error durante la compra:", error);
      if (error.response && error.response.status === 409) {
        const errorMessage = error.response.data;
        if (errorMessage.includes("Butaca reservada por 10 minutos")) {
          Alert({
            tipo: 'warning',
            titulo: 'Butaca Reservada',
            descripcion: 'La butaca que deseas comprar está reservada por 10 minutos. Por favor, elige otra o espera a que se libere.',
            duracion: 4000
          });
        } else {
          Alert({
            tipo: 'error',
            titulo: 'Ticket No Disponible',
            descripcion: 'Uno o más tickets seleccionados ya no están disponibles. Por favor, elige otros tickets.',
            duracion: 4000
          });
        }
      } else {
        setErrorCompra(error.message || "Ha ocurrido un error desconocido.");
        Alert({
          tipo: 'error',
          titulo: 'Error',
          descripcion: 'Error al procesar el pago. Por favor, intente nuevamente.',
          duracion: 4000
        });
      }
    } finally {
      setCargandoCompra(false);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <SubmitButton
        isDisabled={isLoading}
        color="#00796b"
        isLoading={isLoading}
        onClick={realizarCompraMp}
        className={styles.button}
        filling={false}
      >
        <img style={{ width: "30px" }} src="https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/metodosPago/logomp.png" alt="" />Saldo en Mercado Pago
      </SubmitButton>
    </div>
  );
};

export default MercadoPagoButton;