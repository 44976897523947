import React from "react";
import styles from "./InformeCaja.module.scss";

// Función para formatear moneda
const formatCurrency = (value) => {
    return new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(value);
};

const InformeCaja = ({ datos }) => {
    if (!datos) {
        return <div>No se encontró información para el evento.</div>;
    }

    const { cajaSeleccionada, cajasTotalesVendidos } = datos;

    return (
        <div className={styles.body}>
            <h1 className={styles.InformeCajaH1}>Informe de venta de la caja: <span>{cajaSeleccionada.nombreCaja}</span></h1>
            
            {/* Renderizar el informe de cierres de lote */}
            <section className={styles.seccionDeInforme}>
                <h2>Cierres de Lote</h2>
                <table className={styles.tablaInformeCantidades}>
                    <thead>
                        <tr>
                            <th className={styles.brtopleft}>Responsable</th>
                            <th>Monto</th>
                            <th className={styles.brtopright}>Fecha</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cajaSeleccionada.cierresDeLote.map((cierre, index) => (
                            <tr key={index}>
                                <td className={styles.brbottomleft}>{cierre.nombreEncargado}</td>
                                <td>{formatCurrency(cierre.montoTotalVendido)}</td>
                                <td className={styles.brbottomright}>{new Date(cierre.fechaYHoraRealizado).toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>

            {/* Renderizar el informe de ventas totales por caja */}
            <section className={styles.seccionDeInforme}>
                <h2>Ventas Totales por Caja</h2>
                <table className={styles.tablaInformeCantidades}>
                    <thead>
                        <tr>
                            <th className={styles.brtopleft}>Caja</th>
                            <th className={styles.brtopright}>Total Vendido</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cajasTotalesVendidos.map((caja, index) => (
                            <tr key={index}>
                                <td className={styles.brbottomleft}>{caja.nombreCaja}</td>
                                <td className={styles.brbottomright}>{formatCurrency(caja.totalVendido)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>

            {/* Renderizar el informe de ventas por producto de la caja seleccionada */}
            <section className={styles.seccionDeInforme}>
                <h2>Ventas por Producto</h2>
                <table className={styles.tablaInformeCantidades}>
                    <thead>
                        <tr>
                            <th className={styles.brtopleft}>Producto</th>
                            <th>Cantidad</th>
                            <th className={styles.brtopright}>Total Vendido</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cajaSeleccionada.productosVendidos.map((producto, index) => (
                            <tr key={index}>
                                <td className={styles.brbottomleft}>{producto.tituloProducto}</td>
                                <td>{producto.cantidadVendida}</td>
                                <td className={styles.brbottomright}>{formatCurrency(producto.montoTotalVendido)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </div>
    );
};

export default InformeCaja;