import React from 'react';
import styles from './Asistencia.module.scss';
import SendWhatsApp from '../../../Components/Buttons-loaders-inputs/SendWhatsApp/SendWhatsApp';
import ButtonEmail from '../../../Components/Buttons-loaders-inputs/SendEmail/SendEmail';

const Asistencia = () => {
    return (
        <div className={styles.container}>
            <div className={styles.info}>
                <h2 className={styles.title}>Asistencia personalizada para tu evento</h2>
                <p className={styles.paragraph}>
                    Contáctanos para recibir asistencia personalizada y resolver todas tus inquietudes.
                </p>
                <p className={styles.paragraph}>
                    Nuestro equipo está acá para ayudarte en cada paso del camino, asegurando que tu evento sea un éxito.
                </p>
            </div>
            <div className={styles.buttons}>
                <ButtonEmail
                    label="Enviar correo"
                    email="productores@simplesistemas.com.ar"
                    subject="Solicitud de asistencia para evento"
                    body="Hola, necesito asistencia para..."
                    variant="primary"
                />
                <SendWhatsApp
                    phoneNumber="+5493436114446" 
                    message="Hola, me gustaría recibir asistencia para mi evento."
                    label="Hablar con asistente"
                    className={styles.whatsappButton}
                />
            </div>
        </div>
    );
};

export default Asistencia;
