import React from 'react';
import ReactDOM from 'react-dom';
import styles from './WhatsAppPop.module.scss';
import { Icon } from '@iconify/react';

const WhatsAppPop = () => {
  return ReactDOM.createPortal(
    <a className={styles.btWhatsapp} href="https://wa.me/+543436114446" target="_blank" rel="noopener noreferrer">
    <Icon width={45} icon="mdi:whatsapp" />

    </a>,
    document.body 
  );
}

export default WhatsAppPop;