import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './CallToAction.module.scss';

const CallToAction = () => {
  const navigate = useNavigate(); 

  const handleClick = () => {
    navigate('/crearEvento', { replace: true });
    window.scrollTo(0, 0); 
  };


  return (
    <section className={styles.banner}>
      <div className={styles.content}>
        <h1 className={styles.title}>Creá un evento inolvidable y compartilo con el mundo</h1>
        <p className={styles.subtitle}>Una nueva forma de vender entradas, fácil y simple. ¡Probalo!</p>
        <button className={styles.button} onClick={handleClick}> 
          Creá tu evento ahora
        </button>
      </div>
    </section>
  );
};

export default CallToAction;
